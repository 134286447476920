import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import toast from "react-hot-toast";
import { ContentCategoryController } from "../../../../controllers/ContentCategoryController";
import useWindowDimensions from "../../../../hooks/useWindowDimensions";
import { ContentCategory, IContentCategory } from "../../../../models/AdditionalVideoContent/ContentCategory";
import { DeepCopy } from "../../../../models/utility/DeepCopy";
import NavSidebar from "../../../SocialConnection/components/layout/NavSidebar/NavSidebar";
import DraggableCategory from "../../../TherapistSupport/DraggableCategory";
import BasicInlineForm from "../../AdminForms/InlineForms/BasicInlineForm";
import {
    StandardContentButton
} from "../../ContentBuilder/ContentBuilderMenu/ContentBuilderMenuButtons/ContentBuilderMenuButton";

type AdminTherapistSupportLayoutProps = {
    isAdmin:boolean;
    contentCategories:ContentCategory[]
    selectedContentCategory:ContentCategory | null | undefined
    selectContentCategory:(id:string) => void
    addNewContentCategory:(name:string) => void
    editContentCategory:(contentCategory:ContentCategory) => void
    toggleDeleteAlert:() => void
    setCategoryForDelete:(contentCategory:ContentCategory) => void
    updateContentCategoryState:(contentCategories:ContentCategory[]) => void
    children:React.ReactNode
}

type AdminTherapistSupportPanelState = {
    newCategoryName:string
    isShowingInlineForm:boolean
    categoryEditingId:string
}

const contentCategoryController = new ContentCategoryController();

const AdminTherapistSupportLayout = (props: AdminTherapistSupportLayoutProps) => {
    // Navigation Panel
    const [isNavOpen, setIsNavOpen] = useState(true);
    const {height, width} = useWindowDimensions();

    const toggle = () => {
        setIsNavOpen(!isNavOpen);
    }

    useEffect(() => {
        // If the width of the device is greater than 1023px, open the navigation panel
        if (width > 1023) {
            setIsNavOpen(true);
        }

        // If the width of the device is less than 1023px, close the navigation panel
        if (width <= 1023) {
            setIsNavOpen(false);
        }

    }, [width]);

    const {
        isAdmin,
        contentCategories,
        selectedContentCategory,
        selectContentCategory,
        addNewContentCategory,
        editContentCategory,
        setCategoryForDelete,
        toggleDeleteAlert,
        children
    } = props;

    const defaultState:AdminTherapistSupportPanelState = {
        newCategoryName: '',
        isShowingInlineForm:false,
        categoryEditingId:""
    }    

    const [state, setState] = useState(defaultState);
    const [showInline, setShowInline] = useState(false);

    /**
     * Toggles wether or not to show the inline form
     */
    const toggleInlineForm = () => {        
        setShowInline(!showInline)
    }

    /**
     * Update a category's name
     * @param categoryName 
     * @returns 
     */
    const handleEditingCategory = (categoryName:string) => {
        const contentCategory = props.contentCategories.find(category => category.id === state.categoryEditingId);

        if(!contentCategory)
        {
            toast.error("Failed To Update Category");
            return;
        }

        const contentCategoryCopy:ContentCategory = DeepCopy.copy(contentCategory);

        contentCategoryCopy.name = categoryName;

        editContentCategory(contentCategoryCopy);
    }    

    /**
     * wrapper to pass to children to update what category is going to be edited
     * @param categoryId 
     */
    const updateCategoryForEdit = (categoryId:string) => {        
        setState(prevState => ({...prevState, categoryEditingId: categoryId}))        
    }

    /**
     * Update category to be deleted and toggle the delete alert
     * @param contentCategory 
     */
    const handleDeleteClick = (contentCategory:ContentCategory) => {        
        setCategoryForDelete(contentCategory);
        toggleDeleteAlert();
    }

    /**
     * When a content category finish dragging update the order of that category
     * and update the database
     * @param results
     * @returns
     */
    const afterDragUpdateCategoryDatabase = (results:any) => {
        if (!results.destination) return;

        const contentCategories:IContentCategory[] = DeepCopy.copy(props.contentCategories);

        const [reorderedItem] = contentCategories.splice(results.source.index, 1);

        contentCategories.splice(results.destination.index, 0, reorderedItem);

        const updateTasks = contentCategories.map((content, index) => {
            try {
                content.order = index;
                contentCategoryController.UpdateContentCategory(content);

            } catch (error) {
                console.error(error);
                toast.error("Failed To Reorder Category")
            }
        });

        props.updateContentCategoryState(contentCategories);

        Promise.all(updateTasks);
    }

    return (
        <div className={`therapist-support-layout ${isNavOpen ? 'isOpen' : ''}`}>
            {/* Navigation Panel */}
            <div className="AccordionContent">
                <NavSidebar toggle={toggle}
                            isNavOpen={isNavOpen}
                            setIsNavOpen={setIsNavOpen}
                            title={'Documents & Files'}
                >
                    <DragDropContext onDragEnd={afterDragUpdateCategoryDatabase}>
                        <Droppable droppableId='content-category-drop-zone'
                            renderClone={(provided, snapshot, rubic) => (                                
                                <DraggableCategory 
                                    index={rubic.source.index} 
                                    category={contentCategories[rubic.source.index]} 
                                    isSelected={selectedContentCategory?.id === rubic.draggableId}
                                    isEditing={state.categoryEditingId === rubic.draggableId} 
                                    draggableProps={provided.draggableProps}
                                    dragHandleProps={provided.dragHandleProps}
                                    innerRef={provided.innerRef}
                                    onCategoryClick={() => selectContentCategory(rubic.draggableId)}
                                    onEditSubmit={handleEditingCategory} 
                                    onEditClick={updateCategoryForEdit}
                                    onDeleteClick={handleDeleteClick}                                
                                />
                            )}
                        >
                            {(provided, snapshot) => (
                                <div className="navigation-side-panel-inner" {...provided.droppableProps} ref={provided.innerRef}>
                                    <>
                                        {
                                            contentCategories.map((category, index) => (
                                                <Draggable
                                                    key={category.id}
                                                    draggableId={category.id}
                                                    index={index}                                                    
                                                >
                                                    {(dragProvided, snapshot) => (
                                                        <DraggableCategory 
                                                            index={index} 
                                                            category={category} 
                                                            isSelected={selectedContentCategory?.id === category.id} 
                                                            isEditing={state.categoryEditingId === category.id} 
                                                            draggableProps={dragProvided.draggableProps} 
                                                            dragHandleProps={dragProvided.dragHandleProps} 
                                                            innerRef={dragProvided.innerRef} 
                                                            onCategoryClick={() => selectContentCategory(category.id)} 
                                                            onEditClick={updateCategoryForEdit}
                                                            onEditSubmit={handleEditingCategory} 
                                                            onDeleteClick={handleDeleteClick}                                                        
                                                        />
                                                    )}
                                                </Draggable>
                                            ))
                                        }
                                        <>
                                            {
                                                <>
                                                    {showInline && (
                                                    <div className="chapter-content-container">
                                                        <BasicInlineForm
                                                            text={''}
                                                            inputCSSClass="docs-files-text-input"
                                                            placeHolderText={'Enter A Name For This Category'}
                                                            onSubmit={addNewContentCategory}
                                                            onBlur={toggleInlineForm}
                                                        />
                                                    </div>
                                                    )}
                                                </>
                                            }
                                        </>
                                    </>
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                    <>
                        {
                            isAdmin && (
                                <div className="standard-content-btn-container">
                                    <StandardContentButton
                                        isLoading={false}
                                        onClick={toggleInlineForm}
                                        btnLabel={'Add Category'}
                                    />
                                </div>
                            )
                        }
                    </>
                </NavSidebar>
            </div>
            {/* Main content container */}
            <div className="therapist-support-main-content">
                {children}
            </div>
        </div>
    )
}

export default AdminTherapistSupportLayout