import { LessonIconEnum } from '../../../../models/lesson/Lesson';
import ComboLessonIconSrc from '../../../../resources/icons/icon-combo.png';
import QuizIconSrc from '../../../../resources/icons/icon-quiz.png';
import TextIconSrc from '../../../../resources/icons/icon-reading.png';
import VideoIconSrc from '../../../../resources/icons/icon-video.png';

type LessonIconProps = {
    lessonIcon:LessonIconEnum
}

const LessonIconRender = (props:LessonIconProps) => {
    const {lessonIcon} = props;

    const getIconSrc = () => {
        switch (lessonIcon) {
            case LessonIconEnum.VideoIcon: {
                return VideoIconSrc;
            }                
                
            case LessonIconEnum.TextIcon: {
                return TextIconSrc;
            }
                
            case LessonIconEnum.QuizIcon: {
                return QuizIconSrc;
            }
                
            case LessonIconEnum.ComboLessonIcon: {
                return ComboLessonIconSrc;
            }

            default:{
                return ""; 
            }
        }
    }

    return (
        <img src={getIconSrc()} alt="Lesson Icon " />
    )
}

export default LessonIconRender;