import { useAccordionButton } from 'react-bootstrap';
import EditIconSrc from '../../../../resources/icons/icon-edit.png';

type ContentItemNavigationBtnProps = {
    eventKey:string
    callback:any
}

const ModuleItemNavigationBtn = (props:ContentItemNavigationBtnProps) => {
    const {eventKey, callback} = props;    
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => {callback && callback(eventKey)},
    );

    return (        
        <img src={EditIconSrc} alt="Edit Button" onClick={decoratedOnClick}/>        
    )
}

export default ModuleItemNavigationBtn