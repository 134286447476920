import { AxiosError } from 'axios';
import { TagTransferObject, _idObject } from "../models/AdditionalVideoContent/AdditionalVideoContent";
import { ContentCategory, IContentCategory } from '../models/AdditionalVideoContent/ContentCategory';
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ContentCategoryService } from "../services/ContentCategoryService";

export const privateContentCategoryId = "6335ba944417423d7b3bff59";

export class ContentCategoryController
{
    constructor()
    {
        this.contentCategoryService = new ContentCategoryService();
    }

    private contentCategoryService:ContentCategoryService;    

    public async CreateContentCategory(contentCategoryData:IContentCategory) {
        try {
            return this.contentCategoryService.Post(contentCategoryData);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async UpdateContentCategory(contentCategoryData:IContentCategory):Promise<ContentCategory> {
        try {
            return this.contentCategoryService.Put({id:contentCategoryData.id},contentCategoryData);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async DeleteContentCategory(id:string) {
        try {
            return this.contentCategoryService.Delete({id});
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async RemoveTagFromPrivateCategory(tag:string) {
        try {
            let contentCategory:IContentCategory = await this.contentCategoryService.Get({id:privateContentCategoryId});

            //removing the old content tag from the list
            contentCategory.tags = ContentCategory.filterTagList(contentCategory, {_id: tag});   

            await this.contentCategoryService.Put({id: privateContentCategoryId}, contentCategory);
            
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetAdditionalVideosByTagList(tagList: TagTransferObject)
    {
        try
        {
            return this.contentCategoryService.GetAdditionalVideosForTagList(tagList);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async GetAllCategories():Promise<ContentCategory[]>
    {
        try
        {
            return this.contentCategoryService.GetAll();
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }    

    /**
     * Get only public categories and filter out the private category
     * @returns 
     */
    public async GetAllPublicCategories():Promise<ContentCategory[]>
    {
        try {            
            const categories:IContentCategory[] = await this.contentCategoryService.GetAll();                                                        

            return categories;
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CheckIfLessonIdExistsInPrivateCategory(lessonTagId:string):Promise<boolean> {
        try {
                     
            const doesLessonIdExistInPrivate = await this.contentCategoryService.CheckIfLessonIdExistsInPrivateCategory(lessonTagId);

            return doesLessonIdExistInPrivate;
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Check if a tag exists in a Content Category and update the category if 
     * it does not exist and return null if it do it exist.
     */
    public async CheckIfTagExistsInCategoryAndUpdate(id:string, tagId:string):Promise<ContentCategory> {
        try {
            const tagIdObj = new _idObject();
            tagIdObj._id = tagId;
            return await this.contentCategoryService.CheckIfTagExistsInCategoryAndUpdate(id, tagIdObj);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));           
        }
    }

}