export default class CloudflareImageHelper {
    //Account hash
    public static imageUrl = "https://imagedelivery.net/nbGmV_kxvKnLmWKRzJ21TQ/"
    public static variants = {
        public: "public",
        imageThumb: "imagethumb",        
        defaultProfileImage: "defaultProfileImage",
        navbarUserImage: "navbarUserImage",
        userProfileImage:"userProfileImage",       
        pixelTrainerTextLesson:"pixelTrainerTextLesson", 
    }
}