import React, { useEffect, useState } from 'react';
import { GroupedAdditionalContent, IAdditionalContent } from '../../../models/AdditionalContent/AdditionalContent';
import {v4 as uuidV4} from 'uuid';
import {
    DndContext,
    closestCenter,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors
} from '@dnd-kit/core';
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    rectSortingStrategy
} from '@dnd-kit/sortable';
import AdminTherapistSupportContentItem from './AdminTherapistSupportContentItem';
import { IContentCategory } from '../../../models/AdditionalVideoContent/ContentCategory';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import { AdditionalContentController } from '../../../controllers/AdditionalContentController';
type AdminTherapistContentItemListProps = {
    subCategoryId:string
    allAdditionalContentGrouped:GroupedAdditionalContent[]
    isAdmin:boolean
    updateAdditionalContentItems: (msg: string) => void
    selectedContentCategory:IContentCategory
}

type AdminTherapistContentItemListState = {
    currentAdditionalContentList:IAdditionalContent[],
}

const additionalContentController = new AdditionalContentController();

const AdminTherapistContentItemList = (props:AdminTherapistContentItemListProps) => {
    const {subCategoryId} = props;

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates
        })
    );


    const defaultState:AdminTherapistContentItemListState = {
        currentAdditionalContentList: []
        
    }

    const [state, setState] = useState(defaultState);

    useEffect(() => {
        function onComponentMount() {
            displayAdditionalContent(subCategoryId)
        }

        onComponentMount();
    }, [props.allAdditionalContentGrouped]);

    const displayAdditionalContent = (subCategoryId:string) => {
  
        const foundContent = props.allAdditionalContentGrouped.find(content => content.tagId === subCategoryId);

        if(!foundContent)
            return [];
        
        foundContent.content = foundContent.content.filter((content, index) => 
        !index || (content.id !== foundContent.content[index - 1].id));

        foundContent.content.sort((a, b) => {
            const foundIndex = a.tagList.findIndex(t => t._id === subCategoryId);

            const foundBIndex = b.tagList.findIndex(t => t._id === subCategoryId);

            return a.tagList[foundIndex].order - b.tagList[foundBIndex].order;
        });

        setState(prevState => ({
            ...prevState,
            currentAdditionalContentList:foundContent.content
        }));
    }    

    const handleDragEnd = async (event:any) => {
        const {active, over} = event;

        if(active.id === over.id) {
            return;
        }
        
        const contentList:IAdditionalContent[] = DeepCopy.copy(state.currentAdditionalContentList);

        const oldIndex = state.currentAdditionalContentList.findIndex(content => content.id === active.id);
        const newIndex = state.currentAdditionalContentList.findIndex(content => content.id === over.id);

        const foundOldTagListIdx = state.currentAdditionalContentList[oldIndex].tagList.findIndex(tag => tag._id === subCategoryId);
        const foundNewTagListIdx = state.currentAdditionalContentList[newIndex].tagList.findIndex(tag => tag._id === subCategoryId);

        contentList[oldIndex].tagList[foundOldTagListIdx].order = newIndex;
        contentList[newIndex].tagList[foundNewTagListIdx].order = oldIndex;

        const newList = arrayMove(contentList, oldIndex, newIndex);

        setState(prevState => ({
            ...prevState,
            currentAdditionalContentList:newList
        }));

        for(let i = 0; i < newList.length; i++) 
        {   
            const item = newList[i];
            const foundTagListIdx = item.tagList.findIndex(tag => tag._id === subCategoryId);

            newList[i].tagList[foundTagListIdx].order = i;
        }
        
        await Promise.all(newList.map(additionalContent => (additionalContentController.updateAdditionalContentItem(additionalContent))));
    }

    return (
        <div className="tag-content-container">
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={state.currentAdditionalContentList}
                    strategy={rectSortingStrategy}
                >
                    {
                        state.currentAdditionalContentList.map(((additionalContent, index) => (
                            <div key={uuidV4()}>
                                    <AdminTherapistSupportContentItem 
                                        subCategory={props.subCategoryId}
                                        additionalContent={additionalContent} 
                                        updateAdditionalContentItems={props.updateAdditionalContentItems}
                                        isAdmin={props.isAdmin}   
                                        index={index}                                                                                                                                                                                                                                       
                                    />  
                            </div>  
                    )))}
                </SortableContext>
            </DndContext>
        </div>
    )
}

export default AdminTherapistContentItemList;