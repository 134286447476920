import * as React from 'react';
import toast from 'react-hot-toast';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
    Col, Container, Form, FormFeedback, FormGroup, Input,
    Label, Row
} from 'reactstrap';
import { NotificationController } from "../../controllers/NotificationController";
import { UserController } from "../../controllers/UserController";
import Roles from '../../models/utility/Roles';
import { StringHelper } from '../../models/utility/StringHelper';
import { UserService } from '../../services/UserService';
import { ApplicationState } from '../../store';
import * as CurrentUserStore from '../../store/CurrentUser';
import './Login.css';
import { Link } from 'react-router-dom';

type currentUserProps =
    CurrentUserStore.CurrentUserState
    & typeof CurrentUserStore.actionCreators
    & RouteComponentProps<{}>


// The current state of the login fields
type LoginState = {
    username: string;
    password: string;
    errors: string[];
    isLoading: boolean;
}


// Login Class component sends an username and password to an API server and should get back some type of token
class Login extends React.Component<currentUserProps, LoginState> {
    private successLoginMsg = "Successfully Logged In"
    uController: UserController = new UserController();
    nController: NotificationController = new NotificationController();
    UService = new UserService();

    // Initialize the username and password states to be empty
    state: LoginState = {
        username: '',
        password: '',
        errors: [],
        isLoading: false
    };

    /**
     * Method for clearing input fields
     */
    private clearInputFields = () => {
        this.setState({username: '', password: ''});
    }

    /**
     * Set the state back to a default state
     */
    private unloadState = () => {
        this.setState({username: '', password: '', isLoading: false, errors: []});
    }


    /**
     * Makes an API call when the form is submitted and stores the received JWT in redux
     * @param e Form Event
     */
    private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        // prevents the page from loading on
        e.preventDefault();

        const username = this.state.username.trim().toLowerCase();

        const password = this.state.password;

        try {

            this.setState(prevState => ({
                ...prevState,
                isLoading: true
            }));

            // const userProfile = await this.uController.Authenticate(username, password);

            const userProfile = await this.UService.Login(username, password);
            
            const notificationCount = await this.nController.GetUnreadNotificationsCount(userProfile.id);

            const currentUser: CurrentUserStore.CurrentUserState = {
                userProfile,
                isAdmin: userProfile.roles.includes(Roles.Admin),
                isSuperAdmin: userProfile.roles.includes(Roles.SuperAdmin),
                isLoggedIn: true,
                notificationCount : notificationCount,
            }
            
            // Set the current user in redux
            this.props.setUser(currentUser);

            this.setState(prevState => ({
                ...prevState,
                isLoading: false
            }));

            // clear fields after the form has been submitted
            this.clearInputFields();            

            this.navigateTo(currentUser.userProfile.assignedSectionId);

            toast.success(this.successLoginMsg);

        } catch (errors) {
            let listOfErrors: string[] = [];

            const msg = "Invalid Username/Password combination";

            toast.error(msg);

            listOfErrors = [msg];

            this.setState(prevState => ({
                ...prevState,
                isLoading: false,
                errors: listOfErrors
            }));
        }
    }

    private navigateTo = (courseId:string) => {
        
        const {history} = this.props;

        let param = courseId;

        if(StringHelper.IsNullOrWhiteSpace(courseId)) {
            //For when we do not have a course created or assigned so we do not get a 404 error (the id here is not real)             
            param = "651f01e047684706e6efa8be";
        }

        history.push(`/learningDashboard/${param}`);
    }

    // Change state when an input element changes; Note: There is a better way to do this
    private onChange = (e: React.FormEvent<HTMLInputElement>): void => {
        // Get the name of the current active input field
        const inputField = e.currentTarget.name;

        // because there are only two fields we are using check which one it is
        if (inputField === 'username')
            this.setState({username: e.currentTarget.value});
        else
            this.setState({password: e.currentTarget.value});
    };


    public render() {
        return (
            <React.Fragment>
                <Container>
                    <Form className="login-form" onSubmit={this.onSubmit}>
                        <h2>Login</h2>
                        <Row>
                            <Col md={12}>
                                <FormGroup floating="true">
                                    <Label for="username">Username</Label>
                                    <Input invalid={this.state.errors.length > 0}
                                           name="username" type="text" id="username" placeholder="Username"
                                           value={this.state.username} onChange={this.onChange} required/>
                                    <FormFeedback>
                                        Invalid Username
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12}>
                                <FormGroup floating="true">
                                    <Label for="password"> Password </Label>
                                    <Input invalid={this.state.errors.length > 0}
                                           name="password" type="password" id="password" placeholder="Password"
                                           value={this.state.password}
                                           onChange={this.onChange} required/>
                                    <FormFeedback>
                                        Invalid Password
                                    </FormFeedback>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <div className="login-form-button-container">
                                {this.state.isLoading ? (
                                        <button disabled className='btn-cbit-primary'>
                                            Loading...
                                        </button>
                                    )
                                    :
                                    <button type="submit" color="primary" className='btn-cbit-primary'>
                                        Sign In
                                    </button>
                                }
                                
                                <div className="forgot-creds-container">
                                    <Link to="/forgot-password">Forgot Username/Password?</Link>                                    
                                </div>
                            </div>
                        </Row>
                    </Form>
                </Container>

            </React.Fragment>
        );
    }
};

export default connect(
    (state: ApplicationState) => state.currentUser,
    CurrentUserStore.actionCreators
)(Login as any)
