export default class FormHelper {
    /**
     * Removes white space from the given string
     * @param str 
     * @returns 
     */
    public static RemoveWhiteSpace = (str:string) => {
        return str.split(" ").join("");
    }

    /**Max length error message */
    public static maxErrorMsg = (fieldName:string, maxLen:number) => (`${fieldName} must be less than ${maxLen} characters`);
    
    /**Min length error message */
    public static minErrorMsg = (fieldName:string, minLen:number) => (`${fieldName} must be ${minLen} or more characters`);
    
    /**Error message for required fields */
    public static requiredErrorMsg = (fieldName:string) => (`Please provide a valid ${fieldName}`);
}

/**Max about of characters a description can be */
export const MaxCharactersForDescription = 2000;

/**
 * Determines if we should display max character description error styles
 * @param text
 * @returns 
 */
export const displayMaxCharDescError = (text:string) => {
    return text.length > MaxCharactersForDescription ? "my-profile-desc-error" : ""
}