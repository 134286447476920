import * as React from "react";
import { FunctionComponent, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { connect, useDispatch } from "react-redux";
import { RouteComponentProps } from "react-router";
import { Spinner } from "reactstrap";
import { ModuleController } from "../../../controllers/ModuleController";
import { PublishedLessonController } from "../../../controllers/PublishedLessonController";
import { PublishedModuleController } from "../../../controllers/PublishedModuleController";
import { UserLessonController } from "../../../controllers/UserLessonController";
import { UserModuleController } from "../../../controllers/UserModuleController";
import { LessonIconEnum } from "../../../models/lesson/Lesson";
import { ICurrentUser } from "../../../models/User/UserProfile";
import { PartialModuleTreeModel } from "../../../models/partialModels/PartialModuleTreeModel";
import { ErrorHandler } from "../../../models/utility/ErrorHandler";
import NavMenuIcon from '../../../resources/icons/icon-nav-menu.png';
import { ApplicationState } from "../../../store";
import * as CurrentUser from "../../../store/CurrentUser";
import { lastLessonActionCreators } from "../../../store/LastLessonStore";
import * as UserTreeView from "../../../store/UserModuleAccordionStore";
import { cleanState } from "../../AdminDashboard/AdminAccordion/AdminModuleAccordion";
import CustomModal from "../../Utilities/CustomModal";
import IntroVideo from "../../Utilities/IntroVideo";
import ModuleAccordionItemComp from "./ModuleAccordionItemComp";
import './UserModuleAccordion.css';

type AccordionProps =
    UserTreeView.currentUserAccordionProps
    & CurrentUser.CurrentUserState
    & typeof UserTreeView.actionCreators
    & RouteComponentProps
    & { id: string }
    & moduleAccordionProps;


type moduleAccordionProps = {
    isAdmin: boolean
    userProfile: ICurrentUser
    isLoggedIn: boolean
    id: string
    SetUserAccordion: any
    SelectedModule: PartialModuleTreeModel
    handleSidebar: any
}

const LearningModuleAccordionComp: FunctionComponent<AccordionProps> = (props) => {
    const publishedLessonController = new PublishedLessonController();
    const moduleController: ModuleController = new ModuleController();
    const publishedModuleController:PublishedModuleController = new PublishedModuleController();    
    const userModController: UserModuleController = new UserModuleController();
    const userLessonController: UserLessonController = new UserLessonController();    

    const {setLastLesson} = lastLessonActionCreators;

    const dispatch = useDispatch();

    const [parentModule, setParentModule] = useState<PartialModuleTreeModel>({
        id: '',
        originalDocumentId: '',
        type: 0,
        order: 0,
        name: '',
        description: '',
        isViewable: true,
        isDisabled: false,
        isEditable: true,
        isOptional: true,
        isComplete: false,
        isOpen: false,
        parentDocumentId:'',
        isNext: false,
        time: '',
        children: [],
        isLocked:true,
        totalQuestions:0,
        lessonIcon:LessonIconEnum.Unknown,
    });
    const [selectedModule, setSelectedModule] = useState<PartialModuleTreeModel>({
        id: '',
        originalDocumentId: '',
        type: 0,
        order: 0,
        name: '',
        description: '',
        isViewable: true,
        isDisabled: false,
        isEditable: true,
        isOptional: true,
        isComplete: false,
        parentDocumentId:'',
        isOpen: false,
        isNext: false,
        time: '',
        children: [],
        isLocked:true,
        totalQuestions:0,
        lessonIcon:LessonIconEnum.Unknown,

    })
    const [selectedBranch, setSelectedBranch] = useState<PartialModuleTreeModel>({
        id: '',
        originalDocumentId: '',
        type: 0,
        order: 0,
        name: '',
        description: '',
        isViewable: true,
        isDisabled: false,
        isEditable: true,
        parentDocumentId:'',
        isOptional: true,
        isComplete: false,
        isLocked:true,
        isOpen: false,
        isNext: false,
        time: '',
        children: [],
        totalQuestions:0,
        lessonIcon:LessonIconEnum.Unknown,
    });

    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [showIntroVideo, setShowIntroVideo] = useState(false);    

    useEffect(() => {
        const setLastLessonInRedux = async () => {

            const lastLesson = await publishedLessonController.GetLastLesson(props.id);
            if(!lastLesson) {
                return;
            }            

            dispatch(setLastLesson({lastLesson}));

        }

        const GetData = async (parentData: any, SetData: any, SetLoading: any, SetAccordion: any) => {

            if (props.id !== null) {
                let ogParent = await publishedModuleController.GetModule(props.id);
                let parent: PartialModuleTreeModel = props.parentModule == null ?
                    {
                        id: '',
                        originalDocumentId: '',
                        type: 0,
                        order: 0,
                        name: '',
                        description: '',
                        isViewable: true,
                        isDisabled: false,
                        isEditable: true,
                        isOptional: true,
                        isComplete: false,
                        parentDocumentId:'',
                        isOpen: false,
                        isNext: false,
                        time: '',
                        children: [],
                        isLocked:true,
                        totalQuestions:0,
                        lessonIcon:LessonIconEnum.Unknown,
                    } : props.parentModule;

                parent.name = ogParent.name;
                parent.originalDocumentId = props.id;

                if (parent.id === '') {
                    parent.children = [];

                    let userModules: PartialModuleTreeModel[];
                    let hasModules = await userModController.DoesUserHaveModules(props.id);
                    if(!hasModules)
                    {
                        // toggleShowIntroVideo();
                        //Create New Modules
                        await userModController.InitializeUserDataForParentId(props.id);
                        

                        //Get Data
                        userModules  = await userModController.GetAssignedPartialUserModulesByParentId(props.id);

                    }
                    else{
                        //The order of these two functions mater. UpdateUserCourse needs to happen first in order to create new user modules
                        //Check and performs Updates                                                
                        await userModController.UpdateUserCourse(props.userProfile.id, props.id);
                        userModules  = await userModController.GetAssignedPartialUserModulesByParentId(props.id);                        
                    }

                    parent.children = userModules;
                }

               
                let accordionFound = false;

                let childToSet;
                let alternateChild;
                parent.children.forEach((child,index) => {
                    if (child.isNext && !accordionFound) {
                        childToSet = child;
                        accordionFound = true;
                        return
                    } else if (child.children.length > 0) {
                        child.children.forEach((subChild,subIndex) => {

                            if(index == 0 && subIndex == 0){
                                alternateChild = subChild;
                            }

                            if (subChild.isNext && !accordionFound) {
                                childToSet = subChild;
                                accordionFound = true;
                                return;
                            }else if(subChild.children.length >0){
                                subChild.children.forEach((thirdChild) =>{
                                    if(thirdChild.isNext && !accordionFound){
                                        childToSet = thirdChild;
                                        accordionFound = true;
                                        return;
                                    }
                                    if(accordionFound)
                                        return;
                                })
                            }
                            if(accordionFound)
                                return;
                        })
                    }
                    if(accordionFound)
                        return;
                })

                if(childToSet == undefined){
                    childToSet =alternateChild;
                }

                SetAccordion(childToSet);
                SetData(parent);
                SetLoading(false);
            }
        }

        try {
            setLastLessonInRedux();
            GetData(parentModule, setParentModule, setIsLoading, props.SetSelectedModule)
            //If the property id is not null then load in the entire module

        } catch (error) {
            ErrorHandler.generalError((error));
        }
        return () => {
            props.SetUserAccordion(cleanState)
        }
    }, [])

    const navigateHome = () => {
        props.history.push('/home');
    }

    /**
     * toggles the modal that shows the intro video open or close
     */
    const toggleShowIntroVideo = () => {
        setShowIntroVideo(!showIntroVideo);
    }

    return (
        <>
            <div className='mainAccordion'>
                {isLoading ? (
                    <div className="training-according-loading">
                        <Spinner color="secondary"></Spinner>                    
                    </div> 
                ) : (
                    <>
                        {/* Accordion header */}
                        <div className="navigation-header">
                            <div className={'navigation-title-container'}>
                                {/* <div className='faded-navigation-section-label'>
                                    <a onClick={navigateHome}>
                                        BACK TO DASHBOARD
                                    </a>
                                </div> */}
                                {/* Nav icon button */}
                                <div className="nav-menu-icon-container" onClick={props.handleSidebar}>
                                    <img src={NavMenuIcon} alt="Nav menu icon" className={'nav-menu-icon'}/>
                                </div>
                            </div>
                            <div>
                                <div className='navigation-section-label'>
                                    {parentModule.name}
                                </div>
                            </div>
                        </div>
                        <Accordion defaultActiveKey="0" flush className='accordion-body'>
                            {parentModule?.children?.map((module) =>
                                (

                                    <ModuleAccordionItemComp key={module.id}
                                                             depth={0}
                                                             parentModule={props.parentModule}
                                                             selectedModule={props.selectedModule}
                                                             SetSelectedItem={props.SetSelectedModule}
                                                             SetSelectedBranch={setSelectedBranch}
                                                             selectedBranch={selectedBranch}
                                                             module={module}/>
                                ))}
                        </Accordion>
                    </>
                )}

            </div>
            {/* <CustomModal
                isOpen={showIntroVideo} 
                toggle={toggleShowIntroVideo}
                className="bg-transparent intro-video-modal"
            >
                <IntroVideo showTitle={true} />
            </CustomModal> */}
        </>
    )
}

export default connect(
    (state: ApplicationState) => state.currentUserModuleAccordion,
    UserTreeView.actionCreators
)(LearningModuleAccordionComp);