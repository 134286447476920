import { Link } from "react-router-dom";

import userManaSrc from "../../../resources/icons/icon-user-mana.png";
import AdminActionFullItem from "./AdminActionFullItem";
import ContentLog from "./ContentLog";
import "./ContentManagement.css";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";

const ContentManagement = () => {
    const UserStore = useSelector<ApplicationState, CurrentUserState |undefined>((state) => state.currentUser);

    /**
     * Get the assigned section id from the user store. 
     * The course that we can build is the module id we are assigned to
     */
    const getAssignedCourse = () => {
        if(!UserStore) {
            return "";
        }

        const {userProfile: {assignedSectionId}} = UserStore;

        return assignedSectionId;
    }
    return (
        <div className="content-management">
            <div className="content-mana-left cbit-dialog">
                <h1 className="content-mana-header cbit-dashboard-header">Content Management</h1>
                <p className="content-mana-desc">Welcome to the the Pixel Trainer Administrative website! Visit the pages below to manage all Pixel Trainer course materials and resources.</p>
                <div className="content-mana-actions">
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            // to={'/course-selection'}
                            to={`/content-builder/${getAssignedCourse()}`}                                 
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Course Builder
                                </div>
                                <div className="admin-action-content">
                                    Build and modify the course content, including lessons, videos, quizzes, titles etc.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 

                            className='admin-action-link disabled-link'
                            to="/therapist-toolbox-admin"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Company Events
                                </div>
                                <div className="admin-action-content">
                                    Simplify and enhance the process of planning, coordinating, and executing company events. 
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/course-selection"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Course Selection
                                </div>
                                <div className="admin-action-content">
                                    View, create, and edit courses
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link disabled-link'
                            to="/continuing-education/upload-webinars"                        
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    CONTINUING EDUCATION
                                </div>
                                <div className="admin-action-content">
                                    Simplify and enhance the process of continuing education for individuals, educational institutions, and organizations.                                  
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link'
                            to="/document-files-admin"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    Documents and Files 
                                </div>
                                <div className="admin-action-content">
                                    Populate resources and helpful information.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>
                    <div className="admin-action-item">
                        <Link 
                            className='admin-action-link disabled-link'
                            to="/social-connection/admin"
                        >
                            <div className="admin-action-inner">
                                <div className="admin-action-header cbit-dashboard-header">
                                    SOCIAL CONNECT
                                </div>
                                <div className="admin-action-content">
                                    Manage online forum activities and promote popular posts to FAQ section.
                                </div>
                            </div>
                            <div className="btm-banner"></div>
                        </Link>
                    </div>                                                        
                </div>
            </div>
            <div className="content-mana-right">
            <Link
                    to="/users"
                >
                <AdminActionFullItem 
                    mainContainerClass={"user-management"} 
                    iconSrc={userManaSrc} 
                    title={"USER MANAGEMENT"} 
                    description={"Create, update, and manage CBIT Trainer users and admins."}
                    />
                </Link>
                <ContentLog/>

            </div>
        </div>
    )
}

export default ContentManagement;
