import { GridColDef, GridValueGetterParams } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { UserWithCourse } from "../../../models/User/UserWithCourse";
import { RolesHelper } from "../../../models/utility/Roles";
import TimeHelper from "../../../models/utility/TimeHelper";
import IconEdit from '../../../resources/icons/icon-edit.png';
import IconDelete from '../../../resources/icons/icon-trash.png';
import CbitDataGrid from "../../Utilities/CbitTable/CbitDataGrid";
import "./UserManagementTable.css";

type UserManagementTableProps = {
    users:UserWithCourse[];
    isLoading:boolean;
    updateIsLoading:(isLoading:boolean) => void;        
    onDelete:(user:UserWithCourse) => void;
    getUsers:() => void;
}

/**
 * Get user full name to display in the table
 * @param params 
 * @returns 
 */
function fullNameGetter(params:GridValueGetterParams){    
    return `${params.row.firstName} ${params.row.lastName}`;
}

/**
 * Get a users highest role to display in the table
 */
function roleGetter(params:GridValueGetterParams){
    const roles = params.row.roles;

    return RolesHelper.GetHighestRole(roles);
}

/**
 * Get a users sign up date and format it into a readable text
 * Note: the time will display in the admin that is viewing it timezone
 * @param params 
 */
function signUpDateGetter(params:GridValueGetterParams){
    const date = params.row.createdAt;

    if(date) {
        return TimeHelper.formatFullDateAndTime(date, "YYYY-MM-DD h:mm A");
    } else {
        return "-";
    }    
}

/**
 * Get user course name for displaying
 * @param params 
 * @returns 
 */
function getUserCourse(params:GridValueGetterParams) {
    const user = params.row as UserWithCourse;

    const {assignedCourse}  = user;

    if(!assignedCourse) {
        return "Not assigned a course";
    }

    const {name} = assignedCourse;

    return name ?? "-";
}

/**
 * Get user quiz results for displaying
 * @param params 
 */
function getUserQuizResults(params:GridValueGetterParams) {
    const user = params.row as UserWithCourse;

    const {quizQuestionsAsked} = user;
    const noResults = "Haven't completed a quiz";
    if(!quizQuestionsAsked) {
        return noResults;
    }

    const {answered, asked}  = quizQuestionsAsked;
    if(answered === 0 && asked === 0) {
        return noResults;
    }

    return `${answered}/${asked}`;    
}

const noUsersFoundTxt = "No Users Found";

/**
 * Component for admins to view and manage users
 */
const UserManagementTable = (props:UserManagementTableProps) => {
    const {
        users,
        isLoading,
        updateIsLoading,
        onDelete,
        getUsers,
    } = props;        

    const userColumns:GridColDef[] = [
        {
            field: 'role',
            headerName: 'Role',
            headerClassName: 'user-table-header',
            flex:1,
            valueGetter:roleGetter,
        },
        {
            field: 'fullName', 
            headerName: 'Full Name',
            headerClassName: 'user-table-header', 
            flex:1,
            valueGetter:fullNameGetter,
        },
        {
            field: 'name',
            headerName: 'Username',
            headerClassName: 'user-table-header',
            flex:1,
        },
        {
            field: 'email',
            headerName: 'Email',
            headerClassName: 'user-table-header',
            flex:2,
        },
        {
            field: 'Course',
            headerName:'Course',
            headerClassName: 'user-table-header',
            flex:2,
            valueGetter:getUserCourse
        },
        {
            field: 'Quiz Results',
            headerName:'Quiz Results',
            headerClassName: 'user-table-header',
            flex:2,
            valueGetter:getUserQuizResults
        },
        {
            field: 'createdAt',
            headerName: 'Sign Up Date',
            headerClassName: 'user-table-header',
            flex:1,
            valueGetter:signUpDateGetter
        },
        {
            field: 'actions',
            headerName: 'Actions',
            headerClassName: 'user-table-header',            
            sortable:false,
            flex:0,
            disableExport:true,
            filterable:false,
            renderCell: (cell:any) => {                
                const row = cell.row;
                return (
                    <div className='user-management-actions'>
                        <Link to={`/users/${row.name}/edit`} className='table-action-edit btn-cbit-link'>
                            <img src={IconEdit} alt="Edit" />
                        </Link>
                        <button 
                            onClick={() => onDelete(row)}
                            className='table-action-delete btn-cbit-link'
                        >
                            <img src={IconDelete} alt="Delete" />
                        </button>
                    </div>
                )
            }
        }
    ];
    
    return (
        <div className="user-mana-table">
            <CbitDataGrid 
                isLoading={isLoading}
                columns={userColumns}
                rowData={users}
                columnVisibilityModel={{referralStatus:false}}
                noRowsText={noUsersFoundTxt}
            />                                 
        </div>
    )
}

export default UserManagementTable;