import { IUserBio } from "../../../models/User/UserProfile";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import ProfileImage from "../../Utilities/ProfileImage/ProfileImage";

const imageVariant = CloudflareImageHelper.variants.defaultProfileImage;

type MyProfileInfoProps = {
    user:IUserBio;
    toggleEditing:() => void;
} 

/**
 * Renders basic user profile information
 */
const MyProfileInfo = (props:MyProfileInfoProps) => {
    const {user, toggleEditing} = props;

    /**
     * Returns the user full name
     */
    const getUserFullName = () => {
        if(!user){
            return "";
        } 
        return `${user.firstName} ${user.lastName}`;
    }

    return (
        <div className="my-profile cbit-dialog">
            <h2 className="cbit-dashboard-header">My profile</h2>                    
            <div className="my-profile-inner">
                <div className="profile-inner-left">
                    <ProfileImage 
                        imageLink={user?.imageLink ?? ""}
                        imageVariant={imageVariant}
                    />
                </div>
                <div className="profile-inner-right">                        
                    <h4 className="cbit-small-header">{getUserFullName()}</h4>                                                    
                    <div>
                        <div className="cbit-label">Username</div>
                        {user.name}
                    </div>
                    <div>
                        <div className="cbit-label">Email</div>
                        {user.email}
                    </div>
                    <div>
                        <div className="cbit-label">bio</div>
                        <p className="profile-bio-desc">
                            {
                                user && user?.description.length <= 0 ? 
                                (
                                    "No boi was provided"
                                ) 
                                : 
                                (
                                    user?.description
                                )
                            }
                        
                        </p>
                    </div>
                    <div className="my-profile-btn-container">
                        <button
                            className="btn-cbit-minor"
                            onClick={toggleEditing}
                        >
                                Edit Profile
                        </button>
                    </div>
                </div>
                </div>
    </div> 
    )
}

export default MyProfileInfo;