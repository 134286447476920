import moment from "moment";

export default class TimeHelper {
    /**Format for moment to show time like: 5:30 pm */
    static hoursMinTimeFormat = "h:mm A";

    /**Formats date to look like: August 29, 2022 */
    static fullDate = "MMMM DD, YYYY";

    /**Certain browsers require moment to have a set of formats specified in moments constructor */
    static momentFormats = ["MM-DD-YYYY", "YYYY-MM-DD", "MM/dd/yyyy", "yyyy/MM/dd"];
    /**
     * Converts seconds to a duration string
     * @param seconds 
     */

    /**
     * Creates a moment date object with moment date formats
     * because safari needs to have moment formats in order to work
     */
    static createMomentDate = (date:Date|string) => {
        // const temp = moment(new Date(date), TimeHelper.momentFormats);
        
        return moment(new Date(date), TimeHelper.momentFormats);
    }

    static convertSecondsToDuration = (seconds:number) => {
        const hour = 3600;        
        
        let duration = "00:00";

        // If we need to return duration in hours
        if(seconds > hour) {
            const date = moment(new Date(), TimeHelper.momentFormats).toDate();

            date.setSeconds(seconds);
            
            //Where we want to stop in the ISO string
            const end = date.toISOString().length - 5;

            duration = date.toISOString().substring(11, end);
        } else {
            const oneMinute = 60;

            const minutes = Math.floor(seconds/oneMinute);  // Getting the minutes as a whole number

            const remainder  = seconds%oneMinute; // Getting the left overs seconds

            // We need to add 0s before both parts of the string
            duration = `${minutes.toString().padStart(2,'0')}:${remainder.toString().padStart(2,'0')}`;             
        }

        return duration;
    }

    /**     
     * Get the next upcoming half hour of the passed in date
     * For example: 4:53 -> 5:00, 5:15 -> 5:30
     */
    static getNextHalfHour = (date:Date|string) => {        
        //creating new date object so we do mutate the passed in one
        const updatedDate = moment(date, TimeHelper.momentFormats);
        
        const currentTime = TimeHelper.createMomentDate(new Date());           
        
        const minutes = Math.ceil(currentTime.minutes()/30)*30;
        
        const hours = currentTime.hours();

        const seconds = 0

        updatedDate.set(
            {
                hours,
                minutes, 
                seconds
            }
        );

        return updatedDate.toDate();
    }
    
    /**
     * checks if a date has passed or not
     * @param date 
     */
    static checkDateHasPast = (date:Date) => {
        const today = moment(new Date(), TimeHelper.momentFormats).toDate();
        
        const isBefore = moment(date, TimeHelper.momentFormats).isBefore(today);

        return isBefore;
    }

    /**
     * Formats date to show the full date (August 29, 2022) and the time (5:40 pm)
     * pass in a format to override default format
     */
    static formatFullDateAndTime = (date:Date|string, format?:string) => {
        let defaultFormat = format ? format : "MMMM DD, YYYY h:mm A"
        return moment(date).format(defaultFormat);
    }

    /**
     * Sorts time either by ascending or descending
     * @param dateA 
     * @param dateB 
     * @param desc 
     * @returns 
     */
    static SortObjectByTime = (dateA:string, dateB:string, desc:boolean) => {
        if(desc) {
            return moment(dateA as string).isBefore(moment(dateB as string)) ? 1 : -1;
        }
        return moment(dateA as string) < (moment(dateB as string)) ? -1 : 1;      
    }

    /**
     * Get the amount of days within a week based on 
     * Sunday as the start of a week
     * @param date 
     * @returns 
     */
    static WeeksInMonth(date:Date) {
        const year:number = date.getFullYear();
        
        const monthNumber:number = date.getMonth();         
            
        const daysInWeek = 7;

        const firsDayOfMonth = new Date(year, monthNumber, 1);

        // The 0 as the last parameter gets the last date in a month
        const lastDayOfMonth = new Date(year, monthNumber, 0);
    
        const total = firsDayOfMonth.getDay() + lastDayOfMonth.getDate();
        
        return Math.ceil(total/daysInWeek);
    }
    
    /**
     * Updates the end time to add the passed in amount of minutes from the start time
     * @param mins 
     * @returns 
     */
    static GetTimeFromMinutes = (mins:number, startDate:string) => {            
        
        const startTime = new Date(startDate);
            
        const endTime = new Date(startTime.setMinutes(startTime.getMinutes() + mins));                            
    
        return moment(endTime, TimeHelper.momentFormats).format('LT')
    }
    
    /**
     * Wrapper for moment function to check if it is daylight savings or not
     * @param date 
     * @returns 
     */
    static CheckIsDayLightSavings = (date:string) => {
        return moment(date).isDST();
    }
    
    /**
     * For when you want to add an hour when the date is in daylight savings (dst) and today is not
     * More info:
     * https://momentjs.com/docs/#/query/is-daylight-saving-time/     
     * @param date 
     * @returns 
     */
    static HandleDateDST = (dateToHandle:string, refDate:string) => {
        let newDate = moment(dateToHandle);        
        
        //If our date is not in DST but we are
        if(!moment(dateToHandle).isDST() && moment(refDate).isDST()) {                  
            dateToHandle = newDate.add(1, 'hour').toString();        
        } else if(moment(dateToHandle).isDST() && !moment(refDate).isDST()) {
            dateToHandle = newDate.subtract(1, 'hour').toString();
        }
        
        return dateToHandle;
    }

    /**
     * Get a list of available times for the start time dropdown
     * Right now time is in 15 minute increments 
     * TODO filter out times before the current time
     * @returns 
     */
    static CreateTimeList = (dateStr:string) => {                        
        const incrementBy = 15;
        const todayStart = TimeHelper.createMomentDate(dateStr).startOf('D');
        let updatedTime = TimeHelper.createMomentDate(todayStart.toDate());
        

        const timePeriod = moment.duration(1, 'day').as('m');
    
        let listOfTimes = [];
        
        for(let i = 0; i < timePeriod; i+= incrementBy) {
            // if we are at the first object we do not want to increment the value
            updatedTime.add(i === 0 ? 0 : incrementBy, 'm');            
            
    
            listOfTimes.push(TimeHelper.createMomentDate(updatedTime.toDate()));
        }        

         listOfTimes = listOfTimes.filter(times => { 
            const isAfter = TimeHelper.createMomentDate(times.toDate()).isSameOrAfter(todayStart, );
                        
            if(isAfter)
                return times
        });         

        const updatedList = listOfTimes.map(date => ({date: date.toDate().toISOString(), time:date.format("h:mm A")}))
        
        return updatedList
    }

    /**
     * Formats the given date to an acceptable format 
     * that the html date & time input would accept
     * yyyy-MM-ddThh:mm
     */
    static FormatDateForInput = (date:Date, includeTime:boolean) => {
        if(includeTime) {
            return moment(date, TimeHelper.momentFormats).format(moment.HTML5_FMT.DATETIME_LOCAL)
        } else {
            return moment(date, TimeHelper.momentFormats).format(moment.HTML5_FMT.DATE)
        }
    }

    /**
     * Replaces the passed in date offset with a utc offset. The date
     * keeps its time and does not convert it to utc
     */
    static ReplaceOffsetWithUTC = (d:Date) => {
        return new Date(
                Date.UTC(
                            d.getUTCFullYear(),  
                            d.getUTCMonth(), 
                            d.getUTCDate(), 
                            d.getHours(), 
                            d.getMinutes()
                        )
                );
    }
}