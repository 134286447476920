import { useMemo, useState } from "react";
import { DraggableProvidedDragHandleProps, DraggableProvidedDraggableProps } from "react-beautiful-dnd";
import { useSelector, useStore } from "react-redux";
import { ContentCategory } from "../../models/AdditionalVideoContent/ContentCategory";
import editIconSrc from '../../resources/icons/icon-edit.png';
import trashIconSrc from '../../resources/icons/icon-trash.png';
import { ReactComponent as DragGrip } from '../../resources/images/icon-drag-drop.svg';
import { ApplicationState } from "../../store";
import { CurrentUserState } from "../../store/CurrentUser";
import BasicInlineForm from "../AdminDashboard/AdminForms/InlineForms/BasicInlineForm";
import "./DraggableCategory.css";

type propsType = {
    index:number;
    category:ContentCategory;  
    isSelected:boolean;
    isEditing:boolean;  
    draggableProps:DraggableProvidedDraggableProps;
    dragHandleProps:DraggableProvidedDragHandleProps | null | undefined;
    innerRef:(element: HTMLElement | null) => void;
    onCategoryClick:(id:string) => void;
    onEditClick:(id:string) => void;
    onEditSubmit:(categoryName: string) => void;
    onDeleteClick:(category:ContentCategory) => void;
}

export default function DraggableCategory(props:propsType) {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser); 
    
    const {
        index,
        category,
        isSelected,
        isEditing,
        draggableProps,
        dragHandleProps,
        innerRef,
        onCategoryClick, 
        onEditClick,
        onEditSubmit,
        onDeleteClick,
    } = props;

    const [showEditInput, setShowEditInput] = useState(false);

    const isAdmin = useMemo(() => {
        if(!userStore) {
            return false;
        }

        return userStore.isAdmin;
    },[useStore]);

    /**
     * Toggles on or off the edit input inline form
     * @returns 
     */
    const toggleEditInput = () => (setShowEditInput(!showEditInput));

    /**
     * sets the edit item state to the category id and shows the inline edit input
     * @param e 
     */
    const handleEditClick = (e:React.MouseEvent<HTMLButtonElement>) => {
        //Stops the event from bubbling up so the onCategoryClick does not run
        e.stopPropagation();
        onEditClick(category.id);
        toggleEditInput();
    }

    /**
     * Sets the delete item state to the category and toggles the delete alert
     * @param e 
     */
    const handleDeleteClick = (e:React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        onDeleteClick(category);        
    }

    /**
     * Get styles for the current selected category
     * @param id
     * @returns
     */
    const getSelectedStyles = (id:string) => (isSelected ? "active-nav-item" : ""); 

    return (
            <div
                key={category.id}
                onClick={() => onCategoryClick(category.id)}
                className={`chapter-content-container ${getSelectedStyles(category.id)}`}
                ref={innerRef}
                {...draggableProps}
                {...dragHandleProps}
            >
                <div className="chapter-icon-container">
                    <div
                        className="drag-handle"
                        {...dragHandleProps}
                    >
                        <DragGrip />
                    </div>
                    <div className="sidebar-link-text">
                        {
                            showEditInput && isEditing ?
                            (
                                <BasicInlineForm
                                    text={category.name}
                                    inputCSSClass="docs-files-text-input"
                                    placeHolderText={'Enter A Name For This Category'}
                                    onSubmit={onEditSubmit}
                                    onBlur={toggleEditInput}
                                />
                            ) 
                            : 
                            (
                                <>
                                    {category.name}
                                </>
                            )
                        }
                    </div>
                </div>
                {
                    isAdmin && (
                        <div className="therapist-panel-btn-container">
                            <button
                                className="btn-cbit-icon"
                                onClick={(e) => handleEditClick(e)}
                            >
                                <img src={editIconSrc} alt="Edit Icon" />
                            </button>
                            <button
                                className="btn-cbit-icon"
                                onClick={(e) => handleDeleteClick(e)}
                            >
                                <img src={trashIconSrc} alt="Trash Icon" />
                            </button>
                        </div>
                    )
                }
            </div>               
    )
}