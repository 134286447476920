import * as React from 'react';
import {IMultipleChoiceContent, MultipleChoiceContent} from '../../../../models/contentTypes/MultipleChoiceContent';
import {ILessonContent, LessonContent} from "../../../../models/lesson/LessonContent";
import {LessonContentType} from "../../../../models/contentTypes/LessonContentType";
import {QuestionType} from "../../../../models/contentTypes/QuestionType";
import {
    Form,
    FormGroup,        
    Input,
    Label,
    Row,
    Col,
    FormFeedback,        
} from 'reactstrap';
import { LessonContentController } from '../../../../controllers/LessonContentController';
import {ReactComponent as IconAddAnother} from '../../../../resources/images/icon-add-another-choice.svg';
import {ReactComponent as IconTrash} from '../../../../resources/images/icon-feather-trash-2.svg';
import {ReactComponent as IconCircle} from '../../../../resources/images/icon-circle.svg';
import {ReactComponent as IconCheckCircle} from '../../../../resources/images/icon-check-circle-fill.svg';
import './QuestionForm.css';
import { AdminModule, IAdminModule } from '../../../../models/module/AdminModule';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import { LessonController } from '../../../../controllers/LessonController';

// State type for the multi choice form
type MultipleChoiceState = {
    question: IMultipleChoiceContent
    isLoading: boolean
    isCorrectAnswer: boolean
}


// Prop type for the basic Question Form
export type QuestionProps = {
    content: ILessonContent
    questionType: QuestionType
    questionNumber:number
    order:number
    isEditing: boolean
    branchID: string
    courseId:string
    addChoice: any
    removeChoice: any
    defaultQuestion: MultipleChoiceContent
    isTrueOrFalse:boolean
    onSubmit(content:ILessonContent | IAdminModule) : void
    toggleModal(isOpen?:boolean):void     
}


// Form for multiple choice questions
class QuestionForm extends React.Component<QuestionProps, MultipleChoiceState> {
    private lessonContentController = new LessonContentController();
    private lessonController = new LessonController();
    
    /**
     * Passing props to super and setting the default state
     */
    constructor(props:any) {
        super(props);        
        this.state = this.unloadState;
    }

    componentDidUpdate(prevProps:QuestionProps, prevState:MultipleChoiceState) {
        if(prevProps.defaultQuestion !== this.props.defaultQuestion) {
            const question = this.props.defaultQuestion;
            this.setState(prevState => ({
                ...prevState,
                question: question
            }));
        }

    }

    private unloadState : MultipleChoiceState = {
        question: this.props.defaultQuestion,
        isLoading: false,
        isCorrectAnswer: false
    }

    /**
     * 
     * @param e 
     */
    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
            question: {
                ...prevState.question,
                [inputName]: inputValue
            }
        }));
    }

    private onChoiceChange = (index:number, e: React.ChangeEvent<HTMLInputElement>) => {
            const inputValue = e.currentTarget.value;
            const choices = this.state.question.choices;
            if(!Array.isArray(choices)){               
                return;
            }
            
            choices[index].text = inputValue;
            
            this.setState(prevState => ({
                ...prevState,
                question: {
                    ...prevState.question,
                    choices: [...choices]
                }
            }));
    }

    /**
     * 
     * @returns 
     */
    private checkMultipleChoice  = () : boolean => {
        const questionType = this.props.questionType;

        if(questionType == QuestionType.multipleChoice)
            return true;
        else {
            return false;
        }
    }

    private selectIsCorrect = (index:number) => {
        const choices = this.state.question.choices;
        if(!Array.isArray(choices))
            return;
        
        const isCorrect = choices[index].isCorrect;

        choices[index].isCorrect = !isCorrect;

        this.setState(prevState => ({
            ...prevState,
            isCorrectAnswer: !this.selectIsCorrect,
            question: {
                ...prevState.question,
                choices: [...choices]
            }
        }));
    }

    /**
     * Checks whether the question is going to be true/false or not
     */
    private getIsTrueOrFalse = () => {
        if(this.props.addChoice) { 
            return false;
        } else {
            //If add choice is null then we know its a true or false question
            return true
        }
    }

    /**
     * Creates a new Question Lesson content or updates an existing one and submits to the db
     */
    private updateQuestionContent = async () => {
        const {branchID, onSubmit, toggleModal, isEditing, content, isTrueOrFalse, order, questionNumber} = this.props;
        const { question} = this.state;

        let questionContent = null;

        const questionCopy:IMultipleChoiceContent = DeepCopy.copy(question);

        questionCopy.choices = MultipleChoiceContent.TrimChoicesWhiteSpace(questionCopy.choices);

        questionCopy.questionText = questionCopy.questionText.trim();        

        if(isEditing){

            questionContent = {...content};

            questionContent.name = questionCopy.questionText;       
            
            questionContent.courseId = this.props.courseId;
    
            questionContent.multipleChoiceProperties = questionCopy;
    
            // questionContent = await this.lessonContentController.UpdateLessonContent(questionContent);

            onSubmit(questionContent);
    
        } else { //Creating a new quiz lesson content because we are not editing and existing one
            
            questionContent = new LessonContent();
            
            // Assigning question content properties
            questionContent.name = question.questionText;
            
            questionContent.type = isTrueOrFalse? LessonContentType.trueOrFalse : LessonContentType.multipleChoice;
            
            questionContent.multipleChoiceProperties = questionCopy;

            questionContent.order = order;

            questionContent.courseId = this.props.courseId

            questionCopy.questionNumber = `${questionNumber}`; //Converting to string
            
            questionContent.lessonId = branchID;            

            questionContent = await this.lessonContentController.CreateLessonContent(questionContent);
            
            const adminModule = new AdminModule(questionContent);
            
            adminModule.totalQuestions = questionNumber

            await this.updateQuizTotalQuestions(questionNumber);

            onSubmit(adminModule);
        }                
    }

    /**
     * Updates the total amount of questions in a quiz
     * TODO update selected module duration
     */
    private updateQuizTotalQuestions = async (totalQuestions:number) => {
        const {branchID} = this.props;        

        const quizLesson = await this.lessonController.GetLesson(branchID);
        
        quizLesson.duration = totalQuestions.toString();
        quizLesson.totalQuestions = totalQuestions;
        
        await this.lessonController.UpdateLesson(quizLesson);        
    }

    /**
     * handles the submission of the form
     * @param e 
     */
    private handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault(); //prevents page from refreshing
        
        const {toggleModal} = this.props;

        this.updateQuestionContent();

        toggleModal(false);                            
    }

    /**
     * Text field styling is different depending on the question type (multi/true or false)
     */
    private getTextFieldClassName = () => {
        if(this.props.isTrueOrFalse) {
            return "true-false-text-field"
        } else {
            return "multi-choice-text-field"            
        }
    }

    public render() {
        const {isEditing, toggleModal} = this.props;
        return (
            <>
            <Form className="form-question" onSubmit={this.handleSubmit}>  
                {
                    isEditing ? 
                    (
                        <>
                            {
                                this.getIsTrueOrFalse() ? 
                                (
                                    <h2 className="form-title">CREATE TRUE OR FALSE QUESTION</h2>   
                                )
                                :
                                (                                        
                                    <h2 className="form-title">CREATE MULTIPLE CHOICE QUESTION</h2>                                                                                           
                                )
                            }
                        </>
                    ) 
                    : 
                    (                                        
                        <>
                        {
                            this.getIsTrueOrFalse() ? 
                            (
                                <h2 className="form-title">EDIT TRUE OR FALSE QUESTION</h2>   
                            )
                            :
                            (                                        
                                <h2 className="form-title">EDIT MULTIPLE CHOICE QUESTION</h2>                                                                                           
                            )
                        }
                    </>                                                         
                    )
                }             
                <Row>
                    <Col>
                        <FormGroup>
                            <Label className='form-label' for="questionText">Question Text</Label>
                            <Input
                                className={`form-input form-textarea ${this.getTextFieldClassName()}`} name="questionText" type="textarea" id="questionText"
                                value={this.state.question.questionText} onChange={this.onChange} required />
                                <FormFeedback>
                                    {/* TODO ERROR MESSAGE */}
                                </FormFeedback>
                        </FormGroup>
                    </Col>
                </Row>

                {this.state.question.choices.map((choice, index) => (
                    <Row key={index}>
                        <Col>
                            <FormGroup>
                                <Label className='form-label' for={`choice${index}`}>Answer Choice {index + 1}</Label>                                
                                    <div className='answer-choice-container'>                                    
                                        {
                                            choice.isCorrect ? 
                                            (
                                                <div className='icon-answer-circle' onClick={() => this.selectIsCorrect(index)}>
                                                    <IconCheckCircle/>
                                                </div>
                                            ) 
                                            : 
                                            (
                                                <div className='icon-answer-circle' onClick={ () => this.selectIsCorrect(index)}>
                                                    <IconCircle />
                                                </div>
                                            )
                                        }
                                        <Input
                                            className="form-input question-input"
                                            name={`choice${index}`} type="textarea" id={`choice${index}`}
                                            rows="1"
                                            value={choice.text} onChange={(e) => this.onChoiceChange(index,e)}  
                                        />

                                        {
                                            this.checkMultipleChoice()  &&                                
                                            <div className='icon-quiz-trash' onClick={() => this.props.removeChoice(index, this.state.question.questionText)}>
                                                <IconTrash/>
                                            </div>              
                                        }                                
                                    </div>
                                <FormFeedback>
                                        {/* TODO ERROR MESSAGE */}
                                </FormFeedback>
                            </FormGroup>
                        </Col>
                    </Row>
                ))}
                <Row>
                    <Col>
                {
                    this.checkMultipleChoice() &&
                    <button type='button' className="btn-add-Another-choice" onClick={ () => this.props.addChoice(this.state.question)}>
                        <span>
                            <IconAddAnother/>
                        </span>
                        Add another choice...
                    </button>
                }
                </Col>
                </Row>
                <div className="video-btn-container">
                    <button type='button' onClick={() => toggleModal(false)} className='btn-cbit-minor'>Cancel</button>
                    <button type='submit' className='btn-cbit-primary'>
                    {
                        isEditing ? "Update" : "Submit"
                    } 
                    </button>
                </div>
            </Form>
            </>
        )
    }
}

export default QuestionForm;