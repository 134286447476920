import { ILesson, IsInstanceOfLesson, LessonIconEnum } from "../lesson/Lesson";
import { IAdminModule } from "../module/AdminModule";
import { IModule, Module } from "../module/Module";
import { ModuleType } from "../module/ModuleType";
import { DeepCopy } from "../utility/DeepCopy";
import { EditorContentEnum } from "../utility/EditorContentEnum";
import { PartialModuleTreeModel, TreeViewType, convertToPartialModuleEnum } from "./PartialModuleTreeModel";

export interface IAdminTreeModule {
    id: string;
    parentId: string;
    description: string;
    type: TreeViewType;
    order: number;
    name: string;
    isViewable: boolean;
    duration: string;    
    lessonIcon:LessonIconEnum;
    totalQuestions:number;
    children: IAdminTreeModule[];
}

/**
 * A tree view item for the admin accordion inside the content builder
 */
export class AdminTreeModule implements IAdminTreeModule {

    constructor(adminTreeModule?:IAdminTreeModule) {  
        if(!adminTreeModule)
            return;

        this.id = adminTreeModule.id;
        this.parentId = adminTreeModule.parentId;
        this.description = adminTreeModule.description;
        this.type = adminTreeModule.type;
        this.order = adminTreeModule.order;
        this.name = adminTreeModule.name;
        this.isViewable = adminTreeModule.isViewable;
        this.duration = adminTreeModule.duration;
        this.lessonIcon = adminTreeModule.lessonIcon;
        this.totalQuestions = adminTreeModule.totalQuestions;
        this.children = adminTreeModule.children;
    }

    id: string = "";
    parentId: string = "";
    duration: string = "";    
    type: TreeViewType = 0;
    order: number = 0;
    name: string = "";
    description: string = "";
    isViewable: boolean = false; 
    lessonIcon:LessonIconEnum = LessonIconEnum.Unknown;
    totalQuestions:number = 0;
    children: IAdminTreeModule[] = [];

    /**
     * 
     */
    static convertPartialTreeToAdminTreeModule(partialModuleTreeModel:PartialModuleTreeModel, duration?:string) {
        duration = duration == null ? "" : duration;
        const adminTreeModule:IAdminTreeModule = new AdminTreeModule(
            {
                ...partialModuleTreeModel,
                duration,
                parentId: "",
                lessonIcon: partialModuleTreeModel.lessonIcon,
                totalQuestions:partialModuleTreeModel.totalQuestions,
                children: partialModuleTreeModel.children.map(partial => this.convertPartialTreeToAdminTreeModule(partial))
            }
        )

        return adminTreeModule
    }

    static convertTreeViewTypeToEditorContentType = (adminTreeModule:IAdminTreeModule, parentModuleId:string) => {
        switch (adminTreeModule.type) {
            case TreeViewType.Branch:{
                if(adminTreeModule.parentId === parentModuleId)
                    return EditorContentEnum.module

                if(adminTreeModule.parentId = "")
                    return EditorContentEnum.parentModule;
                
                return EditorContentEnum.lessonGroup
            }            
        
            case TreeViewType.QuizLesson:{
                return EditorContentEnum.lessonQuiz;
            }

            case TreeViewType.CustomLesson: {
                return EditorContentEnum.lesson
            }
            default:
                return EditorContentEnum.parentModule;
        }
    }

    static convertModuleToAdminTreeModule(module:IModule) {
        const parentId = module.parentModule ? module.parentModule : "";
        const isViewable = module.isViewable ? module.isViewable : false;
        return new AdminTreeModule({
            ...module,
            lessonIcon: LessonIconEnum.Unknown, //Modules don't have lesson icons
            totalQuestions: 0, //A module would not have total questions only lessons
            parentId,
            isViewable:isViewable,
            type: TreeViewType.Branch,
            duration: "",            
            children:[]
        });
    }

    static convertLessonToAdminTreeModule(lesson:ILesson, totalQuestions?:number) {        
        return new AdminTreeModule({
            ...lesson,
            type: convertToPartialModuleEnum(true, lesson.type),
            lessonIcon: lesson.lessonIcon,
            totalQuestions: lesson.totalQuestions,
            duration: lesson.duration,
            parentId: lesson.branchId,            
            children: []
        })
    }

    static convertAdminModuleToAdminTreeModule(adminModule:IAdminModule) {
        const {content} = adminModule;

        if(IsInstanceOfLesson(content))
            return this.convertLessonToAdminTreeModule(content as ILesson);

        return this.convertModuleToAdminTreeModule(content as IModule);
    }

    /**
     * Converts an adminTreeModule to a normal module
     * @param adminTreeModule 
     */
    static convertAdminTreeModuleToModule(adminTreeModule:IAdminTreeModule) {
        const module = new Module({...adminTreeModule } as unknown as IModule);

        module.parentModule = adminTreeModule.parentId;
        
        module.type = ModuleType.branch;
        
        module.isViewable = adminTreeModule.isViewable;

        return module;
    }


    static getAdminTreeItemContentType = (adminTreeModule:IAdminTreeModule, parentModule:IAdminTreeModule) => {
        if(adminTreeModule.id.length <= 0 )
            return EditorContentEnum.parentModule;        

        const selectedModule:IAdminTreeModule = DeepCopy.copy(adminTreeModule);    

        const isModule = parentModule.children.some(module => module.id === selectedModule.id);

        if(isModule)
            return EditorContentEnum.module;

        if(selectedModule.type >= 2)
            return selectedModule.type === TreeViewType.QuizLesson ? EditorContentEnum.lessonQuiz : EditorContentEnum.lesson;
        
        return EditorContentEnum.lessonGroup;
    
    }
}

/**
 * Convert an adminTreeModule to a partialModuleTreeModel
 */
export function convertAdminTreeModuleToPartial(adminTreeModule:IAdminTreeModule) {

    const partialTreeModule = new PartialModuleTreeModel();

    partialTreeModule.id = adminTreeModule.id;
    partialTreeModule.originalDocumentId = adminTreeModule.parentId;
    partialTreeModule.type = adminTreeModule.type;
    partialTreeModule.order = adminTreeModule.order;
    partialTreeModule.name = adminTreeModule.name;
    partialTreeModule.description = adminTreeModule.description;
    partialTreeModule.isViewable = adminTreeModule.isViewable;
    partialTreeModule.isDisabled = false;
    partialTreeModule.isEditable = false;
    partialTreeModule.isOptional = false;
    partialTreeModule.isComplete = false;
    partialTreeModule.isNext = false;
    partialTreeModule.isOpen = false;
    partialTreeModule.children = adminTreeModule.children.map(mod => convertAdminTreeModuleToPartial(mod));
    partialTreeModule.lessonIcon = adminTreeModule.lessonIcon;
    return partialTreeModule;
}


