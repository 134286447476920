import { Route, Switch } from 'react-router-dom';
import ForgotPassword from '../components/PublicPages/ForgotPassword';
import ForgotUsername from '../components/PublicPages/ForgotUsername';
import Login from '../components/PublicPages/Login';
import PasswordRecovery from "../components/PublicPages/PasswordRecovery";
import SignUp from '../components/PublicPages/SignUp';
import GuestGuard from '../components/Utilities/RouteGuard/GuestGuard';
import DemoPage from '../components/PublicPages/DemoPage/DemoPage';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../store';
import { CurrentUserState } from '../store/CurrentUser';

export const validPages = [
    '/',
    '/login',
    '/sign-up',
    '/forgot-password',
    '/forgot-username',
    `/password-recovery/${window.location.pathname.split('/')[2]}/${window.location.pathname.split('/')[3]}`,    
];

const PublicRoutes = () => {

    return (
        <GuestGuard>
            <Switch>
                <Route exact path='/' component={DemoPage} />
                <Route path='/login' component={Login}/>
                <Route path='/sign-up' component={SignUp}/>
                <Route path='/forgot-password' component={ForgotPassword}/>
                <Route path='/forgot-username' component={ForgotUsername}/>
                <Route path='/password-recovery/:id/:passwordToken' component={PasswordRecovery}/>                
            </Switch>
        </GuestGuard>
    )
}

export default PublicRoutes;