import "./DemoPage.css";

const DemoPage = () => {

    return (                        
        <div className="hero-container">
            <div className="cbit-hero cbit-hero-1">                                                                 
                    <div className="cbit-hero-inner-right">
                        <div className="cbit-hero-inner-text">          
                            AN ONLINE LMS <div className="text-bold">For building your own courses</div>
                        </div>
                        <div className="cbit-inner-text">
                            Quickly build you online course using our easy to use content builder.
                        </div>
                        <div className="btn-hero-container">
                            <a href='/login' className="btn-cbit-hero btn-cbit-primary">
                                START DEMO
                            </a>
                        </div>                            
                </div> 
            </div>                                                             
        </div>     
    )
}

export default DemoPage;