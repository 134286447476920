import { AdditionalContentController } from "../../controllers/AdditionalContentController";
import { ContentCategoryController } from "../../controllers/ContentCategoryController";
import { ImageManagementController } from "../../controllers/ImageManagementController";
import { LessonContentController } from "../../controllers/LessonContentController";
import { LessonController } from "../../controllers/LessonController";
import { ModuleController } from "../../controllers/ModuleController";
import { _idObject } from "../AdditionalVideoContent/AdditionalVideoContent";
import { LessonContentType } from "../contentTypes/LessonContentType";
import { MultipleChoiceContent } from "../contentTypes/MultipleChoiceContent";
import { ILesson } from "../lesson/Lesson";
import { ILessonContent } from "../lesson/LessonContent";
import { LessonTypeEnum } from "../lesson/LessonType";
import { IAdminModule } from "../module/AdminModule";
import { IModule } from "../module/Module";
import { EditorContentEnum } from "./EditorContentEnum";
import { GetCourseItemType } from "./GetCourseItemType";
import { DirectUploadResults } from "./ImageHandler";
import { StringHelper } from "./StringHelper";
import { ModuleAction } from "./UnSavedCourse/ModuleAction";

/**
 * Note: alreadyCreatedItems apply to all content except chapters.
 * Because when creating content, it is created in the database and we 
 * need a place to store items that are created but may be discarded
 */
export type IUnsavedContent = {
    deletedItems: any[];
    addedItems: any[];
    editedItems:any[];
    alreadyCreatedItems:any[];
    hasChanges:boolean;
    hasOrderChanged:boolean;
}

// The default values for the unSavedContent obj
export const emptyUnSavedContent : IUnsavedContent = {
    deletedItems: [],
    addedItems: [],
    editedItems: [],
    alreadyCreatedItems:[],
    hasChanges: false,
    hasOrderChanged:false
}

const moduleController = new ModuleController();
const lessonController = new LessonController();
const lessonContentController = new LessonContentController();
const ImageManager = new ImageManagementController();
const additionalContentController = new AdditionalContentController();
const contentCategoryController = new ContentCategoryController();

/**
 * Performs api actions for unsaved content based on its content/module Type
 * TODO turn into a class
 * @param courseItemType 
 * @param unSavedContent 
 * @param saveAction 
 */
export const PerformSaveAction = async (adminModuleItem:IAdminModule, saveAction:ModuleAction, courseId:string  ,adminModuleArr?:IAdminModule[]) => {            
    const moduleItem = {...adminModuleItem.content};

    const moduleItemType = GetCourseItemType(moduleItem);    

    const imageFile = adminModuleItem.file;     
    
    const tagList = adminModuleItem.tagList;

    moduleItem.courseId = courseId;

    switch (moduleItemType) {
        case EditorContentEnum.lesson:
            let lessonItem = moduleItem as ILesson;

            await PerformLessonActions(lessonItem, saveAction, courseId, adminModuleItem);
            
            break;

        case EditorContentEnum.lessonContent:

            const lessonContent = moduleItem as ILessonContent;
            lessonContent.courseId = courseId;

            HandleLessonContent(lessonContent, saveAction, adminModuleItem, courseId, adminModuleArr, tagList);
            
            break;
        // For Modules
        default:
            await handleOnModuleSave(saveAction, courseId, moduleItem as IModule);
            break;
    }
}

/**
 * Handles lesson actions that can be perform when a saved has occurred
 * @param lessonItem 
 * @param saveAction 
 */
const PerformLessonActions = async (lessonItem:ILesson, saveAction:ModuleAction, courseId:string, adminModuleItem:IAdminModule) => {
    let lessonContentList = lessonItem.lessonContent;

    lessonItem.lessonContent = null;

    switch(saveAction) {
        case ModuleAction.ADD: {   
            const {file, uploadUrl} = adminModuleItem;
            if(!file || StringHelper.IsNullOrWhiteSpace(uploadUrl as string)) {
                break;
            }         

            const uploadedRes = await ImageManager.UploadImage(uploadUrl as string, file);
            break;
        }
        case ModuleAction.UPDATE: {                        
            await lessonController.UpdateLessonAndContentCategory(lessonItem);
            break;
        }
        case ModuleAction.DELETE: {            
            await lessonController.DeleteLesson(lessonItem.id);
            await lessonController.DeleteLessonAndTagInContentCategory(lessonItem);
            break;
        }        
        default: 
            break;
    } 

    if(lessonContentList) {
        const lessonContent = lessonContentList[0];
        lessonContent.lessonId =lessonItem.id;
        HandleLessonContent(lessonContent, saveAction, adminModuleItem, courseId);
    }
}

const HandleLessonContent = async (lessonContentItem:ILessonContent, saveAction:ModuleAction, adminModuleItem:IAdminModule, courseId:string,  adminModuleArr?:IAdminModule[], tagList?:_idObject[]) => {     
    const lessonId = lessonContentItem.lessonId;
    lessonContentItem.courseId = courseId;

    const lessonContentType = lessonContentItem.type;
    switch (saveAction) {
        case ModuleAction.ADD: {
            
            break;
        }            
        case ModuleAction.UPDATE:{
            DeleteImage(lessonContentItem);
            await lessonContentController.UpdateLessonContent(lessonContentItem);          
            
            const comboLesson = await lessonController.GetLesson(lessonContentItem.lessonId);
            
            if(tagList && tagList.length > 0) {
                comboLesson.tagList = tagList;                    
            }                                                                             

            await lessonController.UpdateLessonAndContentCategory(comboLesson);
            
            break;
        }

        case ModuleAction.DELETE: {                        
                         
            await lessonContentController.DeleteLessonContent(lessonContentItem.id);
    
            if(lessonContentType === LessonContentType.multipleChoice || lessonContentType === LessonContentType.trueOrFalse)            
                await HandleQuizQuestionDelete(lessonId, adminModuleArr);
            

            if(lessonContentItem.order > 0) {
                return;
            }
            
            //If we are deleting the first content item we need to remove the tag from private if there is one
            const comboLesson = await lessonController.GetLesson(lessonContentItem.lessonId);
            
            //If we are a combo lesson and we have a taglist remove the tag from private and update the combo lesson
            if(comboLesson.type === LessonTypeEnum.custom && comboLesson.tagList.length > 0) {                
                await contentCategoryController.RemoveTagFromPrivateCategory(comboLesson.tagList[0]._id);
                
                comboLesson.tagList = [];
                
                await lessonController.UpdateLesson(comboLesson);
            }             
            
            break;
        }

        default:
            break;
    }
}

const DeleteImage = async (textContent:ILessonContent) => {    

    if(!textContent.textProperties) {
        return;
    }

    // Getting the textContent that is stored in the database
    const prevTextContent = await lessonContentController.GetLessonContent(textContent.id);

    const prevImageId = prevTextContent.textProperties?.imageId;

    const currentImageId = textContent.textProperties?.imageId;

    // If the ids are different then that means the user replaced or removed the image
    if(prevImageId !== currentImageId) {        
        
        if(prevImageId)
            await ImageManager.DeleteImage(prevImageId);
        
    }
}

/**
 * Updates a quiz question parent (the quiz lesson) question total
 * Note: this only works because we have already created the quiz question by time this runs 
 * @param lessonId 
 * @param adminModuleArr 
 * @returns 
 */
const HandleQuizQuestionAdd = async (lessonId:string, adminModuleArr?:IAdminModule[]) => {
    if(!adminModuleArr)
        return;
    
    const lesson = await lessonController.GetLesson(lessonId);
    
    //We don't want to update combo lessons duration
    if(lesson.type === LessonTypeEnum.custom)
      return;

    const lessonContentItems = await lessonContentController.GetAllLessonContentByLessonId(lesson.id);

    let questionTotal = MultipleChoiceContent.GetTotalQuestion(lessonContentItems);

    if(!Number.isInteger(questionTotal))
        questionTotal = 0;

    lesson.duration = questionTotal.toString();

    await lessonController.UpdateLesson(lesson);
}

/**
 * Updates a quiz total amount of questions when deleting a quiz question
 */
const HandleQuizQuestionDelete = async (lessonId:string, adminModuleArr?:IAdminModule[]) => {
    if(!adminModuleArr)
        return;    

    const contentList = adminModuleArr.map(adminModule => ({...adminModule.content}));

    const lesson = await lessonController.GetLesson(lessonId);

    //We don't want to update combo lessons duration
    // if(lesson.type === LessonTypeEnum.custom)
    //     return;

    let questionTotal = parseInt(lesson.duration);

    //Filtering out non questions and gets the total
    let questionDeleteAmount = MultipleChoiceContent.GetTotalQuestion(contentList as ILessonContent[]);

    if(!Number.isInteger(questionTotal))
        questionTotal = 0;

    //Because we are deleting a quiz question we need to subtract one from the total amount of questions
    if(questionTotal > 0)
        questionTotal -= questionDeleteAmount

    lesson.duration = questionTotal.toString();
    lesson.totalQuestions = questionTotal;

    await lessonController.UpdateLesson(lesson);
}

/**
 * Handles when we are saving a module item
 */
const handleOnModuleSave = async (saveAction:ModuleAction, topMostModuleId:string,moduleItem:IModule) => {            
    switch(saveAction) {
        case ModuleAction.ADD: {
            await updateParentModuleLastModified(topMostModuleId, moduleItem.parentModule as string);                        
            break;
        }
        case ModuleAction.UPDATE: {                 
            await moduleController.UpdateModule(moduleItem as IModule);
            await updateParentModuleLastModified(topMostModuleId, moduleItem.parentModule as string);                        
            break;
        }
        case ModuleAction.DELETE: {
            await moduleController.DeleteModule(moduleItem.id);
            await updateParentModuleLastModified(topMostModuleId, moduleItem.parentModule as string);                        
            break;
        }
    } 
}

/**
 * Updates the last modified field for a content item parent 
 * along with the top most module
 * @param topMostModuleId 
 * @param parentModule 
 */
const updateParentModuleLastModified = async (topMostModuleId:string, parentModule:string) => {
    
    if(StringHelper.IsNullOrWhiteSpace(topMostModuleId) && StringHelper.IsNullOrWhiteSpace(parentModule)){
        return;
    }

    //TODO check to see if this is still needed
    if(topMostModuleId !== parentModule) {
        await moduleController.UpdateLastModified(topMostModuleId);
    }    

    await moduleController.UpdateLastModified(parentModule);
}

/**
 * Perform Create, update, and delete actions for a course (collection of mods, less, and less content) that need to be saved
 */
export class SaveContent {    
    static performSave =  async (adminModuleArr:IAdminModule[], hasChanges:boolean, action:ModuleAction, topMostModuleId:string) => {        
        if(!hasChanges) {
            return;
        }

        if(action === ModuleAction.ORDERCHANGE) {            
            for(let i = 0; i < adminModuleArr.length; i++) {
                adminModuleArr[i].content.order = i;        
            }
        
            action = ModuleAction.UPDATE;
        }
                
        let saveQueue = [];

        for(let adminMod of adminModuleArr) {
            
            saveQueue.push(() => PerformSaveAction(adminMod, action, topMostModuleId, adminModuleArr));
        }

        //we need to update the top most parent module update timestamp any time we save
        saveQueue.push(() => moduleController.UpdateLastModified(topMostModuleId));

        await Promise.all(saveQueue.map(s => s()));

    }   
}


