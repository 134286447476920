import * as DOMPurify from 'dompurify';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useHistory, useParams } from 'react-router';
import { Spinner } from 'reactstrap';
import * as Yup from 'yup';
import { PublishedModuleController } from '../../../../controllers/PublishedModuleController';
import { UserController } from '../../../../controllers/UserController';
import { UserModuleController } from '../../../../controllers/UserModuleController';
import { IModule } from '../../../../models/module/Module';
import { IUserBio, IUserProfile } from '../../../../models/User/UserProfile';
import { DeepCopy } from '../../../../models/utility/DeepCopy';
import FormHelper, { MaxCharactersForDescription, displayMaxCharDescError } from '../../../../models/utility/FormHelper';
import Roles from '../../../../models/utility/Roles';
import { StringHelper } from '../../../../models/utility/StringHelper';
import UserStatus from '../../../../models/utility/UserStatus';
import IconEyeClosed from '../../../../resources/icons/icon-eye-closed.png';
import IconEyeOpen from '../../../../resources/icons/icon-eye-open.png';
import CharacterLimit from '../../../Utilities/CharacterLimit';
import PasswordVisibilityToggle from '../../../Utilities/PasswordVisibilityToggle/PasswordVisibilityToggle';
import './AdminUserForm.css';

type AdminFormValues = {
    username:string;
    prefix:string;
    firstName:string;
    lastName:string;
    email:string;    
    description:string;
    roles:string[];
    course:string
    password:string;
    passwordConfirm:string;
}

type AdminUserFormProps = {
    isEditing:boolean;
}

type AdminUserFormState = {
    isLoading:boolean;
    isPwdVisible:boolean;
    isPwdConfirmVisible:boolean;
    user:IUserProfile | null;
    initialValues:AdminFormValues;
}

const userController = new UserController();

const userModuleController = new UserModuleController();

const modController = new PublishedModuleController();

const adminRoles = [
    Roles.Admin,
    Roles.SuperAdmin
]

/**
 * An admin tool for signing up users, setting their roles, and assigning lesson modules
 * @returns 
 */
const AdminUserForm = (props:AdminUserFormProps) => {
    const {isEditing} = props;

    const defaultState:AdminUserFormState = {
        isLoading: false,
        isPwdVisible: false,
        isPwdConfirmVisible: false,       
        user:null,
        initialValues: {
            username: '',
            prefix: '',
            firstName: '',
            lastName: '',
            email: '',                
            description: '',
            roles: [],    
            password: '',
            passwordConfirm: '',
            course:''
        }
    }

    const [state, setState] = useState(defaultState);

    const [courses, setCourses] = useState<IModule[]>([]);

    const history = useHistory();

    let {username} = useParams<{username?: string}>();

    useEffect(() => {
        async function onComponentMount(){
            if(isEditing) {
                await getUserByUsername();
                return;
            } 

            await getCourses();
        }
        onComponentMount();
    },[]);


    /**
     * Get courses and update our course select state
     */
    const getCourses = async () => {
        try {
            const courses:IModule[] = await modController.GetCourses();

            setCourses(courses);

            const stateCopy:AdminUserFormState = DeepCopy.copy(defaultState);

            //We want to default to the oldest course
            const oldestCourse = courses.sort((a,b) => (Date.parse(a.createdAt as string) - Date.parse(b.createdAt as string)))[0];

            if(oldestCourse) {
                stateCopy.initialValues.course = oldestCourse.id;
                setState((prevState) => ({
                    ...prevState,
                    initialValues: {
                        ...prevState.initialValues,
                        course: oldestCourse.id,
                    }
                }));
            }

            
        } catch (error) {
            
        }
    }

    /**
     * When editing we get the user info based on their user name
     */
    const getUserByUsername = async () => {
        setState(prevState => ({
            ...prevState,
            isLoading:true
        }));

        try {
            if(!username) {
                username = "";
            }

            const user = await userController.GetUserByUsername(username);

            const courses:IModule[] = await modController.GetCourses();
            const oldestCourse = courses.sort((a,b) => (Date.parse(a.createdAt as string) - Date.parse(b.createdAt as string)))[0];
            setCourses(courses);

            if(!user) {
                toast.error("Could not find user");
                history.push("/users");
                return;
            }

            const courseId = !StringHelper.IsNullOrWhiteSpace(user.assignedSectionId) ? user.assignedSectionId : oldestCourse.id;

            const initialValues = {
                username: user.name,
                prefix: user.prefix,
                firstName: user.firstName,
                lastName: user.lastName,
                email: user.email,                
                description: user.description, 
                roles: user.roles.map(role => role.toString()),    
                course: courseId,
                password: '',
                passwordConfirm: '',
            }

            setState(prevState => ({
                ...prevState,
                isLoading:false,
                user,
                initialValues
            }));
            

        } catch (error) {
            console.error(error);
            toast.error("Failed to get user");
        }
    }

    const togglePwdVisibility = () => (setState(prevState => ({...prevState, isPwdVisible:!state.isPwdVisible})));
    
    const togglePwdConfirmVisibility = () => (setState(prevState => ({...prevState, isPwdConfirmVisible:!state.isPwdConfirmVisible})));

    /**
     * Includes roles that we want to be able to assign a person
     * Note if creating a test user the admin needs to select the professional role also
     */
    const availableRoles = [
        Roles.TestUser,
        Roles.User,                
        Roles.Admin
    ]

    /**
     * Ui friendly labels for display the role
     */
    const roleLabels = {
        [Roles.None]: "None",
        [Roles.TestUser]: "Test User",
        [Roles.User]: "User",                
        [Roles.Admin]: "Admin",
        [Roles.SuperAdmin]: "Super Admin",
    }

    const maxErrorMsg = (fieldName:string, maxLen:number) => (`${fieldName} must be less than ${maxLen} characters`);
    
    const minErrorMsg = (fieldName:string, minLen:number) => (`${fieldName} must be ${minLen} or more characters`);
    
    const requiredErrorMsg = (fieldName:string) => (`Please provide a ${fieldName}`);

    const validationSchema = Yup.object({
        username: Yup.string()
        .max(20, maxErrorMsg('Username', 20))
        .min(3, minErrorMsg('Username', 3))
        .required(requiredErrorMsg('Username')),
        firstName: Yup.string()
        .max(32, maxErrorMsg('First name', 32))
        .min(2, minErrorMsg('First name', 2))
        .required(requiredErrorMsg('First name')),
        lastName: Yup.string()
        .max(32, maxErrorMsg('Last name', 32))
        .min(2, minErrorMsg('Last name', 2))
        .required(requiredErrorMsg('Last name')),
        email: Yup.string()
        .email('Please provide a valid email address')
        .required(requiredErrorMsg('valid email address')),       
        roles: Yup.array().min(1, "Please select a valid role").required("Please Select a valid role"),
        description: Yup.string().max(MaxCharactersForDescription, `Bio must be ${StringHelper.formatNumberWithCommas(MaxCharactersForDescription)} characters or less`),
        course: Yup.string().required(requiredErrorMsg('Course')),
        password: isEditing ?(
                Yup.string()        
                .min(6, minErrorMsg('Password', 6))
            ) : (
                    Yup.string()        
                    .min(6, minErrorMsg('Password', 6))
                    .required(requiredErrorMsg('Password'))
                ),
        passwordConfirm: isEditing ? (
            Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match') 
        ) :( 
            Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match') 
            .required("Please confirm your password")  
        )     
    });

    /**
     * Display an array of error messages in toast
     */
    const displayErrorsInToast = (errors:string[]) => {        
        for(const err of errors) {
            toast.error(err);
        }
    }

    /**
     * Updates an already existing user
     * @param roles 
     * @param values 
     * @returns 
     */
    const updateUserProfile = async (roles:Roles[], values:AdminFormValues) => {
        if(!isEditing || !state.user){
            toast.error("Trying to edit when there is no user");
            return;
        }
        
        const {username, firstName, lastName, email, prefix, description, course} = values;

        const user:IUserProfile = {
            id: state.user.id,
            name: username,
            firstName,
            lastName,
            email,
            prefix,
            description,
            password: state.user.password,
            passwordLastChanged: state.user.passwordLastChanged,
            passwordAttempts: state.user.passwordAttempts,
            roles,
            status: state.user.status,
            imageLink: state.user.imageLink,
            createdAt: moment(new Date()).toISOString(),
            updatedAt: moment(new Date()).toISOString(),
            assignedSectionId: course,
            visitedSocialConnection: false,
            isBanned: false,
            completedTraining: '',
            coursesCompleted:state.user.coursesCompleted
        }


        try {
            setState(prevState => ({...prevState, isLoading:true}));

            toast.loading("Updating user...", {id: "updating-user-toast"});

            const updateUser:IUserBio = await userController.AdminUpdateUserProfile(user);

            const isAdminOrSuperAdmin = updateUser.roles.some(r => adminRoles.includes(r));

            if(isAdminOrSuperAdmin) {
                await userModuleController.UnlockAllLessonsForUser(updateUser.id, updateUser.assignedSectionId);
            }


            setState(prevState => ({...prevState, isLoading:false}));

            toast.dismiss("updating-user-toast");

            toast.success("Successfully updated user");

            history.push('/users');
        } catch (error) {
            setState(prevState => ({...prevState, isLoading:false}));            
            toast.dismiss("updating-user-toast");
            displayErrorsInToast(error as string[]);
        }
    }

    /**
     * Creates a new user profile
     * @param values 
     */
    const createNewUserProfile = async (roles:Roles[], values:AdminFormValues) => {
        
        const user:IUserProfile = {
            id: '',
            name: values.username,
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            prefix: values.prefix,
            description: values.description,
            password: values.password,
            passwordLastChanged: new Date().toISOString(),
            passwordAttempts: 0,
            roles,
            status: UserStatus.Active,
            imageLink: '',
            createdAt: moment(new Date()).toISOString(),
            updatedAt: moment(new Date()).toISOString(),
            assignedSectionId: values.course,
            visitedSocialConnection: false,
            isBanned: false,
            completedTraining: '',
            coursesCompleted: []
        }
            
        setState(prevState => ({...prevState, isLoading:true}));
            
        const toastLoadId = "creating-user-toast"
            
        try {

            toast.loading("Creating user...", {id: toastLoadId});

            const userBio:IUserBio = await userController.AdminCreateUserProfile(user);            

            // await userModuleController.InitializeUserDataForParentId(userBio.id);        

            const isAdminOrSuperAdmin = userBio.roles.some(r => adminRoles.includes(r));

            if(isAdminOrSuperAdmin) {
                await userModuleController.UnlockAllLessonsForUser(userBio.id, userBio.assignedSectionId);
            }

            setState(prevState => ({...prevState, isLoading:false}));

            toast.dismiss(toastLoadId);

            toast.success("Successfully Created New User");

            history.push('/users');

        } catch (error) {   
            setState(prevState => ({...prevState, isLoading:false}));            
            toast.dismiss(toastLoadId);
            displayErrorsInToast(error as string[]);
        }
    }

    
    /**
     * On submit create a new user and add the main training module to that user
     * @param values 
     * @param setSubmitting 
     * @returns 
     */
    const handleSubmit = async (values:AdminFormValues, setSubmitting:(isSubmitting: boolean) => void) => {                        
        const cleanedInput = cleanInput(values);           
        
        let roles = values.roles.map(role => parseInt(role as unknown as string));
        if(roles.length <= 0) {
            toast.error("Please select a valid role");
            return;
        }
        if(roles.includes(Roles.TestUser) && !roles.includes(Roles.User)) {
            roles.push(Roles.User);
        }
        if(isEditing) {
            updateUserProfile(roles, cleanedInput);            
        } else {
            createNewUserProfile(roles, cleanedInput);
        }

        setSubmitting(false);
    }

    /**
     * Sanitizes user input
     * @param values 
     * @returns 
     */
    const cleanInput = (values:AdminFormValues) => {
        const purifyOpts = {USE_PROFILES: {html: false},  ALLOWED_TAGS: [] };

        const name = DOMPurify.sanitize(FormHelper.RemoveWhiteSpace(values.username.toLowerCase()), purifyOpts);
        const firstName = DOMPurify.sanitize(values.firstName.trim(), purifyOpts);
        const lastName = DOMPurify.sanitize(values.lastName.trim(), purifyOpts);
        const email = DOMPurify.sanitize(FormHelper.RemoveWhiteSpace(values.email).toLowerCase(), purifyOpts);
        const description = DOMPurify.sanitize(values.description.trim(), purifyOpts);
        const prefix = DOMPurify.sanitize(values.prefix.trim(), purifyOpts);
                
        const cleanedValues:AdminFormValues = {
            ...values,
            username: name,
            prefix: prefix,
            firstName: firstName,
            lastName: lastName,
            email: email,
            description: description,
        }
        return cleanedValues;
    }

    return (
        <Formik
            enableReinitialize={true} 
            initialValues={state.initialValues}
            validationSchema={validationSchema}
            onSubmit={(values:any, {setSubmitting}) => {handleSubmit(values, setSubmitting)}}
        >   
        {({values}) => (
            <Form 
                translate={"yes"}
                className="user-form form" 
            >
                <h2 className='form-title'>
                    {
                        isEditing ? 
                        (
                            <>Update User</>
                        ) 
                        : 
                        (
                            <>Create User</>
                        )
                    }
                    
                </h2>
                {
                    state.isLoading ? 
                    (
                        <Spinner animation="border"></Spinner>
                    )
                    :
                    (
                        <>
                            <div className="cbit-form-group">                
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="username">Username {" "}
                                        <span className="required-field">*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <Field 
                                        id="username"
                                        name="username" 
                                        className="form-input"
                                        type="text"
                                        placeholder="Username"                                                              
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="username"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="cbit-form-group">
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="prefix">Prefix {" "}
                                        
                                    </label>
                                </div>
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="firstName">First Name {" "}
                                        <span className="required-field">*</span>
                                    </label>
                                </div>
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="lastName">Last Name {" "}
                                        <span className="required-field">*</span>
                                    </label>
                                </div>
                            </div>
                            <div className="cbit-row">
                                <div className="cbit-column">
                                    <Field 
                                        id="prefix"
                                        name="prefix" 
                                        className="form-input"
                                        type="text"
                                        placeholder="Prefix"
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="prefix"/>
                                    </div>
                                </div>
                                <div className="cbit-column">
                                    <Field 
                                        id="firstName"
                                        name="firstName" 
                                        className="form-input"
                                        type="text"
                                        placeholder="First Name"
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="firstName"/>
                                    </div>
                                </div>
                                <div className="cbit-column">
                                    <Field 
                                        id="lastName"
                                        name="lastName" 
                                        className="form-input"
                                        type="text"
                                        placeholder="Last Name"
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="lastName"/>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label className='form-label' htmlFor="email">Email {" "}
                                            <span className="required-field">*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="email"
                                            name="email" 
                                            className="form-input"
                                            type="email"
                                            placeholder="Email"
                                        />
                                        <div className="error-message-container">
                                            <ErrorMessage name="email"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label className='form-label' htmlFor="description">Bio {" "}                                            
                                        </label>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="description"
                                            name="description" 
                                            className={`admin-description-field form-input my-profile-description ${displayMaxCharDescError(values.description)}`}
                                            component="textarea"
                                            placeholder="Bio Description"
                                        />
                                        <div className="description-error">
                                            <div className="error-message-container">
                                                <ErrorMessage name="description"/>
                                            </div>
                                            <CharacterLimit 
                                                maxCharLimit={MaxCharactersForDescription} 
                                                characters={values.description} 
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label className='form-label'>Roles {" "}
                                            <span className="required-field">*</span>
                                        </label>
                                    </div>
                                </div>                            
                                <div className="cbit-row">
                                    <div 
                                        role="group"
                                        className='user-form-checkbox-container'
                                        aria-label='checkbox-group'                                
                                    >
                                    {
                                        availableRoles.map((role) => (                                        
                                            <div className="user-form-checkbox" key={role}>
                                                <label  className="checkbox-label-cbit">
                                                    <Field 
                                                        type="checkbox" 
                                                        name="roles" 
                                                        value={role.toString()}                                                 
                                                        className='form-checkbox-input checkbox-cbit'    
                                                    />
                                                    {roleLabels[role]}
                                                </label>
                                            </div>
                                        ))
                                    }  
                                    <div className="error-message-container">
                                        <ErrorMessage name="roles"/>                                                                                                    
                                    </div>                          
                                    </div>  
                                </div>
                                <div className="cbit-form-group">
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <label className='form-label' htmlFor="course">Assign Course {" "}
                                            <span className="required-field">*</span>
                                        </label>
                                    </div>
                                </div>
                                <div className="cbit-row">
                                    <div className="cbit-column">
                                        <Field 
                                            id="course"
                                            name="course"                                            
                                            className="form-input"
                                            as="select"
                                            placeholder="Select Course"
                                            value={values.course}
                                        >
                                            {
                                                courses.map(c => (
                                                    <option key={c.id} value={c.id}>{c.name}</option>
                                                ))
                                            }
                                        </Field>
                                        <div className="error-message-container">
                                            <ErrorMessage name="course"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                            {!isEditing && (
                                <>
                                <div className="cbit-form-group">
                                    <div className="cbit-row">
                                        <div className="cbit-column">
                                            <label className='form-label' htmlFor="password">Password {" "}
                                                <span className="required-field">*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="cbit-row">
                                        <div className="cbit-column">
                                            <div className="password-container">
                                                <Field 
                                                    id="password"
                                                    name="password" 
                                                    className="form-input"
                                                    type={state.isPwdVisible ? "text" : "password"}
                                                    placeholder={isEditing ? "Password (leave blank to not change)" : "Password"}
                                                />
                                                <button type="button" className='toggle-visibility' onClick={() => togglePwdVisibility()}>         
                                                {
                                                    state.isPwdVisible ? 
                                                    (
                                                        <img 
                                                            className="toggle-visibility-hide" 
                                                            src={IconEyeClosed}
                                                            alt="Hide password"
                                                        />
                                                    )
                                                    : 
                                                    (
                                                        <img 
                                                            className="toggle-visibility-open" 
                                                            src={IconEyeOpen}
                                                            alt="Show password"
                                                        />
                                                    )
                                                }
                                                </button>   
                                            </div>
                                            <div className="error-message-container">
                                                <ErrorMessage name="password"/>
                                            </div>
                                        </div>
                                    </div>
                                    
                                </div>
                                <div className="cbit-form-group">
                                    <div className="cbit-row">
                                        <div className="cbit-column">
                                            <label className='form-label' htmlFor="passwordConfirm">Password Confirmation {" "}
                                                <span className="required-field">*</span>
                                            </label>
                                        </div>
                                    </div>
                                    <div className="cbit-row">
                                        <div className="cbit-column">
                                            <div className="password-container">
                                                <Field 
                                                    id="passwordConfirm"
                                                    name="passwordConfirm" 
                                                    className="form-input"
                                                    type={state.isPwdConfirmVisible ? "text" : "password"}
                                                    placeholder="Password Confirmation"
                                                />
                                                <PasswordVisibilityToggle 
                                                    isPwdVisible={state.isPwdConfirmVisible}
                                                    togglePasswordVisibility={togglePwdConfirmVisibility}
                                                />
                                            </div>
                                                <div className="error-message-container">
                                                    <ErrorMessage name="passwordConfirm"/>
                                                </div>
                                        </div>
                                    </div>
                                </div>
                                </>
                            )}
                        </div>
                        
                        {state.isLoading ?(
                            <button disabled className='btn-cbit-primary'>
                                Loading...
                            </button>
                            )
                            :
                            <button className='btn-cbit-primary' type="submit"> 
                                {
                                isEditing ? 
                                    (
                                        <>Update</>
                                    )
                                    :
                                    (
                                        <>Create</>
                                    )
                                }                        
                            </button>
                        }
                        </>
                    )
                }
            </Form>   
            )}    
        </Formik>
    )
}

export default AdminUserForm;