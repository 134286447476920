import { DirectUploadResults } from "../models/ImageManagement/DirectUploadResults";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { ImageManagementService } from "../services/ImageManagementService";

/**
 * Controller for communicating the with the image management services 
 */
export class ImageManagementController {
    
    private ImageManagement = new ImageManagementService();
    
    /**
     * Get a url that is used to upload images to cloudflare
     * @returns 
     */
    public async GetDirectUploadUrl() {
        try {

            const directUploadRes:DirectUploadResults = await this.ImageManagement.GetImageUrl();                    
            
            if(!directUploadRes.success)
                throw new Error("Direct Upload Error");
            
            return directUploadRes.result
            

        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }        
    }

    /**
     * Using a direct upload url, uploads an image to Cloudflare
     * @param uploadUrl 
     * @param imageFile 
     * @returns 
     */
    public async UploadImage(uploadUrl: string, imageFile:File){
        try {   

            const formData = new FormData();

            formData.append("file", imageFile,imageFile.name);            
            
            //TODO move to services
            const uploadResult = await fetch(uploadUrl, {
              method: "POST",
              body: formData,
            });

            return uploadResult;
            
        } catch (error) {
            console.error(error);
        }
    }

    /**
     * Removes an image from cloudflare
     * @param id 
     */
    public async DeleteImage(id:string) {
        try {
            
            const deleteResults = await this.ImageManagement.Delete({id});            

        } catch (error) {
            ErrorHandler.catchApiError(error as any);
        }
    }
}