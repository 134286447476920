import React, { useEffect, useState } from "react";
import { UserWithCourse } from "../../../models/User/UserWithCourse";
import { RolesHelper } from "../../../models/utility/Roles";
import UserStatus, { UiFriendlyStatus } from "../../../models/utility/UserStatus";
import IconMagnifyingGlass from '../../../resources/icons/icon-magnifying-glass-light-blue.png';
import "./UserManagementSearch.css";

type UserManagementSearchProps = {
    users:UserWithCourse[];
    filterUsers:(users:UserWithCourse[]) => void;
}

const searchableFields = [
    "firstName",
    "name",
    "lastName",
    "username",
    "email",            
    "createdAt",                
]

const searchRegex = (searchTerm:string) => ( new RegExp(`.*${searchTerm}.*`, "ig"));
/**
 * Input field that handles the global filtering/search for the user management section
 * @returns 
 */
const UserManagementSearch = (props:UserManagementSearchProps) => {
    const {
            users, 
            filterUsers
    } = props;

    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        const term = searchTerm.trim().toLowerCase();

        if(term.length <= 0) {
            filterUsers(users);
            return
        }

        const filteredRows = users.filter((o: any) => {
            return Object.keys(o).some((k: any) => {                
                if(searchableFields.includes(k)) {
                    return searchRegex(searchTerm).test(o[k].toString());
                } else {
                    if(k === "roles") {
                        const role = o[k];                                            

                        if(role == null) {
                            return false;
                        }
                        const highestRole = RolesHelper.GetHighestRole(role);

                        return searchRegex(searchTerm).test(highestRole);
                    }

                    if(k === "status") {
                        const status:UserStatus = o[k];                        
                        //watch out for !status because that will flag false for an enum with a value of 0
                        if(status == null) {
                            return false;
                        }

                        return searchRegex(searchTerm).test(UiFriendlyStatus[status]);
                    }

                    return false;
                }
            });
        });

        filterUsers(filteredRows);
    }, [searchTerm]);

    /**
     * On input change, updates state
     * @param e 
     */
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.currentTarget.value;        
        setSearchTerm(val);                
    }     

    return (
        <div className="admin-search-container">
        <span className='admin-search-icon'>
            <img src={IconMagnifyingGlass} alt="Search Icon" />
        </span>
        <input
            className="user-mana-search form-input" 
            type="text"
            placeholder="SEARCH"
            onChange={handleChange}
            value={searchTerm}
        />
    </div>
    )
}

export default UserManagementSearch