import * as React from "react";
import {FunctionComponent, useContext, useEffect, useState} from "react";

import {PartialModuleTreeModel} from "../../../models/partialModels/PartialModuleTreeModel";
import {BranchLevelOne} from "./AccordionItems/BranchLevelOne";
import {Accordion, Card} from "react-bootstrap";
import {BranchLevelTwo} from "./AccordionItems/BranchLevelTwo";
import {BranchLevelThree} from "./AccordionItems/BranchLevelThree";
import {LessonLevelOne} from "./AccordionItems/LessonLevelOne";
import {LessonLevelTwo} from "./AccordionItems/LessonLevelTwo";
import {LessonLevelThree} from "./AccordionItems/LessonLevelThree";
import { LessonIconEnum } from "../../../models/lesson/Lesson";


type moduleProps={
    module:PartialModuleTreeModel,
    parentModule: PartialModuleTreeModel,
    selectedModule: PartialModuleTreeModel
    depth:number,
    SetSelectedItem: any,
    SetSelectedBranch: any,
    selectedBranch: PartialModuleTreeModel
}

const ModuleAccordionItemComp:FunctionComponent<moduleProps> =(props)=>{

    const [moduleItemState, setModuleItemState] = useState<PartialModuleTreeModel>({
        id: '',
        originalDocumentId: '',
        type: 0,
        order: 0,
        name: '',
        description: '',
        isViewable: true,
        isDisabled: false,
        isEditable: true,
        isOptional: true,
        isComplete: false,
        isOpen: false,
        parentDocumentId:'',
        isNext:false,
        isLocked:false,
        time:'',
        children: [],
        lessonIcon: LessonIconEnum.Unknown,
        totalQuestions: 0
    })
    const [depth, setDepth] = useState(props.depth + 1)

    useEffect(()=>{
        var core = props.module;
        setModuleItemState(core);
    },[props.module]);

    //On Lesson Selection Handle Navigation
    const handleAccordionClosingOnSelect =()=>{

    }

    return(
        <>
            {moduleItemState.type== 1 &&(
                <>
                    {props.depth == 0 &&(
                        <>
                            <div className="admin-chapter-item" >
                                <BranchLevelOne eventKey={moduleItemState.id}
                                                selectedModuleId={props.selectedModule.id}
                                                SetSelectedBranch={props.SetSelectedBranch}
                                                selectedBranch={props.selectedBranch}
                                                parentModule={props.parentModule}
                                                module={moduleItemState}
                                                callback={""}>
                                    {moduleItemState.name}
                                </BranchLevelOne>
                            </div>
                            <Accordion.Collapse data-key={moduleItemState.id} eventKey={moduleItemState.id}>
                                <Accordion flush>
                                    {moduleItemState?.children?.map((module) =>
                                        (
                                            <ModuleAccordionItemComp module={module}
                                                                     parentModule={props.parentModule}
                                                                     depth={depth}
                                                                     selectedModule={props.selectedModule}
                                                                     SetSelectedBranch={props.SetSelectedBranch}
                                                                     selectedBranch={props.selectedBranch}
                                                                     SetSelectedItem={props.SetSelectedItem}
                                                                     key={module.id}
                                            />

                                        ))}
                                </Accordion>
                            </Accordion.Collapse>

                        </>
                    )}
                    {props.depth == 1 &&(
                        <>
                            <div className="admin-chapter-item no-border" >
                                <BranchLevelTwo eventKey={moduleItemState.id}
                                                selectedModuleId={""}
                                                parentModule={props.parentModule}
                                                module={moduleItemState}
                                                callback={""}>
                                    {moduleItemState.name}
                                </BranchLevelTwo>
                            </div>
                            <Accordion.Collapse data-key={moduleItemState.id} eventKey={moduleItemState.id}>
                                <Accordion flush>
                                    {moduleItemState?.children?.map((module) =>
                                        (
                                            <ModuleAccordionItemComp module={module}
                                                                     parentModule={props.parentModule}
                                                                     depth={ depth}
                                                                     selectedModule={props.selectedModule}
                                                                     SetSelectedBranch={props.SetSelectedBranch}
                                                                     selectedBranch={props.selectedBranch}
                                                                     SetSelectedItem={props.SetSelectedItem}
                                                                     key={module.id}
                                            />
                                        ))}
                                </Accordion>
                            </Accordion.Collapse>
                        </>
                    )}
                    {/*{props.depth == 2 &&(*/}
                    {/*    <>*/}
                    {/*        <div className="admin-chapter-item" >*/}
                    {/*            <BranchLevelThree eventKey={moduleItemState.id}*/}
                    {/*                            selectedModuleId={""}*/}
                    {/*                            parentModule={props.parentModule}*/}
                    {/*                            module={moduleItemState}*/}
                    {/*                            callback={""}>*/}
                    {/*                {moduleItemState.name} {props.depth}*/}
                    {/*            </BranchLevelThree>*/}
                    {/*        </div>*/}
                    {/*        <Accordion.Collapse data-key={moduleItemState.id} eventKey={moduleItemState.id}>*/}
                    {/*            <Accordion flush>*/}
                    {/*                {moduleItemState?.children?.map((module) =>*/}
                    {/*                    (*/}
                    {/*                        <ModuleAccordionItemComp module={module}*/}
                    {/*                                                 parentModule={props.parentModule}*/}
                    {/*                                                 selectedModule={props.selectedModule}*/}
                    {/*                                                 depth={depth}*/}
                    {/*                                                 setOpenBranch={props.setOpenBranch}*/}
                    {/*                                                 setSelectedItem={props.setSelectedItem}/>*/}

                    {/*                    ))}*/}
                    {/*            </Accordion>*/}
                    {/*        </Accordion.Collapse>*/}
                    {/*    </>*/}
                    {/*)}*/}

                </>
            )}
            {moduleItemState.type>=2 &&(
                <>
                    {props.depth == 1 &&(
                        <>
                            <div className="admin-lessons">
                                <LessonLevelOne eventKey={moduleItemState.id}
                                                module={moduleItemState}
                                                selectedModuleId={props.selectedModule.id}
                                                selectedBranch={props.selectedBranch}
                                                setSelectedBranch={props.SetSelectedBranch}
                                                parentModule={props.parentModule}
                                                callback={()=>props.SetSelectedItem(moduleItemState)}>
                                    {moduleItemState.name}
                                </LessonLevelOne>
                                <>
                                </>
                            </div>
                        </>
                    )}
                    {props.depth == 2 &&(
                        <>
                            <div className="admin-lesson-group-items">
                                <LessonLevelTwo eventKey={moduleItemState.id}
                                                module={moduleItemState}
                                                selectedModuleId={props.selectedModule.id}
                                                parentModule={props.parentModule}
                                                callback={()=>props.SetSelectedItem(moduleItemState)}>
                                {moduleItemState.name}
                                </LessonLevelTwo>
                                <>
                                </>
                            </div>
                        </>
                    )}
                    {/*{props.depth == 3 &&(*/}
                    {/*    <>*/}
                    {/*        <Card className="no-border full-width Lesson-Header-Depth-2">*/}
                    {/*            <LessonLevelThree eventKey={moduleItemState.id}*/}
                    {/*                              module={moduleItemState}*/}
                    {/*                              selectedModuleId={props.selectedModule.id}*/}
                    {/*                              parentModule={props.parentModule}*/}
                    {/*                              callback={()=>props.setSelectedItem(moduleItemState)}>*/}
                    {/*                {moduleItemState.name}*/}
                    {/*            </LessonLevelThree>*/}
                    {/*            <>*/}
                    {/*            </>*/}
                    {/*        </Card>*/}
                    {/*    </>*/}
                    {/*)}*/}
                </>
            )}
        </>
    );
}


export default ModuleAccordionItemComp;