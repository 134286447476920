import React from 'react';
import CustomModal from './CustomModal';
import './CustomAlert.css';
type CustomAlertProps = {
    header:string
    text:string
    primaryBtnText:string
    secondaryBtnText:string
    tertiaryBtnText?:string
    isLoading?:boolean
    isOpen:boolean    
    primaryBtnSubmit:() => void
    secondaryBtnSubmit:() => void
    toggleAlert:() => void
    tertiaryBtnSubmit?:()=>void
}

/**
 * A custom Alert component that pops with as a modal
 */
const CustomAlert = (props:CustomAlertProps) => {
    
    const {
            header, 
            text, 
            primaryBtnText, 
            secondaryBtnText, 
            tertiaryBtnText, 
            isLoading,
            primaryBtnSubmit, 
            secondaryBtnSubmit, 
            tertiaryBtnSubmit, 
            toggleAlert, 
            isOpen,            
    } = props;
    
    return (
        <>
            <CustomModal isOpen={isOpen} toggle={toggleAlert}>        
                <div className="custom-alert">
                    <div className="custom-alert-header">
                        {header}
                    </div>
                    <div className="custom-alert-text">
                        {text}
                    </div>
                        <div className="btns-custom-alert-container">
                        {
                                tertiaryBtnText && tertiaryBtnSubmit && (
                                    <button 
                                        disabled={isLoading}
                                        className='btn-cbit-minor' 
                                        onClick={() => tertiaryBtnSubmit()}>
                                            {tertiaryBtnText
                                        }</button> 
                                )
                            }
                            <button
                                disabled={isLoading} 
                                className='btn-cbit-minor' 
                                onClick={() => secondaryBtnSubmit()}>
                                    {secondaryBtnText}
                            </button>
                            <button
                                disabled={isLoading} 
                                className='btn-cbit-primary' 
                                onClick={() => primaryBtnSubmit()}>
                                    {primaryBtnText}
                            </button>
                        </div>
                </div>
            </CustomModal>
        </>
    )
}

export default CustomAlert;