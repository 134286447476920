import { IAdminTreeModule } from "../../partialModels/AdminTreeModule";
import { PartialModuleTreeModel, TreeViewType } from "../../partialModels/PartialModuleTreeModel";
import { sortModule } from "../SortModule";

/**
 * Class for helping with navigating inside of a tree 
 */
export class AdminTreeHelper {
    
    /**
    * Find lessons that are marked with isNext and unMark them.
    * This is to fix a UI bug where when already having a isNext lesson (the bookmark), 
    * navigating to a different lesson will create another is next lesson. So there will be two or more lessons with the bookmark 
    */
    static unMarkIsNext = (parent:PartialModuleTreeModel) => {
        const listOfNextLessons = AdminTreeHelper.findIsNextNodesByBfs(parent);
        if(!listOfNextLessons.length) {
            return;
        }

        for(let node of listOfNextLessons) {
            if(node.isNext) {
                node.isNext = false;
            }
        }        
    }

    /**
     * Find a list of nodes that are marked is next by using breath first search
     * @param parent 
     * @returns 
     */
    static findIsNextNodesByBfs = (parent:PartialModuleTreeModel) => {
        let queue:PartialModuleTreeModel[] = [];
        let results = [];
        queue.push(parent);

        while(queue.length) {
            let node = queue.shift();
            if(node?.isNext) {
                results.push(node);
            }
            if(node?.children) {
                queue.push(...node.children);
            }
            
        }

        return results;
    }

    /**
     * 
     * @param node 
     * @param replacementNode 
     * @returns 
     */
    static replaceNodeInTree = (node:PartialModuleTreeModel|IAdminTreeModule, replacementNode:IAdminTreeModule) => {                

        let replacedNode = null;
        
        if(node.id === replacementNode.id) {            
            node = (node as IAdminTreeModule);

            node.name = replacementNode.name;
            node.duration = replacementNode.duration;
            node.order = replacementNode.order;
            node.type = replacementNode.type;
            node.order = replacementNode.order;
            node.isViewable = replacementNode.isViewable;
            node.lessonIcon = replacementNode.lessonIcon;
            replacedNode = node;        
        }

        for(let child of node.children) {
            this.replaceNodeInTree(child, replacementNode)
        }  
        
        return replacedNode
    }

    /**
     * 
     * @param node 
     * @param parentID 
     * @param nodeToInsert 
     * @returns 
     */
    static addNodeToTree : any = (node:PartialModuleTreeModel, parentID:string, nodeToInsert:PartialModuleTreeModel) => {
        if(node.id === parentID) {
            // const converted = AdminTreeModule.convertPartialTreeToAdminTreeModule(nodeToInsert);
            //@ts-ignore
            node.children.push(nodeToInsert);            
            return node;
        } else if(node.children != null) {
            let i;
            let res = null;
            for(i=0; res == null && i < node.children.length; i++) {                

                res = this.addNodeToTree(node.children[i], parentID, nodeToInsert);                
            }
            return res;
        }
        return null;
    }

    /**
     * 
     * @param node 
     * @param id 
     * @param updatedNode 
     * @returns 
     */
    static updateTree : any = (node:IAdminTreeModule, id:string, updatedNode:IAdminTreeModule) => {
        
        if(node.id === id){            
            node.type = updatedNode.type;
            node.order = updatedNode.order;
            node.name = updatedNode.name;
            node.description = updatedNode.description;
            node.isViewable = updatedNode.isViewable;
            node.duration = updatedNode.duration;      
            node.lessonIcon = updatedNode.lessonIcon;      
            return node;
        }else if (node.children != null){
             let i;
             let result = null;
             for(i=0; result == null && i < node.children.length; i++){
                  result = this.updateTree(node.children[i], id, updatedNode);
             }
             return result;
        }
        return null;
    }

    static removeNodeFromTree = (parentNode:any,parentID:string, nodeToRemoveID:string) : any => {
        
        if(parentNode.id === parentID) {
            parentNode.children = parentNode.children.filter((node:any) => node.id !== nodeToRemoveID);            
            return parentNode
        } else if(parentNode.children != null) {
            let i;
            let res = null;

            for(i=0; res == null && i < parentNode.children.length; i++) {
                res = this.removeNodeFromTree(parentNode.children[i], parentID, nodeToRemoveID);
            }
            return res;

        }
        return null;
    }

    static updateTreeOrder = (node:any, parentID:string, nodeToInsert:any) : any => {
        if(node.id === parentID) {
            sortModule(node.children, false);
        } else if (node.children != null) {
            let i;
            let res = null;
            for(i = 0; res == null && i < node.children.length; i++) {
                res = this.updateTreeOrder(node.children[i], parentID, nodeToInsert);
            }
            return res;
        }
        return null;
    }

    static getFirstViewableNode = (parentNode:PartialModuleTreeModel) : any => {                

        if(parentNode.isViewable === true && parentNode.type !== TreeViewType.Branch && parentNode.type !== TreeViewType.none) {
            return parentNode;
        } else if (parentNode.children != null) {
            let i;
            let res = null;

            for(i=0; res == null && i < parentNode.children.length; i++) {
                res = this.getFirstViewableNode(parentNode.children[i]);
            }
            return res;
        }
        return null;
    }
}