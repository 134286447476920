import React, { useEffect, useRef } from 'react';
import toast from 'react-hot-toast';
import { Spinner } from 'reactstrap';
import { LessonController } from '../../../../../controllers/LessonController';
import { ModuleController } from '../../../../../controllers/ModuleController';
import { ILesson, IsInstanceOfLesson, Lesson } from '../../../../../models/lesson/Lesson';
import { IsInstanceOfLessonContent } from '../../../../../models/lesson/LessonContent';
import { LessonTypeEnum } from '../../../../../models/lesson/LessonType';
import { AdminModule, IAdminModule } from '../../../../../models/module/AdminModule';
import { IModule, IsInstanceOfModule } from '../../../../../models/module/Module';
import { AdminTreeModule, IAdminTreeModule } from '../../../../../models/partialModels/AdminTreeModule';
import { DeepCopy } from '../../../../../models/utility/DeepCopy';
import { EditorContentEnum } from '../../../../../models/utility/EditorContentEnum';
import { sortModule } from '../../../../../models/utility/SortModule';
import { ModuleAction } from '../../../../../models/utility/UnSavedCourse/ModuleAction';

import LessonItem from '../../ContentItems/LessonItem';
import ModuleItem from '../../ContentItems/ModuleItem';
import { AdminViewProps } from '../TopModuleView/TopModuleView';

type ChapterViewProps = {    
    parentModule:IAdminTreeModule
    selectedModule:IAdminTreeModule
} & AdminViewProps;

const moduleController = new ModuleController();

const lessonController = new LessonController();

/**
 * The view when inside a chapter or module
 * @param props 
 * @returns 
 */
const ChapterView = (props:ChapterViewProps) => {
    const {
        contentList, 
        updatedContentList, 
        updateIsLoading, 
        selectedModuleId, 
        topMostModuleId,
        contentType,
        navigateTo,
        isLoading,
        updateAccordion,
        removeAccordionItem,
        onDeleteConfirm,
        updateUnSavedContent,
        addToAccordion,
        parentModule,
        selectedModule
    } = props;

    const firstRender = useRef(true);

    useEffect(() => {
        async function onSelectedModuleChange() {
            
            const type = AdminTreeModule.getAdminTreeItemContentType(selectedModule,parentModule)

            if(type === EditorContentEnum.module || type === EditorContentEnum.lessonGroup) {
                // getContent();            
            }
        }
        onSelectedModuleChange();
    },[selectedModuleId]);

    /**
     * Get content inside the current chapter we are in
     * lessons, sub modules, etc
     */
    const getContent = async () => {
        
        if(!selectedModuleId && selectedModuleId.length <= 0)
            return;
        try {
            updateIsLoading(true);
            const modules:IModule[] = await moduleController.GetModulesWithParentId(selectedModuleId);
            
            const lessons:ILesson[] = await lessonController.GetLessonsWithParentId(selectedModuleId);

            const combinedList = [...modules, ...lessons];            

            sortModule(combinedList, false);

            const adminModules:IAdminModule[] = combinedList.map((content) => (new AdminModule(content)));
            
            updatedContentList(adminModules);

        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve chapters and lessons");
        }
        
        updateIsLoading(false);        
    }

    const getContentType = (content:any) => {
        if(IsInstanceOfModule(content)) {
            return EditorContentEnum.module
        } else if(IsInstanceOfLesson(content)) {
            
            if((content.type as LessonTypeEnum) === LessonTypeEnum.quiz) {
                return EditorContentEnum.lessonQuiz
            }

            return EditorContentEnum.lesson

        } else if (IsInstanceOfLessonContent(content)){
            return EditorContentEnum.lessonContent
        }  
        else {
            return EditorContentEnum.lessonGroup;
        } 
    }

        /**
     * Removes a content item from the content list and the accordion
     * @param adminModule 
     */
    const removeContent = (adminModule:IAdminModule) => {
       let contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);
    
       contentListCopy = contentListCopy.filter(adminMod => adminMod.content.id !== adminModule.content.id);  
    
       updatedContentList(contentListCopy);
       
       removeAccordionItem(adminModule.content.id);      
       
       updateUnSavedContent(adminModule, ModuleAction.DELETE);

    }

    /**
     * Adds a new chapter to the content list and updates the accordion 
     * @param adminModule 
     */
    const addChapter = (adminModule:IAdminModule) => {            
        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);

        const foundIndex = contentListCopy.findIndex(adminMod => adminMod.key === adminModule.key);        

        if(foundIndex !== -1) {
            contentListCopy[foundIndex] = adminModule;            
            
            updatedContentList(contentListCopy);

            addToAccordion(AdminTreeModule.convertAdminModuleToAdminTreeModule(adminModule));
            
            updateUnSavedContent(adminModule, ModuleAction.ADD);
            return;
        }
    }

    /**
     * replaces an adminModule item in the content list with an updated one
     * @param adminModule 
     * @returns 
     */
    const updateContent = (adminModule:IAdminModule) => {
        const contentListCopy:IAdminModule[] = DeepCopy.copy(contentList);
        
        const foundIndex = contentListCopy.findIndex(adminMod => adminMod.content.id === adminModule.content.id);        
        if(foundIndex !== -1) {
            contentListCopy[foundIndex] = adminModule;            
            
            updatedContentList(contentListCopy);
            updateAccordion(AdminTreeModule.convertAdminModuleToAdminTreeModule(adminModule));
    
            if(adminModule.content.id === adminModule.key) {
                updateUnSavedContent(adminModule, ModuleAction.ADD);
            } else {
                
                updateUnSavedContent(adminModule, ModuleAction.UPDATE);
            }
            return;
        }
    
        toast.error("Failed to update Lesson")
    }

    const handleModuleRender = (contentItem:IAdminModule, index:number) => {
        const {content, key, isNewModule} = contentItem;

        const type = getContentType(content);   
        
        const isLesson = IsInstanceOfLesson(content);

        const isNewContent = content.id === "";

        const onSubmit = content.id.length <= 0 ? addChapter : updateContent        
        
        if(
            IsInstanceOfModule(content as IModule) 
            || content.type === LessonTypeEnum.custom 
            || content.type === LessonTypeEnum.quiz
        ) {
            return <ModuleItem                        
                        parentID={topMostModuleId}
                        onDeleteConfirmSubmit={onDeleteConfirm}
                        contentType={type}    
                        index={index}
                        content={content as IModule | ILesson}
                        navigateTo={navigateTo}
                        draggableID={key} 
                        deleteItem={() => removeContent(contentItem)} 
                        onSubmit={onSubmit} 
                        isLesson={isLesson} 
                        isEditing={isNewContent} 
                        addingNewContent={isNewContent}    
                        parentsVisibility={props.isVisible}
                        causeRerender={props.forceRerender}    
                        isNewModule={isNewModule}                         
                    />
        }

        if(isLesson) {                         
            //we need the constructor to run to generate specific fields in case the obj doesn't have them        
            const lesson = new Lesson(content as ILesson);
            
            return <LessonItem 
                        lesson={lesson} 
                        draggableId={key} 
                        onDeleteConfirmSubmit={onDeleteConfirm} 
                        updateScrollIsVisible={() => {}} 
                        index={index} 
                        deleteItem={() => removeContent(contentItem) } 
                        onSubmit={updateContent} 
                        isVisible={(content as ILesson).isViewable} 
                        isExpanded={props.isExpanded} 
                        causeRerender={props.forceRerender}  
                        parentsVisibility={props.isVisible}   
                        tagList={(content as ILesson).tagList}              
                    />
        }        
    }

    return (
        <>
        {
            isLoading ? 
            (
                <Spinner className='custom-spinner admin-spinner' color="secondary">loading</Spinner>
            ) 
            : 
            (
                <>
                {
                    contentList.length > 0 ? 
                    (
                        <>
                            {contentList.map((contentItem: IAdminModule, index:number) => (
                                    //@ts-ignore
                                    <React.Fragment key={contentItem.key}>
                                        {
                                            handleModuleRender(contentItem, index)                                                      
                                        }
                                    </React.Fragment>                                                                                        
                                )
                            )}                                                                                          
                        </>
                    ) 
                    :
                    (                                                                           
                        <div className="empty-content-list">
                            <p>There is nothing here yet</p>
                        </div>
                    )
                }
                </>
            )
        }
        
    </>
    )
}

export default ChapterView;