import React, {useEffect, useState} from "react";
import  IconCaretLeft from '../../../../resources/icons/icon-caret-left.png';
import  IconCaretDown from '../../../../resources/icons/icon-caret-down.png';
import {getTreeLength, PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import { AccordionHelper } from "../../../../models/utility/AccordionHelper";
import '../AdminAccordionItems.css';

interface contextAwareProps {
    module: any,
    children: any,    
    callback: any,
    parentModule: PartialModuleTreeModel
    selectedModuleId: string    
}

export type AdminBranchState = {
    isOpen:boolean,
    isSelected:boolean
    totalLessons:number
}

export const AdminBranchLevelOne: React.FC<contextAwareProps> = (props) =>
{
    const {module,children, callback, selectedModuleId, parentModule} = props;    

    const [state, setState] = useState<AdminBranchState>({
        isOpen:false,
        isSelected:false,
        totalLessons: 0
    });    

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            totalLessons:getTreeLength(module)
        }));
    }, []);

    useEffect(() => {
        handleAccordion();                
    }, [selectedModuleId]);
    
    /**
     * Handles the accordion state and collapse or show the 
     * accordion depending on the state
     * @returns 
     */
    const handleAccordion = () => {
        //If this current module is selected
        if(selectedModuleId === module.id) {
            setState(prevState => ({
                ...prevState,
                isSelected:true,
                isOpen:true
            }));

            AccordionHelper.setCollapseOrShowAccordion(false, module.id);

            return;
        }

        //If one of this current module's children are selected
        if((AccordionHelper.checkIfChildIsSelected(parentModule, module.id, selectedModuleId))) {
            setState(prevState => ({
                ...prevState,
                isSelected:false,
                isOpen:true
            }));
            AccordionHelper.setCollapseOrShowAccordion(false, module.id)
            return;
        }

        //When we are not selected and not open
        setState(prevState => ({
            ...prevState,
            isSelected:false,
            isOpen:false
        }));


        AccordionHelper.setCollapseOrShowAccordion(true, module.id)
    }

    /**
     * Navigate inside the module and toggle the accordion
     * @param e 
     */
    const handleNavigationClick = (e:React.MouseEvent<HTMLElement>) => {        
        e.stopPropagation();

        setState(prevState => ({
            ...prevState,
            isOpen:!state.isOpen,
            isSelected:true
        }));

        AccordionHelper.toggleAccordion(module.id);

        callback();                
    }
    
    return(
        <>
        {
            state.isSelected? 
            (
                <div className='chapter-content-container admin-selected' onClick={handleNavigationClick}>                        
                    <div className='admin-chapter-text'>
                        {children}
                    </div>
                        <div className='chapter-lesson-amount'>
                            {/* {(`(${lessonCount(module.children)})`)} */}
                            {(`(${getTreeLength(module)})`)}
                        </div>
                        <div
                            className={`admin-icon-container ${AccordionHelper.addIconCaretClass(state.isOpen)}`}
                        >
                            {
                                state.isOpen ?
                                (
                                    <img src={IconCaretDown} alt="icon caret down" />
                                )
                                :
                                (
                                    <img src={IconCaretLeft} alt="icon caret left" />
                                )
                            }

                        </div>

                    <div className="chapter-lesson-amount-container mobile">
                        <div className='chapter-lesson-amount mobile'>
                            {/* {(`(${lessonCount(module.children)}))`)} */}
                            {(`(${getTreeLength(module)})`)}
                        </div>
                        <div
                            className={`admin-icon-container mobile ${AccordionHelper.addIconCaretClass(state.isOpen)}`}
                        >
                            {
                                state.isOpen ?
                                (
                                    <img src={IconCaretDown} alt="icon caret down" />
                                )
                                :
                                (
                                    <img src={IconCaretLeft} alt="icon caret left" />
                                )
                            }

                        </div>

                    </div>
                </div>
            )
            :
            (
                <div className={`chapter-content-container`} onClick={handleNavigationClick}>

                        <div className='admin-chapter-text'>
                            {children}
                        </div>

                        <div className='chapter-lesson-amount'>
                            {`(${getTreeLength(module)})`}
                        </div>
                        <div
                            className={`admin-icon-container ${AccordionHelper.addIconCaretClass(state.isOpen)}`}>
                        {
                                    state.isOpen?
                                    (

                                        <img src={IconCaretDown} alt="icon caret down" />


                                    )
                                    :
                                    (

                                        <img src={IconCaretLeft} alt="icon caret Left" />
                                    )
                                }
                        </div>

                    <div className="chapter-lesson-amount-container mobile">
                        <div className='chapter-lesson-amount mobile'>
                            {`(${getTreeLength(module)})`}
                        </div>
                        <div
                            className={`admin-icon-container mobile ${AccordionHelper.addIconCaretClass(state.isOpen)}`}>
                        {
                                    state.isOpen?
                                    (

                                        <img src={IconCaretDown} alt="icon caret down" />


                                    )
                                    :
                                    (

                                        <img src={IconCaretLeft} alt="icon caret Left" />
                                    )
                                }
                        </div>
                    </div>
                </div>
            )
        }
        </>
    );

}

