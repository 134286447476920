import React, { useState } from "react";
import { IUserBio } from "../../../models/User/UserProfile";
import CloudflareImageHelper from "../../../models/utility/CloudflareImageHelper";
import { StringHelper } from "../../../models/utility/StringHelper";
import ImageUploader from "../../Utilities/ImageUploader";
import ProfileImage from "../../Utilities/ProfileImage/ProfileImage";
import * as yup from "yup";
import { ErrorMessage, Field, Form, Formik } from "formik";
import FormHelper, { MaxCharactersForDescription, displayMaxCharDescError } from "../../../models/utility/FormHelper";
import CharacterLimit from "../../Utilities/CharacterLimit";
import { UserController } from "../../../controllers/UserController";
import toast from "react-hot-toast";
import * as DOMPurify from "dompurify";
import { ImageManagementController } from "../../../controllers/ImageManagementController";
import { MyProfileDTO } from "../../../models/User/MyProfileDTO";

type MyProfileProps = {
    user:IUserBio
    updateImageLink:(imageLink:string) => void;
    toggleEditing:() => void;
}

type myProfileValues = {
    firstName:string;
    lastName:string;
    email:string;
    description:string;
    name:string;
    prefix:string;
}

const imageVariant = CloudflareImageHelper.variants.defaultProfileImage;

const userController = new UserController();
const imageController = new ImageManagementController();

const domPurifyOpts = {USE_PROFILES: {html: false},  ALLOWED_TAGS: [],  };

/**
 * Form for editing a user bio information.
 */
const MyProfileForm = (props:MyProfileProps) => {
    const {
            user: {
                id,
                imageLink,
                firstName,
                lastName,
                email,
                description,
                name,
                prefix,
            },
            updateImageLink,
            toggleEditing    
    } = props;

    const [imageFile, setImageFile] = useState<File | null>(null);
    const [imageUrl, setImageUrl] = useState("");
    const [isLoading, setIsLoading] = useState(false);

    const initialValues:myProfileValues = {
        firstName,
        lastName,
        email,
        description,
        name,
        prefix
    }

    const validationSchema = yup.object({
        name: yup.string()
        .max(20, FormHelper.maxErrorMsg('Username', 20))
        .min(3, FormHelper.minErrorMsg('Username', 3))
        .required(FormHelper.requiredErrorMsg('Username')),
        prefix: yup.string()
        .max(30, FormHelper.maxErrorMsg('Prefix', 30)),
        firstName: yup.string()
        .max(32, FormHelper.maxErrorMsg('First name', 32))
        .min(2, FormHelper.minErrorMsg('First name', 2))
        .required(FormHelper.requiredErrorMsg('First name')),
        lastName: yup.string()
        .max(32, FormHelper.maxErrorMsg('Last name', 32))
        .min(2, FormHelper.minErrorMsg('Last name', 2))
        .required(FormHelper.requiredErrorMsg('Last name')),
        email: yup.string()
        .matches(/(.+)@(.+){2,}\.(.+){2,}/, "Please provide a valid email address")
        .required(FormHelper.requiredErrorMsg('valid email address')),  
        description: yup.string()
        .max(MaxCharactersForDescription, `Bio must be ${StringHelper.formatNumberWithCommas(MaxCharactersForDescription)} characters or less`),
    });

    /**
     * Update profile image but does not upload the image to Cloudflare.
     * Basically preview of the image
     * @param file 
     */
    const UpdateProfileImageFile = (file:File) => {
        setImageFile(file);
        
        const profileImageUrl = window.URL.createObjectURL(file);
        if(profileImageUrl) {
            setImageUrl(profileImageUrl as string);                
        }
    }

    /**
     * Removes the image from the local state and from the user
     */
    const removeImageOnEdit = () => {
        if(imageFile) {
            setImageFile(null);
            setImageUrl("");
        }
        updateImageLink("");
    }

    const updateUserBio = async (data:myProfileValues) => {
        const loadId = "updateMyProfile";
        let {
            firstName,
            lastName,
            email,
            description,
            name,
            prefix,
        } = data;
        try {
            toast.loading("Updating profile", {id: loadId});
            setIsLoading(true);
                        

            //Cleaning our input
            firstName = DOMPurify.sanitize(firstName, domPurifyOpts)
            lastName = DOMPurify.sanitize(lastName, domPurifyOpts)
            email = DOMPurify.sanitize(email, domPurifyOpts)
            description = DOMPurify.sanitize(description, domPurifyOpts)
            name  = DOMPurify.sanitize(name , domPurifyOpts)
            prefix = DOMPurify.sanitize(prefix, domPurifyOpts)
            
            let profileImageLink = await uploadProfileImage() ??  imageLink;
            
            const myProfileDTO:MyProfileDTO = {
                username: name,
                prefix: prefix,
                firstName,
                lastName,
                email,
                description,
                imageLink:profileImageLink
            }
            
            const isEmailUse = await userController.CheckIfEmailIsInUse(email);
            const isUsername = await userController.CheckIfUsernameIsInUse(name);

            if(isEmailUse) {
                toast.error("Email is already in use");
                setIsLoading(false);
                toast.dismiss(loadId);
                return;
            }
            
            if(isUsername) {
                toast.error("Username is already in use");
                setIsLoading(false);
                toast.dismiss(loadId);
                return;
            }

            await userController.UpdateMyProfile(id, myProfileDTO);
            // await userController.UpdateUserBio({firstName, lastName, email, description, })

            toggleEditing();
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to update user profile");
        }
        setIsLoading(false);
        toast.dismiss(loadId);
    }

    /**
     * Uploads the profile image to cloud flare
     * @returns 
     */
       const uploadProfileImage =  async () => {
        if(!imageFile) {
            return;
        }

        const res: {id:string, uploadUrl:string} | undefined = await imageController.GetDirectUploadUrl();

        try {
            if(res) {
                if(res.uploadUrl) {
                    const uploadResults = await imageController.UploadImage(res.uploadUrl, imageFile); 
                    
                   if(uploadResults){
                        return res.id;
                   }
                }
            }
            
            return "";

        } catch (error) {
            console.error(error);
            // setIsLoading(false);
            toast.error("Failed To Upload Image");
        }
    }    

    return (
        <div className="my-profile my-profile-form cbit-dialog">
            <h2 className="cbit-dashboard-header">Edit profile</h2>
            <div className="my-profile-inner">
            <div className="profile-inner-left">
                {
                    imageUrl.length > 0 ? 
                    (
                        <ProfileImage 
                            imageLink={imageUrl}
                            imageVariant={imageVariant}
                            isLocal
                        />
                    ) 
                    : 
                    (
                        <ProfileImage 
                            imageLink={imageLink}
                            imageVariant={imageVariant}
                        />
                    )
                }
            <div className="image-upload-container">
                <label 
                    htmlFor='inputImageUpload' 
                    className="upload-btn-container btn-cbit-minor"
                    >
                    {imageLink.length > 0 || !StringHelper.IsNullOrWhiteSpace(imageUrl) ? 
                        (
                            <>Change</>
                            ) 
                            : 
                            (
                                <>Upload</>
                                )
                            }                                            
                </label>
                <ImageUploader 
                    setIsLoading={() => {}}
                    isLoading={false}
                    updateParentImageUrl={() => {}}
                    performAfterUpload={() => {}}
                    removeImage={() => {}}
                    hideRemoveImage={true}
                    customUpload={UpdateProfileImageFile}
                />
            </div>
            <button 
                className="btn-cbit-link"
                onClick={removeImageOnEdit}
            >
                    Remove Image
            </button>
        </div>
        <div className="profile-inner-right">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={updateUserBio}
            >
                {({handleSubmit, values, handleChange}) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="cbit-form-group">
                            <label className='form-label' htmlFor="username">Username{" "}
                                <span className="required-field">*</span>
                            </label>                            
                            <Field
                                type="text"
                                className="form-input" 
                                placeholder="Username"
                                id="username"
                                name="name"
                                disabled={isLoading}
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="name"/>
                            </div>
                        </div>
                        <div className="cbit-form-group">
                            <label className='form-label' htmlFor="prefix">Prefix {" "}</label>
                            <Field 
                                id="prefix"
                                name="prefix" 
                                className="form-input form-control"
                                type="text"
                                placeholder="Prefix"
                                disabled={isLoading}
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="prefix"/>
                            </div>                        
                        </div>
                        <div className="cbit-row">
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="firstName">First Name {" "}
                                        <span className="required-field">*</span>
                                    </label>
                                </div>
                                <div className="cbit-column">
                                    <label className='form-label' htmlFor="lastName">Last Name {" "}
                                        <span className="required-field">*</span>
                                    </label>
                                </div>
                        </div>
                        <div className="cbit-row cbit-form-group">
                                <div className="cbit-column">
                                    <Field 
                                        id="firstName"
                                        name="firstName" 
                                        className="form-input form-control"
                                        type="text"
                                        placeholder="First Name"
                                        disabled={isLoading}
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="firstName"/>
                                    </div>
                                </div>
                                <div className="cbit-column">
                                    <Field 
                                        id="lastName"
                                        name="lastName" 
                                        className="form-input form-control"
                                        type="text"
                                        placeholder="Last Name"
                                        disabled={isLoading}
                                    />
                                    <div className="error-message-container">
                                        <ErrorMessage name="lastName"/>
                                    </div>
                                </div>
                            </div>
                        <div className="cbit-form-group">
                            <div>
                                <label className='form-label' htmlFor="email">Email{" "}
                                    <span className="required-field">*</span>
                                </label>
                            </div>
                            <Field
                                type="text"
                                className="form-input" 
                                placeholder="Email"
                                id="email"
                                name="email"
                                disabled={isLoading}
                            />
                            <div className="error-message-container">
                                <ErrorMessage name="email"/>
                            </div>
                        </div>
                        <div className="cbit-form-group">
                            <div>
                                <label className='form-label' htmlFor="description">Description</label>
                            </div>
                            <Field                                
                                className={`form-input my-profile-description ${displayMaxCharDescError(values.description)}`} 
                                placeholder="Bio"
                                id="description"
                                name="description"
                                component="textarea"
                                disabled={isLoading}
                            />
                            <div className="description-error">
                                <div className="error-message-container">
                                    <ErrorMessage name="description"/>
                                </div>
                                <CharacterLimit 
                                    maxCharLimit={MaxCharactersForDescription} 
                                    characters={values.description} 
                                />
                            </div>
                        </div>
                        <div className="my-profile-btn-container">
                            <button 
                                disabled={isLoading} 
                                type="button"
                                className="btn-cbit-minor"
                                onClick={toggleEditing}
                            >
                                    Discard Changes
                            </button>
                            <button
                                disabled={isLoading} 
                                type="submit" 
                                className="btn-cbit-primary"
                            >
                                Update
                            </button>
                        </div>
                    </Form>
                )}
            </Formik>                                
        </div>
        </div>
    </div> 
    )
}

export default MyProfileForm;
