import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";
import { IModule } from "../models/module/Module";

export class ModuleService extends BaseReactService {
    constructor(){
        super("Module/");
    }
    
    public async GetModuleDepthById(id:{id:string}){
        const endpoint = 'Module/GetModuleDepthById/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            // ErrorHandler.catchApiError(error)
        }
    }
    
    public async GetModulesWithParentId(id: { id:string }){
        const endpoint = 'Module/WithParentId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            // ErrorHandler.catchApiError(error)
        }
    }

    public async GetModulesForTreeViewParentModuleId(id: { id:string }){
        const endpoint = 'UserModule/GetAssignedModulesForUserByParentModuleId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async GetDeletedModules()
    {
        const endPoint = 'Module/GetDeleted';
        try {
            return await this.GetAll(endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetAvailableModulesByParentModule(id:{id:string})
    {
        const endPoint = 'Module/GetAvailableModulesByParentModule';
        try {
            return await this.Get(id,endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }   
    }

    /**
     * Checks if a module differs from its published version
     * @param id 
     */
    public async CheckModuleAndPublishedModuleChanges(id:string) {
        const endPoint = 'Module/CheckModuleAndPublishedModuleChanges/';
        try {
            return await this.Get({id}, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Creates a new course and assign it to all users          
     */
    public async CreateNewCourse(module:IModule) {
        const endPoint = 'Module/CreateNewCourse/';
        try {
            return await this.Post(module, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async UpdateCourse(module:IModule) {
        const endPoint = 'Module/UpdateCourse';
        try {
           return await this.CustomPatch(module, endPoint); 
        } catch (error) {
            ErrorHandler.catchApiError(error as  AxiosError);
        }
    }

    /**
     * Updates the last modified field on a module
     * @param id 
     * @returns 
     */
    public async UpdateLastModified(id:{id:string}) {
        const endPoint = 'Module/LastModifiedUpdate/';
        try {
            return await this.CustomPatch(id, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as  AxiosError);
        }
    }

    /**
     * Softly deletes a module by updating the deletedAt property
     * @param id 
     * @returns 
     */
    public async DeleteModuleSoftly(id: {id:string}){
        const endpoint = 'Module/DeleteSoftly/';
        try {
            return await this.Patch(id, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Restores a softly deleted module
     * @param id 
     * @returns 
     */
    public async RestoreDeletedModule(id:{id:string}){
        const endpoint = 'Module/RestoreDeleted/';
        try {
            return await this.Patch(id, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
}