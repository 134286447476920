

export class idObject{
    id = '';
}
export class _idObject{
    _id = '';
}
export class TagTransferObject{
    tags: _idObject[]=[];
}

export interface IAdditionalVideoContent {
    id: string;
    name : string;
    videoLink : string;
    videoLength : string;
    description:string;
    tagList : _idObject[];
    lessonIdList:_idObject[];
}

export class AdditionalVideoContent implements IAdditionalVideoContent {
    id: string;
    name : string;
    videoLink : string;
    videoLength : string;
    description: string;
    tagList : _idObject[] = [];
    lessonIdList: _idObject[];
    
    constructor( data: IAdditionalVideoContent)
    {
            this.id = data.id,
            this.name = data.name,
            this.videoLength = data.videoLength,
            this.videoLink = data.videoLink,
            this.description = data.description
            this.tagList = data.tagList,
            this.lessonIdList = data.lessonIdList
    }

}