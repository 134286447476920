import { useEffect, useRef, useState } from "react"
import { _idObject } from "../../../../../models/AdditionalVideoContent/AdditionalVideoContent"
import { ILesson } from "../../../../../models/lesson/Lesson"
import { ILessonContent } from "../../../../../models/lesson/LessonContent"
import { EditorContentEnum } from "../../../../../models/utility/EditorContentEnum"
import { DeepCopy } from "../../../../../models/utility/DeepCopy"
import { IAdminModule } from "../../../../../models/module/AdminModule"
import { LessonContentType } from "../../../../../models/contentTypes/LessonContentType"
import IconCaretDown from '../../../../../resources/icons/icon-caret-down.png';
import IconCaretLeft from '../../../../../resources/icons/icon-caret-left.png';
import QuizIconSrc from '../../../../../resources/icons/icon-quiz.png';
import TextIconSrc from '../../../../../resources/icons/icon-reading.png';
import VideoIconSrc from '../../../../../resources/icons/icon-video.png';
import DragGripSrc from '../../../../../resources/icons/icon-drag-drop.png';
import EditIconSrc from '../../../../../resources/icons/icon-edit.png';
import TrashIconSrc from '../../../../../resources/icons/icon-trash.png';
import EyeClosedIconSrc from '../../../../../resources/icons/icon-eye-closed.png';
import EyeOpenIconSrc from '../../../../../resources/icons/icon-eye-open.png';
import React from "react";
import MultipleChoiceForm from "../../../AdminForms/QuizForms/MultipleChoiceForm";
import TrueOrFalseForm from "../../../AdminForms/QuizForms/TrueOrFalseForm";
import TextForm from "../../../AdminForms/TextForms/TextForm";
import VideoForm from "../../../AdminForms/VideoForm";
import { Draggable } from "react-beautiful-dnd";
import CustomModal from "../../../../Utilities/CustomModal";
import AdminContentItemViewer from "../AdminContentItemViewer/AdminContentItemView";
import LessonIconRender from "../LessonIconRender";
import "./LessonContentItem.css";

type LessonContentItemProps = {
    index:number
    lesson?:ILesson
    content:ILessonContent    
    deleteItem: () => void       
    onSubmit: (content:any, duration?:string, tagList?:_idObject[]) => void
    setIsVisible?: (isVisible:boolean) => void
    isVisible?:boolean
    parentsVisibility:boolean
    isExpanded:boolean
    isAllExpanded:boolean
    causeRerender:boolean
    draggableId:string
    onDeleteConfirmSubmit:any    
    imageFile?:File
    contentType?:EditorContentEnum
    tagId?:_idObject
}

type LessonContentItemState = {    
    lessonContent: ILessonContent    
    isModalOpen: boolean   
    isViewable: boolean 
    isExpanded: boolean   
    contentDuration:string
}

export default function LessonContentItem(props:LessonContentItemProps) {
    const {
        content,
        index,
        isVisible,        
        isAllExpanded,
        causeRerender,
        imageFile,
        contentType,
        lesson,
        draggableId,
        parentsVisibility,
        onSubmit,   
        deleteItem, 
        onDeleteConfirmSubmit,
    } = props;

    const isFirstRender = useRef<boolean>(true);

    const [state, setState] = useState<LessonContentItemState>({
        lessonContent: content,        
        isModalOpen: false,
        isViewable:isVisible ?? false,
        isExpanded:false,
        contentDuration: '00:00',        
    });

    useEffect(() => {
        setState(prevState => ({
            ...prevState,
            isExpanded:isAllExpanded
        }));
    }, [isAllExpanded]);

    useEffect(() => {
        if(!isFirstRender.current) {
            setState(prevState => ({
                ...prevState,
                isViewable:parentsVisibility
            }));
        } else {
            isFirstRender.current = false;
        }

    }, [parentsVisibility]);

    /**
     * Get the duration of the lesson content
     * @returns 
     */
    const getLessonContentDuration = () => {
        if(contentType) {
            contentType === EditorContentEnum.lessonQuiz || contentType === EditorContentEnum.lesson;
            return ""
        }

        if(lesson) {
            return lesson.duration
        }

        if(content.videoProperties) {
            return content.videoProperties.videoLength
        }

        return state.contentDuration
    }

    /**
     * Toggles isExpanded open or close
     * @returns 
     */
    const toggleContent = () => {
        const isExpanded = !state.isExpanded;
        setState(prevState => ({
            ...prevState,
            isExpanded
        }));
    }

    /**
     * Toggles the modal to be open or closed
     */
    const toggleModal = () => {
        const {isModalOpen} = state;
        setState(prevState => ({
            ...prevState,
            isModalOpen: !isModalOpen
        }));
    }
        
    /**
     * Updates the quiz questions types (multi choice and true or false)
     * @param lessonContent 
     */
    const onEditSubmit = async (lessonContent: ILessonContent, duration?:string, imageFile?:File, tagList?:_idObject[]) => {
        
       const newAdminCourseItem : IAdminModule = {
           key: draggableId,
           content: lessonContent,
           file:imageFile,
           tagList
       }                               

       onSubmit(newAdminCourseItem, duration, tagList);             
    }
    

    /**
     * Checks to see if the content type is a type of question
     */
    const IsQuestionContent = () => {
        const {content} = props;

        const contentType = content.type;
        
        if(contentType === LessonContentType.multipleChoice || contentType === LessonContentType.trueOrFalse){
            return true;
        } else {
            return false;
        }
    }

    /**
     * Get the lesson content question number for displaying
     */
    const GetQuestionNumber = () => {  
        //If not a question type content return undefined
        if(!IsQuestionContent()){
            return;
        }

        const {content} = props;

        return content.multipleChoiceProperties?.questionNumber;
    }

    const handleVisibilityClick = () => { 
        const {isViewable} = state;
        const toggledViewable = !isViewable;
        setState(prevState => ({
            ...prevState,
            isViewable:toggledViewable
        }));

        if(props.setIsVisible) {
            props.setIsVisible(toggledViewable);
        }

        // if(props.setIsVisible)
        //     return props.setIsVisible;
        
        // return setIsViewable;
    }

    const handleIsVisible = () => {
        if(props.isVisible != undefined)
            return props.isVisible
        
        return state.isViewable
    }

    /**
     * Because lesson content and quiz items should not have visibility toggle
     */
    const showVisibility = () => {
        return props.setIsVisible != null
    }

    const expandedClass = () => (state.isExpanded ? "content-item-expanded" : "");

    
    /**
    * Renders the correct icon for the content type
    */
    const renderContentTypeIcon = () => {    
        switch (content.type) {
            case LessonContentType.multipleChoice:
                return <img src={QuizIconSrc} alt="Quiz Icon" />

            case LessonContentType.trueOrFalse:
                return <img src={QuizIconSrc} alt="Quiz Icon" />

            case LessonContentType.text:
                return <img src={TextIconSrc} alt="Text Icon" />

            default:
                return <img src={VideoIconSrc} alt="Video Icon" />
        }
    }

    /**
     * Returns the proper form based on the content type
     * @returns 
     */
    const renderForm = () => {                           
        switch(content.type){
            case LessonContentType.multipleChoice:
                return React.createElement(
                    MultipleChoiceForm,{
                        onSubmit: onEditSubmit, 
                        content: content, 
                        question: content.multipleChoiceProperties,
                        isEditing: true,                                  
                        toggleModal: toggleModal,
                    });
            case LessonContentType.trueOrFalse:
                return React.createElement(
                    TrueOrFalseForm, {
                        onSubmit: onEditSubmit, 
                        content: content, 
                        question: content.multipleChoiceProperties,
                        isEditing: true,                                  
                        toggleModal: toggleModal,
                });
            case LessonContentType.video:
                return React.createElement(
                    VideoForm, {
                        content: content, 
                        video: content.videoProperties,
                        isEditing: true,
                        onSubmit: onEditSubmit,
                        toggleModal: toggleModal,
                        tagId: props.tagId,
                        isInsideLessonCombo:props.contentType ? props.contentType === EditorContentEnum.lesson : undefined
                });
            case LessonContentType.text:
                return React.createElement(
                    TextForm, {
                        lesson:props.lesson,
                        content: content, 
                        textContent: content.textProperties,
                        isEditing: true,
                        onSubmit: onEditSubmit,
                        toggleModal: toggleModal,
                        tagId: props.tagId,
                        isInsideLesson:props.contentType ? props.contentType === EditorContentEnum.lesson : undefined
                        
                });         
        }
    }    
    
    return (
        <>
            <Draggable
                key={draggableId}
                draggableId={draggableId}
                index={index}
            >
                {(provided, snapshot) => (
                    <div className={`content-item lesson-content-item ${expandedClass()} ${snapshot.isDragging ? 'content-item-dragging' : ''}`} 
                        ref={provided.innerRef} 
                        {...provided.draggableProps} 
                    >                        
                            <div 
                                className="drag-handle"  
                                {...provided.dragHandleProps}
                            >
                                <img src={DragGripSrc} alt="Drag Grip" />              
                            </div>
                            {
                                lesson ? 
                                (
                                    <div className="content-item-type">
                                        <LessonIconRender 
                                            lessonIcon={lesson.lessonIcon}
                                        />
                                    </div>
                                ) 
                                : 
                                (
                                    <div className='content-item-type'>
                                        {renderContentTypeIcon()}
                                    </div> 
                                )
                            }

                            <div className="content-item-title lesson-course-item-title">
                                {

                                    IsQuestionContent() && (
                                        <div className="admin-question-number">
                                            {GetQuestionNumber() + "."}
                                        </div>      
                                        )
                                }
                                <div>
                                    {
                                        content.name
                                    }
                                </div>
                            </div>
                                                            
                            <ul className="content-item-options"> 
                                <li className={`lesson-content-caret-${state.isExpanded ? "down" : "left"}`} >
                                    <div onClick={toggleContent}> 
                                    {
                                        state.isExpanded ? 
                                        (
                                            <img src={IconCaretDown} alt="caret down"  />
                                            
                                        )
                                        :
                                        (                                                    
                                            <img src={IconCaretLeft} alt="caret left"  />                       
                                        )
                                    }
                                    </div>
                                </li>
                                <li onClick={() => onDeleteConfirmSubmit(content.name,deleteItem)}>
                                    <div className="content-action-icon">
                                        <img src={TrashIconSrc} alt="Trash Button" />
                                    </div>
                                </li>
                            
                                <li onClick={() => toggleModal()}>
                                    <div className="content-action-icon">
                                        <img src={EditIconSrc} alt="Edit Button" />
                                    </div>
                                </li>                                    
                                {
                                    showVisibility() && (

                                        <li onClick={handleVisibilityClick}>
                                        <div className="content-action-icon-visibility">
                                        {
                                            state.isViewable ? 
                                            (
                                                <img src={EyeOpenIconSrc} alt="Visibility Button" />                                                    
                                            ) 
                                            : 
                                            (                                                                                                                
                                                <img src={EyeClosedIconSrc} alt="Visibility Button" />
                                            )
                                        }
                                        </div>
                                    </li>
                                    )
                                }
                                {!IsQuestionContent() && (

                                    <li>
                                        <span className='lesson-content-duration'>
                                            {getLessonContentDuration()}
                                        </span> 
                                    </li>                                  
                                )}
                            </ul>      
                        {
                            state.isExpanded && (
                                <>
                                    <div className="flex-break"></div>                                                                                          
                                        {
                                            <AdminContentItemViewer 
                                            imageFile={imageFile}
                                            lessonContent={content}                                            
                                            />
                                        }                                        
                                </>
                            )
                        }
                    </div>
                )}
            </Draggable>
            <CustomModal isOpen={state.isModalOpen} toggle={toggleModal}>
                    {renderForm()}
            </CustomModal>
        </>
    )
            
}