import * as React from 'react';
import { LessonContentController } from '../../../../controllers/LessonContentController';
import { LessonController } from '../../../../controllers/LessonController';
import { _idObject } from '../../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { ILesson } from '../../../../models/lesson/Lesson';
import { ILessonContent } from '../../../../models/lesson/LessonContent';
import { IAdminModule } from '../../../../models/module/AdminModule';
// import LessonContentItem from './LessonContentItem';
import LessonContentItem from './LessonContentItem/LessonContentItem';
import { ContentItemState } from './ModuleItem';
import './ContentItem.css' ;
import './LessonItem.css';

type LessonItemProps =  
    {   
        lesson: ILesson
        draggableId: string
        onDeleteConfirmSubmit:any
        updateScrollIsVisible:(isVisible: boolean) => void        
        index:number            
        deleteItem: () => void
        onSubmit: (content:any, lessonContent?:any) => void
        isVisible:boolean
        isExpanded:boolean
        parentsVisibility:boolean
        causeRerender:boolean
        tagList:_idObject[]
        imageFile?:File
    }; 

type LessonItemState = ContentItemState &
    {
        lessonContent: ILessonContent[];        
        isModalOpen: boolean;
        // isVisible:boolean;
    }

/**
 * we need to show its lesson content by going through the actually lesson first to get it. We have to do this because lesson content cannot be
 * inside modules/chapters only lessons and lesson groups can. So we are rendering the lesson content through the lesson
 * So the name that is displayed comes from the lesson content name
 */
class LessonItem extends React.Component<LessonItemProps, LessonItemState> {    
    private LessContentController = new LessonContentController();

    // Default state of the ContentModuleItem
    private defaultState : LessonItemState = {
        isEditing: false,        
        isVisible: this.props.isVisible,
        lessonContent: [],
        isModalOpen: false,
    }

    constructor(props:any) {
        super(props);
        this.state = this.defaultState;
    }

    async componentDidMount(){
        const {lesson} = this.props;                

        //TODO check if inside lesson or module
        
        let lessonContent : ILessonContent[] = lesson.lessonContent ? lesson.lessonContent : [];

        if(lessonContent.length <= 0)
            lessonContent = await this.LessContentController.GetAllLessonContentByLessonId(lesson.id);
        
        //TODO check lessonContent length

        this.setState(prevState => ({
            ...prevState,
            lessonContent,            
        }));

        // this.checkIfScrollPresent();
    }


    private updateVisibility = (visibility:boolean) => {
        const newLesson = {...this.props.lesson};

        newLesson.isViewable = visibility;
        
        const adminContentItem = {
            key:this.props.draggableId,
            content: newLesson
        }                

        this.props.onSubmit(adminContentItem);     
    }

    /**
     * Updates the video/text lesson types to match their updated lesson content
     * @param lessonContent 
     */
    private onEditSubmit = async (adminContentItem: IAdminModule, duration?:string, tagList?:_idObject[]) => {
        const {content} = adminContentItem;
        
        const lessonContent = {...content} as ILessonContent;                

        this.setState(prevState => ({
            ...prevState,
            lessonContent: [lessonContent]
        }));

        const lesson = {...this.props.lesson};

        lesson.name = lessonContent.name;
        lesson.description = lessonContent.description; 
        lesson.duration = duration ? duration : "00:00";              
        lesson.lessonContent = [lessonContent];
        lesson.tagList = tagList ? tagList : [];        
        
        const newAdminContentItem : IAdminModule = {
            key: this.props.draggableId,
            content: lesson,
            file: adminContentItem.file
        }                        

        this.props.onSubmit(newAdminContentItem);             
    }

    public render() {
        const {index, deleteItem, lesson, draggableId, onDeleteConfirmSubmit, isExpanded, causeRerender, imageFile} = this.props;
        const {isModalOpen, lessonContent} = this.state;

        return (
            <>
                {!lessonContent[0] ? 
                (
                    ""
                ) 
                : 
                (
                    <LessonContentItem 
                        isAllExpanded={isExpanded}
                        causeRerender={causeRerender}
                        isExpanded={isExpanded}
                        index={index} 
                        lesson={lesson}
                        content={this.state.lessonContent[0]} 
                        deleteItem={deleteItem} 
                        onSubmit={this.onEditSubmit} 
                        setIsVisible={this.updateVisibility} 
                        isVisible={lesson.isViewable} 
                        parentsVisibility={this.props.parentsVisibility}
                        draggableId={draggableId} 
                        onDeleteConfirmSubmit={onDeleteConfirmSubmit}      
                        imageFile={imageFile}    
                        tagId={this.props.tagList[0]}          
                    /> 
                )}
            </>
        )
    }

}

export default LessonItem;