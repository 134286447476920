import {UserModuleService} from "../services/UserModuleService";
import {
    convertToPartialModuleEnum,
    PartialModuleTreeModel,
    TreeViewType
} from "../models/partialModels/PartialModuleTreeModel";
import {AxiosError} from "axios";
import {UserModule, UserModuleCreateType} from "../models/module/UserModule";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import {UserLesson} from "../models/lesson/UserLesson";
import {LessonTypeEnum} from "../models/lesson/LessonType";

export class UserModuleController {
    constructor()
    {
        this.userModuleService = new UserModuleService();
    }
    
    private userModuleService:UserModuleService;
    
    public async Get(id:string):Promise<UserModule>
    {
        try
        {
            const userModule = await this.userModuleService.Get({id});
            return userModule;
        } catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    
    public async GetUpdatedFlags(uModule:PartialModuleTreeModel ,id:string){
        try{
            const userModule =await this.userModuleService.Get({id});
            uModule.isDisabled = userModule.isDisabled;
            uModule.isNext = userModule.isNext;
            uModule.isViewable = userModule.isViewable;
            uModule.isEditable = userModule.isEditable;
            uModule.isOptional = userModule.isOptional;
            uModule.isComplete = userModule.isComplete;
            uModule.isOpen = userModule.isOpen;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        } 
    }

    //This returns completd lesson is original parent is pulled
    public async GetAssignedPartialUserModulesByParentId(id:string): Promise<PartialModuleTreeModel[]>{
        try{
            const response =await this.userModuleService.GetAssignedModulesForUserByParentModuleId({id});
            return response;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async DoesUserHaveModules(id:string): Promise<boolean>{
        try{
            const response =await this.userModuleService.DoesUserHaveModules({id});
            return response;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    public async InitializeUserDataForParentId(id:string): Promise<boolean>{
        try{
            const response =await this.userModuleService.InitializeUserDataForParentId({id});
            return response;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }



    public async GetAssignedSection(id:string){
        try{
            const userSection =await this.userModuleService.GetAssignedSection({id});
            
            return userSection;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async GetNextUserLessonId(userLessonId: string){
        let data={
            userLessonId: userLessonId,
        }
        try{
            return await this.userModuleService.GetNextUserLesson(data);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async UpdateUserLessonCompleteness(userLessonId: string, nextUserLessonId:string, courseId:string){
        let data={
            userLessonId: userLessonId,
            nextUserLessonId: nextUserLessonId,
            courseId
        }
        try{
            return await this.userModuleService.UpdateUserLessonCompleteness(data);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));

        }
    }

    /**
     * Admin only function to update all users course content
     * @param topMostParentId 
     */
    public async UpdateAllUsersCourse(sectionId:string) {
        try {
            return await this.userModuleService.UpdateAllUsersCourse(sectionId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Update a single user's course content
     * @param userId 
     * @param assignedSectionId 
     * @returns 
     */
    public async UpdateUserCourse(userId:string, sectionId:string) {
        try {
            return await this.userModuleService.UpdateUserCourse(userId, sectionId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async UnlockAllLessonsForUser(userId:string, sectionId:string) {
        let data={
            userId,
            sectionId
        }
        try {
            return await this.userModuleService.UnlockAllLessonsForUser(data);
        } catch(error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async DeleteUserModule(id:string){
        
    }
}