import React, {FunctionComponent, useEffect, useState} from "react";
import {UserLesson} from "../../../../models/lesson/UserLesson";
import {DefaultLesson, ILesson, Lesson} from "../../../../models/lesson/Lesson";
import {PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import {UserModuleController} from "../../../../controllers/UserModuleController";
import {UserLessonContentLogController} from "../../../../controllers/UserLessonContentLogController";
import {UserLessonController} from "../../../../controllers/UserLessonController";
import ReactPlayer from "react-player/lazy";
import {UserLessonContent} from "../../../../models/lesson/UserLessonContent";
import {UserLessonContentController} from "../../../../controllers/UserLessonContentController";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { CurrentLastLessonState } from "../../../../store/LastLessonStore";
import { AdminTreeHelper } from "../../../../models/utility/TreeHelper/AdminTreeHelper";

type videoProps = {
    parentModule: PartialModuleTreeModel,
    selectedUserLesson: UserLesson,
    updateAccordionFunction: any,
    toggleCourseCompletion:() => void,
}

const VideoLesson: FunctionComponent<videoProps> = (props) => {

    const defaultContent:UserLessonContent[] =[]

    //State Data
    const [UserLessonContent, setUserLessonContent] = useState(defaultContent)
    const [Lesson, setLesson] =useState(DefaultLesson);
    const [VideoLessonContent, setVideoLessonContent] = useState();

    const [complete, setComplete] = useState(props.selectedUserLesson.isComplete);
    const [dataLoaded, setDataLoaded] = useState(false);
    const lastLessonStore = useSelector<ApplicationState, CurrentLastLessonState | undefined>(state => state.currentLastLesson);
    const lastLesson:ILesson | null = lastLessonStore ? lastLessonStore.lastLesson : null;


    const userLessonContentController: UserLessonContentController = new UserLessonContentController();
    const userLessonController: UserLessonController = new UserLessonController();
    const userModuleController: UserModuleController = new UserModuleController();
    const userLessonContentLog: UserLessonContentLogController = new UserLessonContentLogController();

    //Inital Configuration
    useEffect(() => {
        const setInitalData = async (setUserContent: any, setL:any, setContent:any, setData:any, setCompleteness:any) => {

            setUserContent(props.selectedUserLesson.lesson.userLessonContent);
            setL(props.selectedUserLesson.lesson);
            setCompleteness(props.selectedUserLesson.isComplete);

            if (props.selectedUserLesson.lesson.lessonContent != null) {
                setContent(props.selectedUserLesson.lesson.lessonContent[0].videoProperties)
            }
            setData(true);
        }
        setInitalData(setUserLessonContent,setLesson,setVideoLessonContent, setDataLoaded,setComplete);
                
    }, [props.selectedUserLesson])

    async function navigateToNextLesson()
    {
        let parent = props.parentModule;
        let nextUserPartial = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);

        props.updateAccordionFunction({
            parentModule: parent,
            selectedModule: nextUserPartial,
            nextLessonId: nextUserPartial.id,
            selectedContent: UserLessonContent,
            isUpdating: true,
        });
    }

    

    async function videoHasEnded(){
        //Video has ended
        await userLessonContentLog.PostLog({
            Choice: "User Completed Video",
            IsCompleted: "true",
            LessonContentId: UserLessonContent[0].id,
            CorrectAnswer: 'Completed Video',
            IsCorrect: "true",
            LessonId: Lesson.id,
            Timestamp: ""
        })
        
        //Obtain the next user Lesson in the list
        let nextUserPartial = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);
                
        if(nextUserPartial !== null)
        {
            //UserLessonContent - Get First In the Array ( for Video )
            let videoContentToUpdate = UserLessonContent[0];
            videoContentToUpdate.isComplete = true;
            videoContentToUpdate.sectionId = props.parentModule.originalDocumentId;
            await userLessonContentController.UpdateUserLessonContent(videoContentToUpdate);
            
            let isUserLessonComplete = await userModuleController.UpdateUserLessonCompleteness(props.selectedUserLesson.id, nextUserPartial.id, props.parentModule.originalDocumentId);            
            
            if(isUserLessonComplete)
            {
                setComplete(true);
                let parent = props.parentModule;
                AdminTreeHelper.unMarkIsNext(parent);

                //Update the Parent in the accordion
                parent?.children?.forEach(firstChild => {
                    //look for next

                    if (firstChild.id == nextUserPartial.id) {
                        //Set Next
                        firstChild.isNext = true;
                        firstChild.isDisabled = false;
                        firstChild.isLocked = false;
                    }
                    if (firstChild.id == props.selectedUserLesson.id) {
                        //Unset Next
                        firstChild.isNext = false;
                        firstChild.isComplete = true;
                        firstChild.isLocked = false;
                        firstChild.isDisabled = false;
                    }
                    if (firstChild.children.length > 0) {
                        firstChild.children.forEach(secondChild => {
                            if (secondChild.id == nextUserPartial.id) {
                                secondChild.isNext = true;
                                secondChild.isDisabled = false;
                                secondChild.isLocked = false;
                            }
                            if (secondChild.id == props.selectedUserLesson.id) {
                                secondChild.isNext = false;
                                secondChild.isComplete = true;
                                secondChild.isDisabled = false;
                                secondChild.isLocked = false;
                            }
                            if(secondChild.children.length >0){
                                secondChild.children.forEach(thirdChild =>{
                                    if (thirdChild.id == nextUserPartial.id) {
                                        thirdChild.isNext = true;
                                        thirdChild.isDisabled = false;
                                        thirdChild.isLocked = false;
                                    }
                                    if (thirdChild.id == props.selectedUserLesson.id) {
                                        thirdChild.isNext = false;
                                        thirdChild.isComplete = true;
                                        thirdChild.isDisabled = false;
                                        thirdChild.isLocked = false;
                                    }
                                })
                            }
                        })
                    }
                })

                props.updateAccordionFunction({
                    parentModule:parent,
                    selectedModule: props.selectedUserLesson,
                    nextLessonId: nextUserPartial.id,
                    selectedContent: UserLessonContent,
                    isUpdating:true,

                })                           
                if(lastLesson && lastLesson.id === props.selectedUserLesson.lesson.id) {
                    props.toggleCourseCompletion();
                }
            }

            if(lastLesson && lastLesson.id === props.selectedUserLesson.lesson.id) {
                props.toggleCourseCompletion();
            }
        }
    }



    //Render
    return (
        <>
            <div>
                {dataLoaded && (VideoLessonContent != null) ?(
                    <>
                        <div className="video-content-panel">
                            <div className="video-player-background" key={Lesson.id}>
                                    {/*//@ts-ignore*/}
                                    <ReactPlayer url={VideoLessonContent.videoLink}
                                        controls={true}
                                        width={'100%'}
                                        height={'100%'}
                                        pip={false}
                                        onEnded={videoHasEnded}/>
                            </div>
                            <div>
                                <div className='video-content-panel-header'>
                                    <div className='video-text-title'>
                                        {/*//@ts-ignore*/}
                                        {VideoLessonContent.videoName}
                                    </div>
                                    {
                                        lastLesson && lastLesson.id == props.selectedUserLesson.originalDocumentId ? 
                                        (
                                            <></>
                                        )
                                        :
                                        (
                                            <div className='Left-Buffer-24 float-right '>
                                                {complete ?(
                                                    <>
                                                        <button onClick={()=> navigateToNextLesson()}
                                                            className='btn-cbit btn-primary '>NEXT LESSON
                                                        </button>
                                                    </>
                                                ):(
                                                    <>
                                                        <button onClick={()=>{}}
                                                            className='btn-cbit btn-disabled'>NEXT LESSON
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        )
                                    }
                                </div>
                                <div>
                                    <div className='video-text-offset'>
                                        <div className='video-text-body'>
                                            {/*//@ts-ignore*/}
                                            {VideoLessonContent.videoDescription}
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </>
                ):(
                    <>
                        Data Is Loading
                    </>
                )}
            </div>
        </>
    )

}
export default VideoLesson