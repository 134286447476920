import { AdditionalContentEnum } from "../Enums/AdditionalContentEnum";

export class idObject{
    id = '';
}
export class _idObject{
    _id = '';
}
export class TagTransferObject{
    tags: _idObject[]=[];
}

export class Subcategory { 
    _id:string = '';
    order:number = 0;
}

export interface IAdditionalContent {
    id: string;
    name : string;
    videoLink : string;
    videoLength : string;
    description:string;
    type:AdditionalContentEnum
    tagList : Subcategory[];
    lessonIdList:_idObject[];
    artifactId:string;
    thumbnailUrl:string;
    thumbnailUrlWithPlayButton:string;
}
export class AdditionalContent implements IAdditionalContent {
    id: string;
    name : string;
    videoLink : string;
    videoLength : string;
    description: string;
    type:AdditionalContentEnum
    tagList : Subcategory[] = [];
    lessonIdList: _idObject[];
    artifactId:string = "";
    thumbnailUrl: string = "";
    thumbnailUrlWithPlayButton: string = "";

    constructor( data: IAdditionalContent)
    {
            this.id = data.id,
            this.name = data.name,
            this.videoLength = data.videoLength,
            this.videoLink = data.videoLink,
            this.description = data.description,
            this.type = data.type,
            this.tagList = data.tagList,
            this.lessonIdList = data.lessonIdList
            this.artifactId = data.artifactId
    }

}

/**
 * Additional content grouped by 
 */
export class GroupedAdditionalContent {
    tagId:string = "";
    content:IAdditionalContent[] = [];
}