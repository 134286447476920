import React, {FunctionComponent, useEffect, useState} from "react";
import ReactPlayer from "react-player";
import {UserLessonContentLogController} from "../../../../../controllers/UserLessonContentLogController";
import {UserLessonContentController} from "../../../../../controllers/UserLessonContentController";
import {UserLessonContent} from "../../../../../models/lesson/UserLessonContent";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { CurrentUserAccordionState } from "../../../../../store/UserModuleAccordionStore";

type VideoContentProps={
    userLessonContent:UserLessonContent|null
    completeCallback:any,
}

const ContentViewerVideoContent:FunctionComponent<VideoContentProps>=({userLessonContent,  completeCallback})=> {

    const [state, setState] = useState(new UserLessonContent())
    const [videoLoaded, setVideoLoaded] = useState(false);
    const userLessonContentLogController: UserLessonContentLogController = new UserLessonContentLogController();
    const userLessonContentController :UserLessonContentController = new UserLessonContentController()    
    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);

    const Compelted=()=>{
        //LogCompletetion
        userLessonContentLogController.PostLog(
            {
                Choice:"Completed Video ( "+state.lessonContent.videoProperties?.videoLink+" )",
                IsCompleted:"true",
                LessonContentId:state.lessonContent.id,
                CorrectAnswer:'Completed Video',
                IsCorrect:"true",
                LessonId:state.lessonContent.lessonId,
                Timestamp:""
            })

        let updatedState = state;
        updatedState.isComplete = true;
        updatedState.isCorrect = true;
        if(accordionStore) {
            updatedState.sectionId = accordionStore.parentModule.originalDocumentId
        }
        setState(updatedState);
        userLessonContentController.UpdateUserLessonContent(updatedState)

        completeCallback();
    }

    useEffect(()=>{
        const initalSet = (setData:any, setVidLoad:any) =>{
            setData(userLessonContent)
            setVidLoad(true)
        }
        initalSet(setState, setVideoLoaded)
    }, [userLessonContent])


    return(
        <>
            {userLessonContent == null || state == null ?(
                <>
                </>
            ):(
                <div className="video-player-background" key={state.lessonContent.lessonId }>
                    {state.lessonContent.videoProperties?.videoLink != undefined ?(
                        <ReactPlayer url={state.lessonContent.videoProperties?.videoLink}
                            controls={true}
                            width={'100%'}
                            height={'100%'}
                            onEnded={()=>Compelted()}
                        />
                    ):(
                        <>
                            Video Loading...
                        </>
                    )}


                </div>
            )}

        </>
    )
}

export default ContentViewerVideoContent