import { v4 as uuidv4 } from 'uuid';
import { ISelectableChoice } from '../../../../../../models/contentTypes/SelectableChoice';

type quizItemProps = { 
    answerChoices: ISelectableChoice[]
}

const QuizViewItem = (props:quizItemProps) => {
    
    const {answerChoices} = props;

    return (
        <div className="admin-view-item">
            {answerChoices.map(choice => (
                <div key={uuidv4()}>
                    <input className='radio-btn-admin' checked={choice.isCorrect} disabled type='radio' />
                    <span className='admin-answer-choice-text'>{choice.text}</span>
                </div>
                
            ))}
        
        
       
        </div>
    )
}

export default QuizViewItem;