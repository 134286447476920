import React, { useEffect, useState } from 'react';
import ReactPlayer from 'react-player/vimeo';
import { ArtifactController } from '../../controllers/ArtifactController';
import { IAdditionalContent } from '../../models/AdditionalContent/AdditionalContent';
import { AdditionalContentEnum } from '../../models/Enums/AdditionalContentEnum';
import pdfIconSrc from '../../resources/icons/icon-reading.png';
import videoIconSrc from '../../resources/icons/icon-video.png';
import CustomModal from '../Utilities/CustomModal';
import ToolTipWrapper from '../Utilities/ToolTipWrapper/ToolTipWrapper';
import {useHistory} from "react-router-dom";
import {SiteLocation} from "../../models/Enums/SiteLocation";
import {ContentViewLogController} from "../../controllers/ContentViewLogController";


type TherapistContentItemProps = {
    additionalContent:IAdditionalContent
    subCategory:string
}

const artifactController = new ArtifactController();

const TherapistSupportContentItem = (props:TherapistContentItemProps) => {
    const {additionalContent} = props;
    const history = useHistory();
    const [isVideoModalOpen, setIsVideoModalOpen] = useState(false);

    const [pdfUrl, setPdfUrl] = useState("");

    const contentViewLogController = new ContentViewLogController();

    const UpdateViewCounter =async (name:string)=>{
        var response = await contentViewLogController.UpdateViewCount(SiteLocation.documentsAndFiles, name);
    }

    const toggleVideoModal = () => {
        //SET VIEW COUNTER
        if(!isVideoModalOpen){
            UpdateViewCounter(additionalContent.name);
        }

        setIsVideoModalOpen(!isVideoModalOpen);
    }

    useEffect(() => {
        function onComponentMount() {
            getFile();
        }
        onComponentMount();
    },[]);

    /**
     * Function for handling what to do when the content item itself have been clicked
     */
    const getFile = async () => {

        const urlToFile = await artifactController.GetPdfDownloadWithLog(additionalContent.artifactId, SiteLocation.documentsAndFiles);
        setPdfUrl(urlToFile);
    }


    const renderInnerContentItem = () => {
        return (
            <>
            <div className="therapist-item-details-container">
                <div className='therapist-content-title'>{additionalContent.name}</div>
                {/* <p className='therapist-content-description'>{additionalContent.description}</p> */}
            </div>
        </>
        )
    }

    return (
            <>
                <ToolTipWrapper toolTipText={additionalContent.description}>
                {
                    additionalContent.type === AdditionalContentEnum.video ?
                    (
                        <div onClick={() =>toggleVideoModal()}  className="therapist-content-item">
                            <div className="therapist-content-item-info">
                                <div className="therapist-icon-container">
                                    <img src={videoIconSrc} alt="Video Icon"/>
                                </div>
                                <div className="content-item-duration">{additionalContent.videoLength}</div>
                            </div>
                            {renderInnerContentItem()}
                        </div>

                    )
                    :
                    (
//                        <a href={pdfUrl} className='therapist-content-item therapist-pdf-item'>
                            <a href={pdfUrl} className='therapist-content-item therapist-pdf-item'>
                            <div className="therapist-content-item-info">
                                <div className="therapist-icon-container">
                                    <img src={pdfIconSrc} alt="PDF Icon"/>
                                </div>
                            </div>
                            {renderInnerContentItem()}
                        </a>
                    )
                }
                </ToolTipWrapper>
                <CustomModal
                    toggle={toggleVideoModal}
                    isOpen={isVideoModalOpen}
                    className="therapist-support-modal"
                    id="therapist-support-modal"
                >
                    <div className="therapist-player-wrapper">
                        <ReactPlayer
                            url={additionalContent.videoLink}
                            controls={true}
                            className="therapist-player"
                        />
                        <div className="therapist-player-title-container">
                            <p className="therapist-player-title">
                                {additionalContent.name}
                            </p>
                        </div>
                    </div>
                </CustomModal>
            </>
    )
}

export default TherapistSupportContentItem;
