import React, {FunctionComponent, useEffect, useState} from "react";
import {UserLessonContentLogController} from "../../../../../controllers/UserLessonContentLogController";
import {UserLessonContentController} from "../../../../../controllers/UserLessonContentController";
import {SelectableChoice, UserLessonContent} from "../../../../../models/lesson/UserLessonContent";
import {IMultipleChoiceContent} from "../../../../../models/contentTypes/MultipleChoiceContent";
import {ISelectableChoice} from "../../../../../models/contentTypes/SelectableChoice";
import {ILessonContent} from "../../../../../models/lesson/LessonContent";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../../store";
import { CurrentUserAccordionState } from "../../../../../store/UserModuleAccordionStore";

type contentProps = {
    completed: boolean
    userLessonContent: UserLessonContent | null
    completeCallback: any,
}

const ContentViewerMultipleAnswerContent: FunctionComponent<contentProps> = (props:contentProps) => {
                                                                    
    const {
        userLessonContent,
        completed,
        completeCallback
    }  = props;                                                                     
    
    const accordionStore = useSelector<ApplicationState, CurrentUserAccordionState | undefined>(state => state.currentUserModuleAccordion);                                                                           

    const [state, setState] = useState(new UserLessonContent())
    const [isLoading, setIsLoading] = useState(true);

    const userLessonContentLogController: UserLessonContentLogController = new UserLessonContentLogController();
    const userLessonContentController: UserLessonContentController = new UserLessonContentController()

    const AnswerChoiceSelected = (index: number, choice: string, isCorrect: boolean) => {

        //LogCompletetion
        userLessonContentLogController.PostLog(
            {
                Choice: "Selected Multiple Choice Answer ( " + choice + " )",
                IsCompleted: "true",
                LessonContentId: state.lessonContent.id,
                CorrectAnswer: 'Completed Multiple Choice Question',
                IsCorrect: isCorrect.toString(),
                LessonId: state.lessonContent.lessonId,
                Timestamp: ""
            })

        let updatedState = state;

        updatedState.isComplete = true;
        updatedState.isCorrect = isCorrect;
        let alreadySelected = false;

        if (updatedState.savedAnswer != null) {

            for (let i = 0; i < updatedState.savedAnswer.length; i++) {
                if (updatedState.savedAnswer[i].order == index) {
                    updatedState.savedAnswer.splice(i, 1);
                    alreadySelected = true;
                    break;
                }
            }
        } else {
            updatedState.savedAnswer = [];
        }

        if (!alreadySelected) {
            updatedState.savedAnswer.push({order: index, text: choice, isCorrect: isCorrect});
        }

        // if(accordionStore) {
        //     updatedState.sectionId = accordionStore.parentModule.originalDocumentId
        // }     

        setIsLoading(true);
        setState(updatedState);
        userLessonContentController.UpdateUserLessonContent(updatedState)
        completeCallback();
    }

    useEffect(() => {
        const initalSet = (setData: any, setLoading: any) => {
            if(accordionStore && userLessonContent != null) {
                userLessonContent.sectionId = accordionStore.parentModule.originalDocumentId
            }
            setData(userLessonContent)
            setLoading(false);
        }
        initalSet(setState, setIsLoading)

    })

    // useEffect(() => {
    //
    //
    // }, [isLoading])
    const HasChoiceBeenSelected = (content: SelectableChoice[], answerIndex: number) => {
        if (content == null)
            return false;

        for (let i = 0; i < content.length; i++) {
            if (content[i].order == answerIndex) {
                return true;
            }
        }

        return false;
    }


    return (
        <>
            {userLessonContent == null || state == null ? (
                <>
                </>
            ) : (
                <>

                    {completed ? (
                        <>
                            <div className="quiz-question-text">
                                <div className="quiz-question-title-seperation">
                                    <div className={'quiz-question-container'}>
                                        <span>
                                            {state.lessonContent.multipleChoiceProperties?.questionNumber}.
                                        </span>
                                        <span className="quiz-question-left-margin">
                                            {state.lessonContent.multipleChoiceProperties?.questionText}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {state.lessonContent.multipleChoiceProperties != null && (
                                        <>
                                            {state.lessonContent.multipleChoiceProperties?.choices.map((answer, answerIndex) => (

                                                <div key={answerIndex} className="quiz-question-seperation">
                                                    {HasChoiceBeenSelected(state?.savedAnswer, answerIndex) ? (
                                                        <>
                                                            {answer.isCorrect ? (
                                                                //MAKE GREEN
                                                                <div
                                                                    className='CorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-check-lg radioDesignationAddjustment"/>
                                                                        <input defaultChecked={true} disabled
                                                                            name={state.lessonContent.order + '_choice' + answerIndex}
                                                                            type='radio'/>
                                                                        <span
                                                                            className='radioTextAdjustment'>{answer.text}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                //MAKE RED
                                                                <div
                                                                    className='inCorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-x-lg radioDesignationAddjustment"/>
                                                                        <input defaultChecked={true} disabled
                                                                            name={state.lessonContent.order + '_choice' + answerIndex}
                                                                            type='radio'/>
                                                                        <span
                                                                            className='radioTextAdjustment'>{answer.text}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}

                                                        </>
                                                    ) : (
                                                        <>
                                                            {answer.isCorrect ? (
                                                                //Missed Correct Answer
                                                                <div
                                                                    className='CorrectRadioButtonText radioButtonAnswerAdjustment'>
                                                                    <div className={'quiz-question-container'}>
                                                                        <i className="bi bi-check-lg radioDesignationAddjustment"/>
                                                                        <input disabled
                                                                            name={state.lessonContent.order + '_choice' + answerIndex}
                                                                            type='radio'/>
                                                                        <span
                                                                            className='radioTextAdjustment'>{answer.text}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            ) : (
                                                                <div>
                                                                    <div className={'quiz-question-container'}>
                                                                        <input disabled
                                                                            name={state.lessonContent.order + '_choice' + answerIndex}
                                                                            type='radio'/>
                                                                        <span
                                                                            className='radioTextAdjustment'>{answer.text}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="quiz-question-text">
                                <div className="quiz-question-title-seperation">
                                    <div className="quiz-question-container">
                                        <span>
                                            {state.lessonContent.multipleChoiceProperties?.questionNumber}.
                                        </span>
                                        <span className="quiz-question-left-margin">
                                            {state.lessonContent.multipleChoiceProperties?.questionText}
                                        </span>
                                    </div>
                                </div>
                                <div>
                                    {state.lessonContent.multipleChoiceProperties != null && (
                                        <>
                                            {state.lessonContent.multipleChoiceProperties?.choices.map((answer, answerIndex) => (

                                                <div key={answerIndex} className="quiz-question-seperation">
                                                    <div className={'quiz-question-container'}>
                                                        <input
                                                            onClick={() => AnswerChoiceSelected(answerIndex, answer.text, answer.isCorrect)}
                                                            checked={HasChoiceBeenSelected(state?.savedAnswer, answerIndex)}
                                                            name={state.lessonContent.order + '_choice' + answerIndex}
                                                            type='radio'/>
                                                        <span className='radioTextAdjustment'>
                                                                {answer.text}
                                                            </span>
                                                    </div>
                                                </div>

                                            ))}
                                        </>
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                </>
            )}

        </>
    )
}

export default ContentViewerMultipleAnswerContent