import React, { useState } from "react";
import toast from "react-hot-toast";
import * as yup from "yup";
import { ContentViewLogController } from "../../../controllers/ContentViewLogController";
import TimeHelper from "../../../models/utility/TimeHelper";
import "./ContentLog.css";

const logController = new ContentViewLogController();

/**
 * Content log csv export for watchable content (therapist toolbox, etc)
 * remarks: They asked for a date picker and then told me to remove it
 */
const ContentLog = () => {
    const [inputData, setInputData] = useState<{from:string, to:string}>(
        {
            from: TimeHelper.FormatDateForInput(
                TimeHelper.createMomentDate(new Date()).startOf('month').toDate(), 
                false
            ), 
            to: TimeHelper.FormatDateForInput(new Date(), false)
        })

    const dateRangeSchema = yup.object({
        from:yup.date().required(),
        to:yup.date().required(),        
    });

    const getCsv = async (e:any) => {
        e.preventDefault();

        try {
            const content  = await logController.GetCSVUrl();
            
            window.open(content);
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to get csv file");
        }
    }

    /**
     * Updates state by input fields changing
     * @param e 
     */
    const onInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputVal = e.currentTarget.value;
        setInputData(prevState => ({
            ...prevState,
            [inputName]:inputVal
        }));
    } 

    return (
        <div className="content-log cbit-container">
            <div className="admin-action-header cbit-dashboard-header">
                Content Log
            </div>
            <div className="content-log-desc">
                Export a CSV file of current view count of all watchable content and FAQs.
            </div>
            <div className="content-log-btn-container">
                <button disabled className="btn-cbit-primary">DOWNLOAD FILE</button>
            </div>
        </div>
    )
}

export default ContentLog;