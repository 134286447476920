import { AxiosError } from 'axios';
import toast from 'react-hot-toast';
import { MyProfileDTO } from '../models/User/MyProfileDTO';
import { ICurrentUser, IUserBio, IUserProfile } from "../models/User/UserProfile";
import { PasswordUpdateRequest } from '../models/requests/PasswordUpdateRequest';
import { ErrorHandler } from "../models/utility/ErrorHandler";
import Roles from '../models/utility/Roles';
import { UserService } from "../services/UserService";
import { UserWithCourse } from '../models/User/UserWithCourse';

/** 
 * Class for interacting with the EduTrainer API Module Endpoints 
 * */
export class UserController{

    constructor(){
        this.uService = new UserService()
    }

    private uService:UserService;

    // Sends a POST request to create a module
    public async Create(userData : IUserProfile)
    {
        try{
            return this.uService.Post(userData);
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    // sends a GET request to get an array of modules
    public async GetUser(id: string)
    {
        try{
            return this.uService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }    

    /**
     * Sends a request to get all users
     * @returns 
     */
    public async GetUsers()
    {
        try{
            return this.uService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    /**
     * Sends a request to get a specific user by their username
     * @param username 
     * @returns 
     */
    public async GetUserByUsername(username:string) {
        try {
            return this.uService.GetUserByUsername(username);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Sends a request to update a single user
     * @param user 
     * @returns 
     */
    public async UpdateUser(user : ICurrentUser)
    {
        try{
            let id = user.id;
            const params = {id}
            return await this.uService.Put(params,user);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * A way for admin to create new users
     * @param newUserProfile 
     */
    public async AdminCreateUserProfile(newUserProfile:IUserProfile) {
        try {
            return await this.uService.AdminCreateUserProfile(newUserProfile);
        } catch (error) {
            throw error;
        }
    }

    /**
     * A way for admins to update a user's profile.
     * Note: can only update certain fields
     * @param userToUpdate 
     * @returns 
     */
    public async AdminUpdateUserProfile(userToUpdate:IUserProfile) {
        try {
            return await this.uService.AdminUpdateUserProfile(userToUpdate)
        } catch (error) {
        
            throw error
        }
    }

    /**
     * Because we don't want to remove fields not in userBio when using put     
     * @param userBio 
     * @returns 
     */
    public async UpdateUserBio(userBio: IUserBio) 
    {   
        try {
            return await this.uService.UpdateUserBio(userBio);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Deletes a single user using their id
     * @param id 
     * @returns 
     */
    public async DeleteUser(id : string)
    {
        try{
            return await this.uService.DeleteUser(id);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError)
        }
    }


    /**
     * Sends a request to login a user. A user is then given an HTTPOnly cookie
     * @param username 
     * @param password 
     * @returns 
     */
   public async Authenticate(username:string, password:string)
   {
       try{
           return await this.uService.Login(username,password);
       }catch(error){
           return ErrorHandler.catchApiError(error as AxiosError);
       }
   }

   /**
    * Logs a user out by sending a request to expire the httpOnly cookie
    */
   public async Logout():Promise<void> {
        try {
            return await this.uService.Logout();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
   }

   /**
    * For checking user to see if the user should still be login
    * @returns 
    */
   public async CheckUserStatus():Promise<IUserProfile> { 
        try {
            return await this.uService.CheckUserStatus();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
   }

    /**
     * Checks to see if an username is already in use
     * @param username 
     * @returns 
     */
    public async CheckIfUsernameIsInUse(username:string) {
        try {            
            const res = await this.uService.CheckIfUsernameIsInUse(username);
            return res;
        } catch (error) {
            console.error(error);
            toast.error("Failed to validate if username is in use");
            return null;
        }
    }

    /**
     * Checks to see if an email is already in use
     * @param email 
     * @returns 
     */
    public async CheckIfEmailIsInUse(email:string) {
        try {            
            const res = await this.uService.CheckIfEmailIsInUse(email);
            return res;
        } catch (error) {
            console.error(error);
            toast.error("Failed to validate if email is in use");
            return null;
        }
    }

    /**
     * Create a new user with the role of professional
     * TODO rename to normal user
     * @param professional 
     * @param testCode 
     * @returns 
     */
   public async CreateProfessional(professional:IUserProfile, testCode:string) {
        try {
            professional.roles = [Roles.User];
            return await this.uService.CreateProfessional(professional, testCode);
        } catch (error) {            
            throw error;
        }
   }

    /**
     * Get user bio information 
     * @param id
     * @returns 
     */
    public async GetUserBio(id:string) : Promise<IUserBio> {        
        try {
            return await this.uService.GetUserBio(id) as IUserBio;            
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }   
    }

    /**
     * Get all users bio information
     * @returns 
     */
    public async GetAllUserBio() : Promise<IUserBio[]> {
        try {
            return await this.uService.GetAllUserBio();            
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }   
    }

    /**
     * Get users formatted for management
     * @returns 
     */
    public async GetUsersForManagement() : Promise<UserWithCourse[]>{
        try {
            return await this.uService.GetUsersForManagement();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Gets a list of consultants
     * @returns 
     */
    public async GetConsultants() {
        try {
            return await this.uService.GetConsultants();
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Updates an individual's password
     * @param id 
     * @param pwdUpdateReq 
     * @returns 
     */
    public async UpdateUserPassword(id:string, pwdUpdateReq:PasswordUpdateRequest) {
        try {
            return await this.uService.UpdateUserPassword(id, pwdUpdateReq);
        } catch (error) {
            throw error;
        }
    }

    /**
     * Updates a user's VisitedSocialConnection property
     * @param id
     * @returns void
     */
    public async UpdateVisitedSocialConnection(id: string) {

        try {
            return await this.uService.UpdateVisitedSocialConnection(id);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Calls the service to end a user testing phase
     * @param userId 
     */
    public async EndUserTestingPhase(userId:string) {
        try {
            return await this.uService.EndUserTestingPhase(userId);
        } catch (error) {
            throw error;
        }
    }
    
    /**
     * Update user with the specific fields in my profile
     * @param id 
     * @param myProfile 
     * @returns 
     */
    public async UpdateMyProfile(userId:string, myProfile:MyProfileDTO) {        
        try {
            return await this.uService.UpdateMyProfile(userId, myProfile);
        } catch (error) {
            throw error;
        }
    }
}


