import { useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import AdminUserForm from '../components/AdminDashboard/AdminForms/UserForms/AdminUserForm';
import AdminTherapistSupport from '../components/AdminDashboard/AdminTherapistSupport/AdminTherapistSupport';
import ContentManagement from '../components/AdminDashboard/AdminTools/ContentManagement';
import ContentBuilder from '../components/AdminDashboard/ContentBuilder/ContentBuilder';
import CourseSelection from '../components/AdminDashboard/CourseSelection/CourseSelection';
import UserManagement from '../components/AdminDashboard/UserManagement/UserManagement';
import Inbox from "../components/Inbox/InboxPages/Inbox/Inbox";
import ContactUs from '../components/PublicPages/ContactUs/ContactUs';
import DownloadPdfPage from '../components/PublicPages/DownloadPdfPage';
import FAQ from '../components/PublicPages/Faqs/Faqs';
import Logout from '../components/PublicPages/Logout';
import AddPost from "../components/SocialConnection/SocialConnectionPages/AddPost/AddPost";
import Admin from "../components/SocialConnection/SocialConnectionPages/Admin/Admin";
import Faqs from "../components/SocialConnection/SocialConnectionPages/Faqs/Faqs";
import MyPosts from "../components/SocialConnection/SocialConnectionPages/MyPosts/MyPosts";
import Post from "../components/SocialConnection/SocialConnectionPages/Post/Post";
import SocialConnection from "../components/SocialConnection/SocialConnectionPages/SocialConnection/SocialConnection";
import TherapistSupport from '../components/TherapistSupport/TherapistSupport';
import LearningDashboard from '../components/UserDashboard/LearningContentViewer/LearningDashboard';
import MyProfileTemp from '../components/UserDashboard/MyProfile/MyProfile';
import PasswordReset from '../components/UserDashboard/PasswordReset/PasswordReset';
import AuthGuard from '../components/Utilities/RouteGuard/AuthGuard';
import { ApplicationState } from '../store';
import { CurrentUserState } from '../store/CurrentUser';

//To get the Admin logo to show you need to add the route to here also
export const adminRoutes = [    
    '/users',        
    '/users/new',    
    '/admin-tools',
    '/content-builder',
    '/content-builder/:id',
    '/users/:username/edit',        
    '/course-selection',
    '/document-files-admin',
    '/test-page'
]

const PrivateRoutes = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

    return (
        <AuthGuard>
            <Switch>
                {/* <Route exact path='/' component={UserDashboard}/> */}
                {/* <Route exact path='/home' component={UserDashboard}/> */}
                <Route path='/contact-us' component={ContactUs} />
                <Route path='/faq' component={FAQ} />
                <Route path='/logout' component={Logout}/>
                <Route path='/my-profile' component={MyProfileTemp}/>
                <Route path='/inbox' component={Inbox}/>
                <Route path='/password-reset' component={PasswordReset}/>
                <Route path='/learningDashboard/:id'
                       render={props => <LearningDashboard id={props.match.params.id}
                                                           {...props}/>}/>    
                <Route path='/therapist-toolbox' component={TherapistSupport}/>            
                <Route path='/social-connection' exact component={SocialConnection}/>
                <Route path='/social-connection/posts/:id' component={Post} />
                <Route path='/social-connection/add-post' component={AddPost} />
                <Route path='/social-connection/my-posts' component={MyPosts}/>
                <Route path='/social-connection/faqs' component={Faqs}/>
                <Route path='/social-connection/admin' component={Admin}/>
                <Route path='/download-pdf/:id' component={DownloadPdfPage}/>
                {
                    userStore && userStore.isAdmin || userStore && userStore.isSuperAdmin ?
                        (
                            <>
                                {/**Add any new Admin routes to the Admin routes object to show the Admin logo */}                                
                                <Route path='/users' component={UserManagement} exact/>
                                <Route path='/users/new' render={props => <AdminUserForm isEditing={false}/>}/>
                                <Route path='/users/:username/edit' render={props => <AdminUserForm isEditing={true}/>}/>
                                <Route path='/document-files-admin' component={AdminTherapistSupport}/>                                
                                <Route path='/admin-tools' component={ContentManagement}/>
                                <Route exact path='/content-builder'>
                                    <Redirect to='/admin-tools' />
                                </Route>
                                <Route path='/content-builder/:sectionId' component={ContentBuilder}/>
                                <Route path='/course-selection' component={CourseSelection} />                                                                
                                {/* <Route path='/test-page' component={MyProfileTemp}/> */}
                            </>
                        )
                        :
                        (
                            <Redirect to='/'/>
                        )
                }                              
            </Switch>
        </AuthGuard>
    )
}

export default PrivateRoutes;
