enum Roles {
    None = 0,
    User  = 1,
    Admin = 4,
    SuperAdmin = 5,
    TestUser = 6
}

/**
 * Object for displaying roles in a ui friendly manner
 */
export const UiRoleToRole = {
    [Roles.None]: "None",
    [Roles.User]: "User",        
    [Roles.Admin]: "Admin",
    [Roles.SuperAdmin]: "Super Admin",
    [Roles.TestUser]: "Test User",
}

/**
 * Class that provide functions to deal with user roles
 */
export class RolesHelper {
    /**
     * Check to see if a user is a super admin or not
     */
    static CheckIfSuperAdmin = (roles:Roles[]) => {
        return roles.includes(Roles.SuperAdmin);
    }

    /**
    * Because users can multiple roles this gets the highest role a user is.
    *  i.e: User is professional and Admin, Admin will display
    * Note: If the user has the test user role on them display that will be displayed over anything
    */
    static GetHighestRole = (roles:Roles[]) => {        
    
        if(!roles || roles.length < 1) {
            return "This user has not been assigned a role"
        }
        
        //We want to display test user over any other role if they have it
        if(roles.includes(Roles.TestUser)) {
            return UiRoleToRole[Roles.TestUser];
        }
        const highestRole = Math.max(...roles);
        return UiRoleToRole[highestRole as Roles];
    }
    
}

export default Roles;