import { AxiosError } from "axios";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { PublishedLessonService } from "../services/PublishedLessonService";
import { ILesson } from "../models/lesson/Lesson";

/**
 * Controller for handling communication with PublishedLesson Services
 */
export class PublishedLessonController{ 
    constructor(){        
        this.LessonService = new PublishedLessonService();
    }
    private LessonService:PublishedLessonService;

    /**
     * Get the last published lesson
     * @returns 
     */
    public async GetLastLesson(courseId:string) : Promise<ILesson> {
        try {
            return await this.LessonService.GetLastLesson(courseId);
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    } 

    public async GetAdditionalVideosForLessonId(id:string){
        try
        {
            return await this.LessonService.GetAdditionalVideosForLesson({id:id});        
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
}