import { _idObject } from "./AdditionalVideoContent";


export interface IContentCategory {
    id: string;
    name : string;
    order: number;
    tags : _idObject[];
}

export class ContentCategory implements IContentCategory {
    id: string;
    name : string;
    order: number;
    tags : _idObject[]= [];

    constructor( data: IContentCategory)
    {
        this.id = data.id,
        this.name = data.name,
        this.order = data.order,
        this.tags = data.tags
    }

    static createTagList(tags:string[]) {
        return tags.map(tag => {
            const tagIdObj = new _idObject();
    
            tagIdObj._id = tag;
    
            return tagIdObj;
        })
    }

    static filterTagList(contentCategory:IContentCategory, tagToRemove:_idObject) {

        const filteredTags = contentCategory.tags.filter(tag => tag._id !== tagToRemove._id);
        
        return filteredTags;
    }
}
