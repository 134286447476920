import * as React from 'react';
import { IVideoContent, VideoContent } from '../../../models/contentTypes/VideoContent';
import { Alert, Spinner, Form, Row, Col, Input,
        FormGroup, FormFeedback, Label } from 'reactstrap';
import {ILessonContent, LessonContent} from "../../../models/lesson/LessonContent";
import {LessonContentType} from "../../../models/contentTypes/LessonContentType";
import './VideoForm.css';
import { ILesson, Lesson, LessonIconEnum } from '../../../models/lesson/Lesson';
import { LessonTypeEnum } from '../../../models/lesson/LessonType';
import { LessonController } from '../../../controllers/LessonController';
import { LessonContentController } from '../../../controllers/LessonContentController';
import { AdminModule, IAdminModule } from '../../../models/module/AdminModule';
import TimeHelper from '../../../models/utility/TimeHelper';
import { VimeoController } from '../../../controllers/VimeoController';
import CustomModal from '../../Utilities/CustomModal';
import ContentCategoryBuilder from './ContentCategoryForms/ContentCategoryBuilder';
import { ContentCategoryController, privateContentCategoryId } from '../../../controllers/ContentCategoryController';
import { _idObject } from '../../../models/AdditionalVideoContent/AdditionalVideoContent';
import { ContentCategory } from '../../../models/AdditionalVideoContent/ContentCategory';
import toast from 'react-hot-toast';


type VideoState = {
    video: IVideoContent
    errors: []
    isLoading: boolean
    isOpen:boolean
    tagId:string
    hasTagError:boolean //Temp, will remove when adding 3rd party library
}

type VideoProps = {
    order:number
    content: ILessonContent
    video: IVideoContent
    isEditing:boolean
    isInsideLessonCombo?:boolean
    branchID:string   
    courseId:string
    tagId?:_idObject
    toggleModal(isOpen?:boolean):void   
    onSubmit(lesson:ILesson | ILessonContent | IAdminModule, duration?:string, imageFile?:File, tagId?:_idObject[]) : void
}

/**
 * Form that creates and edits a video lesson and video content 
 * Note: Tag id was changed to Lesson Id late in development
 */
class VideoForm extends React.Component<VideoProps, VideoState> {

    private LessController = new LessonController();
    private LessContentController = new LessonContentController();
    private contentCategoryController = new ContentCategoryController();
    private Vimeo = new VimeoController();
    private tagErrorMsg = "Lesson ID Is Already In Use";

    constructor(props:any) {
        super(props);
        this.state = this.unloadState
    }

    componentDidMount() {
        
    }

    private unloadState : VideoState = {
        video:  this.props.video ? this.props.video : new VideoContent(),
        errors: [],
        isLoading: false,
        isOpen: false,
        tagId: this.props.tagId ? this.props.tagId._id : "",
        hasTagError:false        
    }    
 
    private onChange = (e: React.ChangeEvent<HTMLInputElement>) => {        
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,
            video: {
                ...prevState.video,
                [inputName]: inputValue
            }
        }));
    }

    private onTagIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputName = e.currentTarget.name;
        const inputValue = e.currentTarget.value;

        this.setState(prevState => ({
            ...prevState,            
            [inputName]: inputValue            
        }));
    }

    private updateHasTagError = (hasTagError:boolean) => {
        this.setState(prevState => ({
            ...prevState,
            hasTagError
        }));
    }
    
    /**
     * Checks if a tag exists and updates the private tag content category
     * @param tagId 
     * @returns 
     */
    private checkIfTagExistsAndUpdate = async (tagId:string) => {        
        try {
            const contentCategory = await this.contentCategoryController.CheckIfTagExistsInCategoryAndUpdate(privateContentCategoryId, tagId);
            
            if(contentCategory) {
                return false;
            }
            
            return true;
        } catch (error) {
            console.error(error);
            toast.error("Failed To Validate Lesson Id");
            throw error;
        }
    }

    private checkIfLessonIdExists = async (lessonId:string) => {
        try {
            
            return await this.contentCategoryController.CheckIfLessonIdExistsInPrivateCategory(lessonId);
            
        } catch (error) {
            console.error(error);
            toast.error("Failed To Validate Lesson Id");
            throw error;
        }
    }

    /**
     * Creates either a video lesson or video content
     * @returns 
     */
    private createVideoLesson = (isLessonContent:boolean, duration?:string) => {
        const {videoName, videoDescription} = this.state.video
        const {order} = this.props;
        let videoLesson = null;

        if(isLessonContent) {                        
            videoLesson = new LessonContent();
            videoLesson.type = LessonContentType.video;
            videoLesson.videoProperties = this.state.video;
            videoLesson.videoProperties.videoLength = duration ? duration : "00:00";            
        } else {
            videoLesson = new Lesson();
            videoLesson.branchId = this.props.branchID;
            videoLesson.type = LessonTypeEnum.video;
            videoLesson.lessonIcon = LessonIconEnum.VideoIcon;
            videoLesson.IsNext = false;
        }
        videoLesson.order = order;
        videoLesson.name = videoName;
        videoLesson.courseId = this.props.courseId;
        videoLesson.description = videoDescription;
        
        return videoLesson;
    }

    /**
     * Adds video lesson and video content to the API
     */
     private addVideoLesson = async (tagId:string, duration?:string) => {
        const {isInsideLessonCombo, branchID} = this.props;
        
        let videoLesson = null;

        let videoLessonContent : ILessonContent = this.createVideoLesson(true, duration) as ILessonContent;

        if(isInsideLessonCombo) {
            videoLessonContent.lessonId = branchID;
            
            if(this.state.tagId.length > 0 && this.props.order < 1) {
                const comboLesson = await this.LessController.GetLesson(branchID);

                comboLesson.tagList = [{_id: this.state.tagId}];

                await this.LessController.UpdateLesson(comboLesson);
            }

        } else {
            videoLesson = this.createVideoLesson(false) as ILesson;
            
            (videoLesson as ILesson).duration = duration ? `${duration}` : "00:00";

            if(tagId.length > 0) {
                const tagIdObject = new _idObject();

                tagIdObject._id = tagId;

                (videoLesson as ILesson).tagList = [tagIdObject];
            }

            videoLesson = await this.LessController.CreateLesson(videoLesson);          

            videoLessonContent.lessonId = videoLesson.id
        }        

        videoLessonContent = await this.LessContentController.CreateLessonContent(videoLessonContent);            

        if(videoLesson) {            
            (videoLesson as ILesson).lessonContent = [videoLessonContent];
        }
        
        const content = videoLesson ? videoLesson : videoLessonContent;

        const adminModule = new AdminModule(content);

        this.props.onSubmit(adminModule);

        this.props.toggleModal(false);
    }

    /**
     * Edit route for video lessons
     * @param duration 
     */
    private editVideoLesson = async (tagId:string, duration:string) => {
        
        const {video} = this.state;
        
        if(tagId !== this.props.tagId?._id && tagId.length > 0) {
            const hasAlreadyBeenCreated = await this.checkIfLessonIdExists(tagId);

            if(hasAlreadyBeenCreated) {
                this.updateHasTagError(true);
                toast.error(this.tagErrorMsg)
                return;
            }
        }
        
        const content = {...this.props.content};        
        content.name = video.videoName;
        content.description = video.videoDescription;
        content.videoProperties = video;
        content.videoProperties.videoLength = `${duration}`

        let tagList:_idObject[] = [] 

        if(tagId.length > 0)        
            tagList = ContentCategory.createTagList([tagId]);
            
        
        if(this.props.isInsideLessonCombo && this.props.content.order > 0) {
            //@ts-ignore
            tagList = undefined;
        }
        
        this.props.onSubmit(content, duration, undefined, tagList);

        this.props.toggleModal(false);
    }

    private onSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        // Prevents a page refresh   
        e.preventDefault();         

        const {isEditing, toggleModal} = this.props;
        
        const {video, tagId} = this.state;

        let formattedTagId = ""; 

        if(tagId && tagId.length > 0)
            formattedTagId = tagId.trim().toLowerCase();
        
        video.videoLink = video.videoLink.trim();
            
        let duration = await this.Vimeo.GetVimeoDuration(video.videoLink);

        duration = TimeHelper.convertSecondsToDuration(duration as number);

        if(isEditing) {            

            this.editVideoLesson(formattedTagId, duration);  
            return;          
        } 

        /**
         * If we are normal lesson and have a tag Id continue.
         * If we are the first item in a combo lesson with a tag id continue
         * **/
        if(formattedTagId.length > 0 && !this.props.isInsideLessonCombo 
            || this.props.isInsideLessonCombo && this.props.order < 1 && formattedTagId.length > 0)
        {        
            const alreadyExist = await this.checkIfTagExistsAndUpdate(formattedTagId)
            
            if(alreadyExist) {
                this.setState(prevState => ({
                    ...prevState,
                    hasTagError:true
                }));
                toast.error(this.tagErrorMsg)
                return; 
            }        
        }

        this.addVideoLesson(formattedTagId, duration);             
    }

    private toggleTags = () => {
        this.setState(prevState => ({
            ...prevState,
            isOpen: !this.state.isOpen,
            hasTagError:false
        }));
    }

    public render(){
        const {isEditing, toggleModal} = this.props;
        return(
            <>
                {this.state.errors.length ? (
                    <Alert color="danger">
                    {this.state.errors.map(error => (
                        error
                        ))}
                    </Alert>
                ) : null}
                {this.state.isLoading ? (
                    <Spinner color="black"></Spinner>
                ) : (
                        <Form className="video-form" onSubmit={this.onSubmit}>
                            {
                                isEditing ? 
                                (
                                    <h2 className="video-form-title">Edit Video Lesson</h2>                                                         
                                ) 
                                : 
                                (                                        
                                    <h2 className="video-form-title">Create Video Lesson</h2>                                                         
                                )
                            }       
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className='video-form-label form-label' for="videoName">Video Name</Label>
                                            <Input
                                                className='form-input' 
                                                name="videoName" type="text" id="videoName" placeholder="Enter a title that will appear under your video." 
                                                value={this.state.video.videoName} onChange={this.onChange}  required/>
                                            <FormFeedback>
                                                Invalid Video Name
                                            </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className='video-form-label form-label' for="description">Video Description</Label>
                                            <Input 
                                                className='form-input form-textarea'
                                                name="videoDescription" type="textarea" id="videoDescription" placeholder="Enter a brief description that will appear under your video title." 
                                                value={this.state.video.videoDescription} onChange={this.onChange}  />
                                            <FormFeedback>
                                                Invalid Video Description
                                            </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup>
                                        <Label className='video-form-label form-label'  for="videoLink">Video Link</Label>
                                            <Input 
                                                className='form-input'
                                                name="videoLink" type="text" id="videoLink" placeholder="Enter the video link." 
                                                value={this.state.video.videoLink} onChange={this.onChange}  required/>
                                            <FormFeedback>
                                                Invalid Video Link
                                            </FormFeedback>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12}>
                                    <FormGroup className='tag-form-group'>
                                        <Label className='video-form-label form-label' for="tagId">Lesson ID</Label>
                                        {
                                            this.props.isInsideLessonCombo && this.props.content && this.props.content.order > 0 ? 
                                            (
                                                <Input 
                                                    disabled
                                                    invalid={this.state.hasTagError}
                                                    className='form-input'
                                                    name="tagId" 
                                                    type="text" 
                                                    id="tagId" 
                                                    placeholder="i.e: M06_03, M07_02, etc."     
                                                    value={this.state.tagId}
                                                    onChange={this.onTagIdChange}                                                                                            
                                                />
                                            ) 
                                            : 
                                            (
                                                <Input 
                                                    invalid={this.state.hasTagError}
                                                    className='form-input'
                                                    name="tagId" 
                                                    type="text" 
                                                    id="tagId" 
                                                    placeholder="i.e: M06_03, M07_02, etc."     
                                                    value={this.state.tagId}
                                                    onChange={this.onTagIdChange}                                                                                            
                                                />
                                            )
                                        }
                                            <FormFeedback>
                                                {this.tagErrorMsg}
                                            </FormFeedback>
                                            <br/>
                                            {/* <button type='button' onClick={() => this.toggleTags()} className="btn-cbit-minor">
                                                Add Tags
                                            </button> */}
                                    </FormGroup>
                                </Col>
                            </Row>            
                            <div className="video-btn-container">
                                <button type='button' onClick={() => toggleModal(false)} className='btn-cbit-minor'>Cancel</button>
                                <button type='submit' className='btn-cbit-primary'>
                                    {
                                        isEditing ? "Update" : "Submit"
                                    }                                    
                                </button>
                            </div>
                        </Form>

                    )}
                    <CustomModal isOpen={this.state.isOpen} toggle={this.toggleTags}>
                        <ContentCategoryBuilder />       
                    </CustomModal>
            </>
        )
    }
}

export default VideoForm;