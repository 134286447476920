import { EditorContentEnum } from '../../../../models/utility/EditorContentEnum';
import { ReactComponent as ChapterLabel } from '../../../../resources/images/tag-chapter.svg';
import { ReactComponent as LessonGroupLabel } from '../../../../resources/images/tag-lesson-group.svg';
import { ReactComponent as LessonLabel } from '../../../../resources/images/tag-lesson.svg';
import { ReactComponent as QuizLabel } from '../../../../resources/images/tag-quiz.svg';
import './ContentTypeLabel.css';

type contentTypeLabelProps = {
    contentType:EditorContentEnum
}

/**
 * Displays the the type of content the admin is currently in
 */
const ContentTypeLabel = (props:contentTypeLabelProps) => {

    const {contentType} = props;

    /**
     * Get what text should appear for the label
     * @returns 
     */
    const getContentTypeText = () => {
        switch (contentType) {
            case EditorContentEnum.module:
                return <ChapterLabel/>    
                
            case EditorContentEnum.lesson:
                return <LessonLabel/>
            
            case EditorContentEnum.lessonGroup:
                return <LessonGroupLabel/>

            case EditorContentEnum.lessonQuiz:
                return <QuizLabel/>
            default:
                return  ""
                break;
        }
    }

    /**
     * Get the class to display the color for the content type label
     */
    const getColorClass = () => {
        if(contentType === EditorContentEnum.module)
            return "content-type-label-module"
    }

    /**
     * Decides if the content type label should be shown or not
     * @returns 
     */
    const showContentTypeLabel = () => {
        // The label for the top most module should not be shown
        return contentType !== EditorContentEnum.parentModule 
    } 


    return (
        <>
            {
                showContentTypeLabel() &&
                (
                    
                    <div className="content-type-label">
                        {getContentTypeText()}
                    </div>
                   
                   
                )
            }

        </>
    )

}

export default ContentTypeLabel;