import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useLocation } from 'react-router-dom';
import { adminRoutes } from '../../../Routes/PrivateRoutes';
import adminPixelLogo from '../../../resources/images/pixel-trainer-logo-white.png';
import pixelLogo from '../../../resources/images/pixel-trainer-logo.png';
import { ApplicationState } from '../../../store';
import { CurrentUserState, userStoreAvailableActions } from '../../../store/CurrentUser';
import AdminNavLinks from '../../AdminDashboard/AdminNavigation/AdminNavLinks';
import './NavMenu.css';
import UserProfileMenu from './UserProfileMenu';
import { StringHelper } from '../../../models/utility/StringHelper';



/**
 * Main Navigation menu header 
 */
const NavMenu = () => {
    const UserStore = useSelector<ApplicationState, CurrentUserState | undefined>((state )=> state.currentUser);
    
    const dispatch = useDispatch();  

    const location = useLocation();

    const logOutAction = useCallback(
        () => dispatch({type: userStoreAvailableActions.removeCurrentUser}), 
        [dispatch]
    )

    const getHomeRoute = () => {
        if(UserStore == null) {
            return "/";
        }

        const {isLoggedIn, userProfile: {assignedSectionId}} = UserStore;

        return UserStore?.isLoggedIn ? `/learningDashboard/${assignedSectionId}` : "/";
    }

    /**
     * Show admin logo or not. Routes page should be protecting the admin routes
     * There are better ways to do this but time is not on our side =/
     * @returns 
     */
    const showAdminDashboardLogo = () => {                
        let currentPath = location.pathname;
        if(!UserStore || !UserStore.isLoggedIn) 
            return false;

        //to show nav logo for content builder
        if(StringHelper.checkIfSubstring(currentPath, 'content-builder/')) {
            return true;
        }

        return adminRoutes.includes(currentPath);
    }
    

    return (
        <header className={`header-navbar ${showAdminDashboardLogo() && 'admin-header-nav'}`}>  
            <div className="navbar-inner">
                <div className="logo-container">
                    {
                        showAdminDashboardLogo() ? 
                        (
                            <NavLink className='logo-link pixel-trainer-logo admin-logo disabled-link' to={'#'}>
                                <img className='logo' src={adminPixelLogo} alt="Pixel Trainer Logo" />
                            </NavLink>
                        ) 
                        : 
                        (
                            <NavLink className='logo-link pixel-trainer-logo disabled-link' to={'#'}>
                                <img className='logo' src={pixelLogo} alt="Pixel Trainer Logo" />
                            </NavLink>
                        )
                    }
                </div>
                <div className="nav-items-container">                
                    <div className={`nav-items ${location.pathname === '/login' ? 'home' : ''}`}>
                    {
                        UserStore?.isLoggedIn && ( 
                            <>
                                <NavLink exact activeClassName='is-active' className='nav-item-text' to={getHomeRoute()}>
                                    Course
                                </NavLink>
                            </>

                        )

                    }
                        <AdminNavLinks />
                        {
                            !UserStore?.isLoggedIn && (
                                <>                                
                                    {/* <NavLink exact activeClassName='is-active' className="nav-item-text" to="/login">Login</NavLink>        
                                    <NavLink exact activeClassName='is-active' className="nav-item-text" to="/sign-up">Sign Up</NavLink>         */}
                                </>
                            )
                        }                        
                    </div>
                    <div className={`user-navigation-items ${location.pathname === '/login' ? 'home' : ''}`}>
                        {
                            UserStore?.isLoggedIn &&
                            (
                                <UserProfileMenu 
                                    userId={UserStore.userProfile.id} 
                                    firstName={UserStore.userProfile.firstName} 
                                    lastName={UserStore.userProfile.lastName} 
                                    isAdmin={UserStore.isAdmin} 
                                    logOutFunc={logOutAction } 
                                />
                            )                             
                        }
                    </div> 
                </div>
            </div>           
        </header>        
    )
}

export default NavMenu; 