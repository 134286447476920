import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";

export class PublishedModuleService extends BaseReactService {
    constructor(){
        super("PublishedModule/");
    }
    
    public async GetModuleDepthById(id:{id:string}){
        const endpoint = 'PublishedModule/GetModuleDepthById/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            // ErrorHandler.catchApiError(error)
        }
    }
    
    public async GetModulesWithParentId(id: { id:string }){
        const endpoint = 'PublishedModule/WithParentId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            // ErrorHandler.catchApiError(error)
        }
    }

    public async GetModulesForTreeViewParentModuleId(id: { id:string }){
        const endpoint = 'UserPublishedModule/GetAssignedModulesForUserByParentModuleId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
    * Gets a list of modules that are courses (Top parent module) 
    */
    public async GetCourses(){
        const endpoint = 'PublishedModule/GetCourses';
        try{
            return await this.GetAll(endpoint);
        }catch(error){
            throw error;
        }
    }
    

    public async GetDeletedModules()
    {
        const endPoint = 'PublishedModule/GetDeleted';
        try {
            return await this.GetAll(endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async GetAvailableModulesByParentModule(id:{id:string})
    {
        const endPoint = 'PublishedModule/GetAvailableModulesByParentModule';
        try {
            return await this.Get(id,endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }   
    }

    /**
     * Publishes the passed in sectionId (course)
     * @param courseId 
     * @returns 
     */
    public async PublishEntireCourse(courseId:string) {
        const endpoint = 'PublishedModule/PublishEntireCourse'
        try {
            return await this.CustomGet({courseId}, endpoint);
        } catch (error) {
            throw error;
        }
    }

    public async UpdateLastModified(id:{id:string}) {
        const endPoint = 'PublishedModule/LastModifiedUpdate/';
        try {
            return await this.CustomPatch(id, endPoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as  AxiosError);
        }
    }

    /**
     * Softly deletes a module by updating the deletedAt property
     * @param id 
     * @returns 
     */
    public async DeleteModuleSoftly(id: {id:string}){
        const endpoint = 'PublishedModule/DeleteSoftly/';
        try {
            return await this.Patch(id, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Permanently delete a course and all of its contents. This will delete user modules/etc also
     * @param courseId 
     * @returns 
     */
    public async DeleteCourse(courseId:string) {
        const endPoint = 'PublishedModule/DeleteCourse';
        try {
            return await this.CustomDelete({courseId}, endPoint);
        } catch(error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Restores a softly deleted module
     * @param id 
     * @returns 
     */
    public async RestoreDeletedModule(id:{id:string}){
        const endpoint = 'PublishedModule/RestoreDeleted/';
        try {
            return await this.Patch(id, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
}