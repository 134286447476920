import * as React from 'react';
import {MultipleChoiceProps, QuestionFormState} from './MultipleChoiceForm';
import QuestionForm from './QuestionForm';
import { MultipleChoiceContent } from '../../../../models/contentTypes/MultipleChoiceContent';
import { SelectableChoice } from '../../../../models/contentTypes/SelectableChoice';
import {ILessonContent} from "../../../../models/lesson/LessonContent";
import {QuestionType} from "../../../../models/contentTypes/QuestionType";

/**
 * True or False form component that create multiple choice lesson content but only give two
 * questions options (True or False, Yes or No, etc) instead of multiple
 */
class TrueOrFalseForm extends React.Component<MultipleChoiceProps, QuestionFormState> {

    constructor(props:any) {
        super(props);
        this.state = this.defaultState;
    }

    private defaultState : QuestionFormState = {
        question: this.props.question ?  this.props.question : new MultipleChoiceContent(
            {          
                questionText:'',
                questionNumber: '',
                completed: false,
                choices: [new SelectableChoice({text: 'True', order: 0, isCorrect: false}), new SelectableChoice({text: 'False', order: 0, isCorrect: false})],
                hasMultipleAnswers: true
            }),
        isLoading: false
    }

    public render() {
        const {onSubmit, isEditing, branchID, content, toggleModal,questionNumber, order, courseId} = this.props;
        const {question} = this.state;
        return (
            <>
                <QuestionForm 
                    order={order}
                    questionNumber={questionNumber}
                    isTrueOrFalse={true}
                    onSubmit={onSubmit} 
                    content={content} 
                    questionType={QuestionType.trueOrFalse} 
                    defaultQuestion={question} 
                    addChoice={null} 
                    removeChoice={null} 
                    isEditing={isEditing} 
                    branchID={branchID} 
                    courseId={courseId}
                    toggleModal={toggleModal}
                />
            </>
        )
    }
} 

export default TrueOrFalseForm