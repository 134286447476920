import { useState } from 'react';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import { LessonIconEnum } from '../../../../../models/lesson/Lesson';
import ComboLessonIconSrc from '../../../../../resources//icons/icon-combo.png';
import QuizIconSrc from '../../../../../resources/icons/icon-quiz.png';
import TextIconSrc from '../../../../../resources/icons/icon-reading.png';
import VideoIconSrc from '../../../../../resources/icons/icon-video.png';
import './ComboIconDropdown.css';

type ComboIconDropdownProps = {
    lessonIcon:LessonIconEnum;
    handleIconChange:(lessonIcon:string|null) => void;
}

const ComboIconDropdown = (props:ComboIconDropdownProps) => {
    const {handleIconChange, lessonIcon} = props;

    const [state, setState] = useState(lessonIcon);

    const displaySelectedIcon = () => {
        switch(lessonIcon) {
            case (LessonIconEnum.VideoIcon): {
                return {src: VideoIconSrc, altText: "Video Icon"};
            }
            case (LessonIconEnum.TextIcon): {
                return {src: TextIconSrc, altText: "Text Icon"};
            }
            case (LessonIconEnum.QuizIcon): {
                return {src: QuizIconSrc, altText: "Quiz Icon"};
            }
            case (LessonIconEnum.ComboLessonIcon): {
                return {src: ComboLessonIconSrc, altText: "Combo Icon"};
            }
            default: {
                return {src: "", altText: ""}
            }
        }
    }

    return (
        <>
            <Dropdown
              as={ButtonGroup}
              className="btn-dropdown-image"
              onSelect={handleIconChange}
            >
                <Dropdown.Toggle id="dropdown-image-select" className='dropdown-image-selected'>                        
                        <div className="dropdown-image-container">
                            <img className='dropdown-image' src={displaySelectedIcon().src} alt={displaySelectedIcon().altText}/>
                        </div>
                </Dropdown.Toggle>
                <Dropdown.Menu className=''>
                    <Dropdown.Item
                        eventKey={LessonIconEnum.VideoIcon}                         
                        active={lessonIcon === LessonIconEnum.VideoIcon}
                    >
                        <div className="dropdown-item-container">
                            <div className="dropdown-image-container">
                                <img className='dropdown-image' src={VideoIconSrc} alt="Video Icon"/>
                            </div>
                            <span>Video Icon</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey={LessonIconEnum.TextIcon}                         
                        active={lessonIcon === LessonIconEnum.TextIcon}
                    >
                        <div className="dropdown-item-container">
                            <div className="dropdown-image-container">
                                <img className='dropdown-image' src={TextIconSrc} alt="Text Icon"/>
                            </div>
                            <span>Text Icon</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey={LessonIconEnum.QuizIcon}                         
                        active={lessonIcon === LessonIconEnum.QuizIcon}
                    >
                        <div className="dropdown-item-container">
                            <div className="dropdown-image-container">
                                <img className='dropdown-image' src={QuizIconSrc} alt="Quiz Icon"/>
                            </div>
                            <span>Quiz Icon</span>
                        </div>
                    </Dropdown.Item>
                    <Dropdown.Item
                        eventKey={LessonIconEnum.ComboLessonIcon}                        
                        active={lessonIcon === LessonIconEnum.ComboLessonIcon}
                    >
                        <div className="dropdown-item-container">
                            <div className="dropdown-image-container">
                                <img className='dropdown-image' src={ComboLessonIconSrc} alt="Combo Icon"/>
                            </div>
                            <span>Combo Icon</span>
                        </div>
                    </Dropdown.Item>
                </Dropdown.Menu>
                
            </Dropdown>
        </>
    )
}

export default ComboIconDropdown;