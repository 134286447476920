import React, {FunctionComponent, useEffect, useState} from "react";
import {UserLesson} from "../../../../models/lesson/UserLesson";
import {DefaultLesson, ILesson, Lesson} from "../../../../models/lesson/Lesson";
import {PartialModuleTreeModel} from "../../../../models/partialModels/PartialModuleTreeModel";
import {UserModuleController} from "../../../../controllers/UserModuleController";
import {UserLessonContentLogController} from "../../../../controllers/UserLessonContentLogController";
import {UserLessonController} from "../../../../controllers/UserLessonController";
import {UserLessonContent} from "../../../../models/lesson/UserLessonContent";
import {UserLessonContentController} from "../../../../controllers/UserLessonContentController";
import "./../UserLessonContentViewer.css";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../../../store";
import { CurrentLastLessonState } from "../../../../store/LastLessonStore";
import CloudflareImageHelper from "../../../../models/utility/CloudflareImageHelper";
import { AdminTreeHelper } from "../../../../models/utility/TreeHelper/AdminTreeHelper";
import { ITextContent } from "../../../../models/contentTypes/TextContent";
import { StringHelper } from "../../../../models/utility/StringHelper";

export const variantName = "/4k"; // name of the cloudFlare variant

type TextProps = {
    parentModule: PartialModuleTreeModel,
    selectedUserLesson: UserLesson,
    updateAccordionFunction: any,
    toggleCourseCompletion:() => void,
}


const TextLesson: FunctionComponent<TextProps> = (props) => {

    const defaultContent:UserLessonContent[] =[]

    //State Data
    const [UserLessonContent, setUserLessonContent] = useState(defaultContent)
    const [Lesson, setLesson] =useState(DefaultLesson);
    const [TextContent, setTextContent] = useState<ITextContent | null>(null);

    const [complete, setComplete] = useState(props.selectedUserLesson.isComplete);
    const [dataLoaded, setDataLoaded] = useState(false);
    const lastLessonStore = useSelector<ApplicationState, CurrentLastLessonState | undefined>(state => state.currentLastLesson);
    const lastLesson:ILesson | null = lastLessonStore ? lastLessonStore.lastLesson : null;

    const userLessonContentController: UserLessonContentController = new UserLessonContentController();
    const userLessonController: UserLessonController = new UserLessonController();
    const userModuleController: UserModuleController = new UserModuleController();
    const userLessonContentLog: UserLessonContentLogController = new UserLessonContentLogController();

    //Inital Configuration
    useEffect(() => {
        const setInitalData = async (setUserContent: any, setL:any, setContent:any, setData:any, setCompleteness:any) => {

            setUserContent(props.selectedUserLesson.lesson.userLessonContent);
            setL(props.selectedUserLesson.lesson);
            setCompleteness(props.selectedUserLesson.isComplete);

            if (props.selectedUserLesson.lesson.lessonContent != null) {
                setContent(props.selectedUserLesson.lesson.lessonContent[0].textProperties)
            }
            setData(true);
        }


        const completeLesson = async()=>{

            await userLessonContentLog.PostLog({
                Choice: "User Completed Text Lesson",
                IsCompleted: "true",
                LessonContentId: props.selectedUserLesson.lesson.userLessonContent[0].id,
                CorrectAnswer: 'Completed Text Lesson',
                IsCorrect: "true",
                LessonId: props.selectedUserLesson.lesson.id,
                Timestamp: ""
            })
            //Obtain the next user Lesson in the list
            let nextUserPartial = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);

            if(nextUserPartial !== null)
            {
                //UserLessonContent - Get First In the Array ( for Video )
                let TextContentToUpdate = props.selectedUserLesson.lesson.userLessonContent[0];
                TextContentToUpdate.isComplete = true;
                TextContentToUpdate.sectionId = props.parentModule.originalDocumentId;
                await userLessonContentController.UpdateUserLessonContent(TextContentToUpdate);

                let isUserLessonComplete = await userModuleController.UpdateUserLessonCompleteness(props.selectedUserLesson.id, nextUserPartial.id, props.parentModule.originalDocumentId);

                if(isUserLessonComplete)
                {
                    setComplete(true);
                    let parent = props.parentModule;
                    AdminTreeHelper.unMarkIsNext(parent);
                    //Update the Parent in the accordion
                    parent?.children?.forEach(firstChild => {
                        //look for next

                        if (firstChild.id == nextUserPartial.id) {
                            //Set Next
                            firstChild.isNext = true;
                            firstChild.isDisabled = false;
                            firstChild.isLocked = false;
                        }
                        if (firstChild.id == props.selectedUserLesson.id) {
                            //Unset Next
                            firstChild.isNext = false;
                            firstChild.isComplete = true;
                            firstChild.isLocked = false;
                            firstChild.isDisabled = false;
                        }
                        if (firstChild.children.length > 0) {
                            firstChild.children.forEach(secondChild => {
                                if (secondChild.id == nextUserPartial.id) {
                                    secondChild.isNext = true;
                                    secondChild.isDisabled = false;
                                    secondChild.isLocked = false;
                                }
                                if (secondChild.id == props.selectedUserLesson.id) {
                                    secondChild.isNext = false;
                                    secondChild.isComplete = true;
                                    secondChild.isDisabled = false;
                                    secondChild.isLocked = false;
                                }
                                if(secondChild.children.length >0){
                                    secondChild.children.forEach(thirdChild =>{
                                        if (thirdChild.id == nextUserPartial.id) {
                                            thirdChild.isNext = true;
                                            thirdChild.isDisabled = false;
                                            thirdChild.isLocked = false;
                                        }
                                        if (thirdChild.id == props.selectedUserLesson.id) {
                                            thirdChild.isNext = false;
                                            thirdChild.isComplete = true;
                                            thirdChild.isDisabled = false;
                                            thirdChild.isLocked = false;
                                        }
                                    })
                                }
                            })
                        }
                    })

                    props.updateAccordionFunction({
                        parentModule:parent,
                        selectedModule: props.selectedUserLesson,
                        nextLessonId: nextUserPartial.id,
                        selectedContent: UserLessonContent,
                        isUpdating:true,

                    })

                    if(lastLesson && lastLesson.id === props.selectedUserLesson.lesson.id) {
                        props.toggleCourseCompletion();
                    }
                }
            }

        }


        setInitalData(setUserLessonContent,setLesson,setTextContent, setDataLoaded,setComplete);
        completeLesson();



    }, [props.selectedUserLesson])

    async function navigateToNextLesson()
    {
        let parent = props.parentModule;
        let nextUserPartial = await userModuleController.GetNextUserLessonId(props.selectedUserLesson.id);

        console.error(nextUserPartial);

        props.updateAccordionFunction({
            parentModule: parent,
            selectedModule: nextUserPartial,
            nextLessonId: nextUserPartial.id,
            selectedContent: UserLessonContent,
            isUpdating: true,
        });
    }

    //Render
    return (
        <>
            <div>
                {dataLoaded && (TextContent != null) ?(
                    <>
                        <div className="quiz-content-panel">
                            {/* QUIZ HEADER */}
                            <div className='quiz-header'>
                                <div className="quiz-title">
                                    {/*// @ts-ignore*/}
                                    {TextContent?.title}
                                </div>
                            </div>
                            {/* QUIZ BODY SECTION */}
                            <div className='textLessonImage'>
                                {
                                    !StringHelper.IsNullOrWhiteSpace(TextContent.imageId as string) && 
                                    (
                                        <img  src={`${CloudflareImageHelper.imageUrl}${TextContent.imageId}/${CloudflareImageHelper.variants.pixelTrainerTextLesson}`} alt={TextContent.title+" Image"} />
                                    )
                                }                                
                            </div>
                            {/*// @ts-ignore*/}
                            <div className='quiz-instruction-text' dangerouslySetInnerHTML={{__html:TextContent?.text}}/>

                            {/* QUIZ FOOTER */}
                            <div className='quiz-footer'>
                                {
                                    lastLesson && lastLesson.id == props.selectedUserLesson.originalDocumentId ? 
                                    (
                                        <></>
                                    ) 
                                    : 
                                    (
                                        <>
                                            {complete ?(
                                                <>
                                                    <button onClick={()=> navigateToNextLesson()}
                                                        className='btn-cbit btn-primary quiz-footer-content'>NEXT LESSON
                                                    </button>
                                                </>
                                            ):(
                                                <>
                                                    <button onClick={()=>{}}
                                                        className='btn-cbit btn-disabled quiz-footer-content'>NEXT LESSON
                                                    </button>
                                                </>
                                            )}       
                                        </>
                                    )
                                }
                            </div>
                        </div>

                    </>
                ):(
                    <>
                        Data Is Loading
                    </>
                )}
            </div>
        </>
    )

}
export default TextLesson