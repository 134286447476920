import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { ModuleController } from "../../../controllers/ModuleController";
import { IModule } from "../../../models/module/Module";
import React from "react";
import { v4 as uuidV4 } from "uuid";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import CourseItem from "./CourseItem";
import "./CourseSelection.css";
import CreateCourseModal from "./CreateCourseModal";
import { CourseSelectContext } from "./Context/CourseSelectContext";

const moduleController = new ModuleController();

/**
 * Where admins select what is the main course for all users
 * @returns 
 */
export default function CourseSelection() {
    const [courses, setCourses] = useState<IModule[]>([]);

    useEffect(() => {
        getCourses();
    }, []);

    /**
     * Get a list of courses (aka sections, parent modules, modules without a parent)
     */
    const getCourses = async () => {
        try {
            const modules = await moduleController.GetParentModules();
            setCourses(modules);
        } catch (error) {
            toast.error("Failed to get modules");
            console.error(error);
        }
    }

    /**
     * Add a course item to the courses list
     */
    const addCourseToList = (course:IModule) => {
        const currentCourses:IModule[] = DeepCopy.copy(courses);
        currentCourses.push(course);        
        setCourses(currentCourses);
    }

    /**
     * Updates the course list with a new course list
     */
    const updateCourses = (updatedCourseList:IModule[]) => {
        setCourses(updatedCourseList);
    }

    /**
     * Update a single course in the current list of courses state
     * @param course 
     */
    const updateCourseInList = (course:IModule) => {
        const currentCourses:IModule[] = DeepCopy.copy(courses)
        const id = course.id;
        const foundIndex = currentCourses.findIndex(c => c.id === id);
        
        //The course does not exist in the state
        if(foundIndex === -1) {
            currentCourses.push(course);                        
        } else {
            currentCourses[foundIndex] = course;
        }

        setCourses(currentCourses);
    }

    return (
        <CourseSelectContext.Provider value={{courses, updateCourses}}>
        <div className="course-selection cbit-dialog">
            <div className="course-header-container">
                <h1 className="content-mana-header cbit-dashboard-header">Course Selection</h1>
                <CreateCourseModal
                    updateCourses={addCourseToList}
                />
            </div>
            <div className="course-container">
            {courses.map(course => (
                <React.Fragment key={uuidV4()}>
                    <CourseItem
                        updateCourseInList={updateCourseInList}
                        course={course}
                    />
                </React.Fragment>
            ))}
            </div>            
        </div>
        </CourseSelectContext.Provider>
    )
}