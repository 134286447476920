import {IModule} from "../models/module/Module";
import {RouteComponentProps} from "react-router";
import {ILesson, LessonIconEnum} from "../models/lesson/Lesson";
import {Action, Reducer} from "redux";
import {LessonTypeEnum} from "../models/lesson/LessonType";

export type crumbs = {
    name:string,
    id:string,
}

export interface CurrentPartialTreeViewState
{
    breadcrumbs:crumbs[],
    parentModule:IModule,
    selectedModule: ILesson,
    isNavigating: boolean,
}

export type currentTreeViewProps = CurrentPartialTreeViewState
& typeof actionCreators
& RouteComponentProps<{}>

export interface GetPartialTreeView { type: 'GET_PARTIALTREEVIEW' }
export interface SetPartialTreeView { type: 'SET_PARTIALTREEVIEW' , currentState: CurrentPartialTreeViewState }
export interface RemovePartialTreeView { type: 'REMOVE_PARTIALTREEVIEW' }

export type KnownAction = GetPartialTreeView| SetPartialTreeView | RemovePartialTreeView;

export const actionCreators = {
    GetPartialTreeView: () => ({type: 'GET_PARTIALTREEVIEW'} as GetPartialTreeView),
    SetPartialTreeView: (state:CurrentPartialTreeViewState) => ({ type: 'SET_PARTIALTREEVIEW', currentState:state } as SetPartialTreeView),
    RemovePartialTreeView: () => ({ type: 'REMOVE_PARTIALTREEVIEW' } as RemovePartialTreeView)
};

export const unloadedState: CurrentPartialTreeViewState =
    {
        breadcrumbs: [],
        parentModule: {
            id: '',
            name: '',
            parentModule: '',
            description: '',
            order: 0,
            type: 0,
            modules: [],
            lessons: [],
            combinedList: [],
            createdAt: '',
            updatedAt: '',
            courseId: ""
        },
        selectedModule: {
            id: '',
            branchId: '',
            courseId: "",
            name: '',
            description: '',
            duration: '',
            order: 0,
            type: LessonTypeEnum.custom,
            tagList: [],
            lessonContent: [],
            userLessonContent: [],
            IsLocked: false,
            IsOptional: false,
            isViewable: false,
            IsComplete: false,
            IsDisabled: false,
            IsNext: false,
            lessonIcon: LessonIconEnum.Unknown,
            totalQuestions: 0,
            createdAt: '',
            updatedAt: '',
        },
        isNavigating:false
    }

export const reducer: Reducer<CurrentPartialTreeViewState> = (state: CurrentPartialTreeViewState | undefined, incomingAction: Action): CurrentPartialTreeViewState => {
    if (state === undefined) {
        return  unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'GET_PARTIALTREEVIEW':
            return {
                breadcrumbs: state.breadcrumbs,
                parentModule: state.parentModule,
                isNavigating:state.isNavigating,
                selectedModule: state.selectedModule
            };
        case 'SET_PARTIALTREEVIEW':
            return {
                breadcrumbs: action.currentState.breadcrumbs,
                isNavigating:action.currentState.isNavigating,
                parentModule: action.currentState.parentModule,
                selectedModule: action.currentState.selectedModule
            };
        case 'REMOVE_PARTIALTREEVIEW':
            return unloadedState;
        default:
            return state;
    }
};