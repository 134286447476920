import {LessonContent} from "./LessonContent";
import {IUserDocument} from "../module/UserModule";

export class UserLessonContent implements IUserDocument
{
    id="";
    userProfileId="";
    originalDocumentId="";
    parentUserDocumentId="";
    parentDocumentId="";
    sectionId:string|null=null;
    order:number=0;
    completion="";
    isViewable=false;
    isDisabled=false;
    isEditable=false;
    isOptional=false;
    isComplete=false;
    time='';
    isCorrect=false;
    savedAnswer:SelectableChoice[] = [];
    Outdated =false;
    VersionAt ='';
    lessonContent:LessonContent = new LessonContent();
}

export class SelectableChoice {
    order =-1;
    text="";
    isCorrect=false;

}