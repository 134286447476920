import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import {ErrorHandler} from "../../models/utility/ErrorHandler";


const baseLocalHostUrl = 'https://localhost:44386/api';
const baseDevHostUrl = 'https://api.pixeltrainer.com/api';
const baseProdHostUrl = 'https://api.pixeltrainer.com/api';

interface IBaseReactService
{
    baseAxiosConfiguration:AxiosRequestConfig;
    GetAll(specificLocation?:string):any;
    Get(params:any, specificLocation?:string):any;
    Put(model:any, specificLocation?:string):any;
    Post(model:any, specificLocation?:string):any;
    Delete(params:any, specificLocation?:string):void;
}

export class BaseReactService implements IBaseReactService{
 
    constructor(providedEndpoint:string){
        if (process.env.REACT_APP_DEV_ENV === 'production') {
            this.baseUrl = baseProdHostUrl;
        }
        else if(process.env.REACT_APP_DEV_ENV === 'development') {
            this.baseUrl = baseDevHostUrl;
        }else{
            this.baseUrl = baseLocalHostUrl;
        }

        this.endPoint = providedEndpoint;
        this.apiLocation = `${this.baseUrl}/${this.endPoint}`;

    }
    
    private baseUrl = baseLocalHostUrl;
    private endPoint = "";
    private apiLocation = "";


    
    public baseAxiosConfiguration:AxiosRequestConfig = {
        withCredentials:true,
        headers: {'Content-Type': 'application/json'}
    }

    /**
     * Get url of api endpoint. Mainly used to get files from the api
     * @param endpointLocation 
     * @param id 
     * @returns 
     */
    public GetUrl(endpointLocation:string, id?:string) {
        return this.GetEndpoint(endpointLocation, "Get", id);
    }

    /**
     * Get url of api endpoint and add passed in query params
     * @param params 
     * @param endpointLocation 
     * @returns 
     */
    public GetCustomUrl(params: {}, endpointLocation:string) {
       let path = new URL(this.GetEndpoint(endpointLocation, "Get"));
       
       for(const [key, val] of Object.entries(params)) {
            //`${val}` is to convert the value to a string regardless if it is an number, object, etc
            path.searchParams.append(key, `${val}`);
       }

       return path;
    }
    
    private GetEndpoint(endpointLocation?:string, action?:string, id?:string){

        var endpoint = `${this.baseUrl}`
        if(endpointLocation != null){
            endpoint +=`/${endpointLocation}`;
        }else{
            endpoint =`${this.apiLocation}${action}/`;
        }
        
        if(id != null){
            endpoint+= id;
        }
        
        return endpoint;
    }
    
    private GetConfigWithParams(params:any){
        var config = this.baseAxiosConfiguration;
        config.params = params;
        return config;
    }
    
    //Calls Get with baseline config
    public async GetAll(specificLocation?:string){
        try{
            var response:AxiosResponse = await axios.get(this.GetEndpoint(specificLocation,"Get"),this.baseAxiosConfiguration)
            return response.data;
        }catch(error){
            ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    //Set URL Parameters { Id: Value, other: Value2 }  =>  Server Will look like Get (string Id, any other)
    public async Get( params: { id:string }, specificLocation?:string){
        try{
            var response:AxiosResponse = await axios.get(this.GetEndpoint(specificLocation,`Get`,params.id),this.GetConfigWithParams(params))
            return response.data;
        }catch(error){
            ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CustomGet(params: {}, specificLocation?: string) {
        try {
            var response: AxiosResponse = await axios.get(
                this.GetEndpoint(specificLocation, `Get`),
                this.GetConfigWithParams(params)
            );
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    /**
     * Performs a get with request with an object as the query params
     * You may want to rewrite incase it does not work with your object
     * @param modal 
     * @param specificLocation 
     * @returns 
     */
    public async GetWithObject(modal:any, specificLocation?:string) {        
        let endPoint = new URL(this.GetEndpoint(specificLocation, "Get"));        
            
        Object.keys(modal).forEach(key => {
                
                if(Array.isArray(modal[key])) {
                    if(modal[key].length > 0) {                                                
                        Object.values(modal[key]).forEach((v:any) => endPoint.searchParams.append(key, v))
                    }
                } else {
                    endPoint.searchParams.append(key, modal[key])
                }            
        });
        
        const temp = endPoint.searchParams.toString();

 
        
            
         try {
            var response:AxiosResponse = await axios.get(endPoint.toString(), this.baseAxiosConfiguration)
            return response.data;
         } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
         }                 

    }
    
    //Calls Post with just a model
    public async Post(model:any, specificLocation?:string){
        try{
            var endpoint = this.GetEndpoint(specificLocation, "Post");
            var response:AxiosResponse = await axios.post(endpoint, model, this.baseAxiosConfiguration)
            return response.data;
        }catch(error){
          return ErrorHandler.catchApiError((error as AxiosError));
        }
    }


    public async AltPost(model:any, specificLocation?:string){
        try{
            var endpoint = this.GetEndpoint(specificLocation, "Post");
            var response:AxiosResponse = await axios.post(endpoint, model, this.baseAxiosConfiguration)
            return response.data;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    public async Patch(params:{ id:string}, specificLocation?:string){
        try {
            
            var response:AxiosResponse = await axios.patch(this.GetEndpoint(specificLocation, "Patch", params.id), this.baseAxiosConfiguration);
            return response.data;
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    /**
     * Perform a patch where data is inside the body instead of params
     * @param params 
     * @param body 
     * @param specificLocation 
     * @returns 
     */
    public async PatchWithBody(params:{id:string}, body:any, specificLocation?:string) {
        try {
            var response:AxiosResponse = await axios.patch(this.GetEndpoint(specificLocation, "Patch", params.id), body, this.baseAxiosConfiguration);
            return response.data;
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    public async CustomPatch (params: {}, specificLocation?: string) {
        try {
            var response:AxiosResponse = await axios.patch(this.GetEndpoint(specificLocation, "Patch"), '', this.GetConfigWithParams(params));
            return response.data;
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }


    //Calls to make a put call
    public async Put(params:{ id:string }, model:any, specificLocation?:string){
        try{
            var response:AxiosResponse = await axios.put(this.GetEndpoint(specificLocation,`Put`,params.id), model, this.GetConfigWithParams(params));
            return response.data;
        }catch(error){
            ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CustomPut(id:string, params:any, specificLocation?:string){
        try{                        
            var response:AxiosResponse = await axios.put(this.GetEndpoint(specificLocation,`Put`, id),'',  this.GetConfigWithParams({...params}))
            return response.data;
        }catch(error){
            console.error(error);

            ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    //For Single Primary Type
    public async PatchPut(id:string, value:any, specificLocation:string){
        try{
            var endpoint = this.baseUrl + specificLocation +id;

            var response:AxiosResponse = await axios.put(endpoint,value,  this.baseAxiosConfiguration)
            return response.data;
        }catch(error){
            ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    //Calls Delete with params
    public async Delete(params:{ id:string }, specificLocation?:string){
        try{
            var response:AxiosResponse = await axios.delete(this.GetEndpoint(specificLocation,`Delete`, params.id), this.GetConfigWithParams(params));
        }catch(error){
            ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    public async CustomDelete(params: {}, specificLocation?: string) {
        try {
            var response: AxiosResponse = await axios.delete(
                this.GetEndpoint(specificLocation, `Delete`),
                this.GetConfigWithParams(params)
            );
            return response.data;
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}


