
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory, useLocation } from 'react-router-dom';
import { UserController } from '../../../controllers/UserController';
import useAuth from '../../../hooks/UseAuth';
import { ICurrentUser } from '../../../models/User/UserProfile';

// types
import { GuardProps } from '../../../models/Types/Auth';
import Roles from '../../../models/utility/Roles';
import { ApplicationState } from '../../../store';
import { actionCreators, CurrentUserState } from '../../../store/CurrentUser';
import {NotificationController} from "../../../controllers/NotificationController";

const userController = new UserController();
const nController = new NotificationController();

/**
 * Route Wrapper for check if a user should still be logged in
 * @returns 
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { isLoggedIn } = useAuth();  

  const [isLoading, setIsLoading] = useState(true);
  
  const history = useHistory();

  const location = useLocation();

  const dispatch = useDispatch();

  const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);

  useEffect(() => {
    async function onPageLoad() {
        //Temp fix but need to check for public routes    
        // if(location.pathname === "/sign-up") {
        //         return;
        //     }
            await checkUserStatus();
        
    }
    onPageLoad();

    return () => {
        setIsLoading(false);
    }

  }, [isLoggedIn, history.replace, dispatch,  location.pathname]);

  /**
   * Check the status of the user
   * Send the user to the login page if the don't have access
   * Set the redux store if they should still have access
   * @returns 
   */
  const checkUserStatus = async () => {
    const {setUser, removeUser} = actionCreators;

    try {  
            // setIsLoading(true);     

            const userProfile = await userController.CheckUserStatus();
            const userNotificationCount = await nController.GetUnreadNotificationsCount(userProfile.id);

            const currentUser:ICurrentUser = {
                id: userProfile.id,
                firstName: userProfile.firstName,
                lastName: userProfile.lastName,
                username: userProfile.name,
                roles: userProfile.roles,                
                imageLink: userProfile.imageLink,                
                isBanned: userProfile.isBanned,
                visitedSocialConnection: userProfile.visitedSocialConnection,
                completedTraining: userProfile.completedTraining,
                assignedSectionId: userProfile.assignedSectionId,                                                
                email: userProfile.email,                
            }
        
            const userState:CurrentUserState = {
                userProfile:currentUser, 
                isAdmin: userProfile.roles.includes(Roles.Admin),
                isSuperAdmin: userProfile.roles.includes(Roles.SuperAdmin),
                isLoggedIn: true,
                notificationCount: userNotificationCount
            }

            dispatch(setUser(userState));

            setIsLoading(false);
        } catch (error) {            
            dispatch(removeUser());
            setIsLoading(false);
            history.replace("/");                
        }

  }

  return (
    <>
        {
             isLoading ? 
            (
                null
            ) 
            :
            (
                <>
                    {
                        !isLoggedIn ? 
                        (
                            null
                        ) 
                        : 
                        (
                            children
                        )
                    }                
                </>

            )
        }
    </>
  )
};

export default AuthGuard;
