import { RouteComponentProps } from 'react-router';
import { Action, Reducer } from 'redux';
import { ICurrentUser } from "../models/User/UserProfile";


// TODO: Moved this to another file
export type UserRoles = "USER" | "FREE_USER" | "ADMIN";

// CurrentUserState 
export interface CurrentUserState {
    userProfile: ICurrentUser,
    isAdmin: boolean,
    isSuperAdmin:boolean,
    isLoggedIn: boolean,
    notificationCount: number
}

export type currentUserProps = CurrentUserState
& typeof actionCreators
& RouteComponentProps<{}>

//Actions available for the user store
export const userStoreAvailableActions = {
    getCurrentUser: 'GET_CURRENT_USER',
    setCurrentUser: 'SET_CURRENT_USER',
    removeCurrentUser: 'REMOVE_CURRENT_USER',
    getNotificationCount: 'GET_NOTIFICATION_COUNT',
    updateNotificationCount: 'UPDATE_NOTIFICATION_COUNT'
}

// ACTIONS
interface GetCurrentUser {
    type: 'GET_CURRENT_USER'
}

interface SetCurrentUser {
    type: 'SET_CURRENT_USER', currentUser: CurrentUserState
}

interface RemoveCurrentUser {
    type: 'REMOVE_CURRENT_USER'
}

interface UpdateNotificationCount {
    type: 'UPDATE_NOTIFICATION_COUNT',
    count: number
}

// Limits the action to be between GetCurrentUser
type KnownAction = GetCurrentUser | SetCurrentUser | RemoveCurrentUser | UpdateNotificationCount;

// Creating the actions
export const actionCreators = {
    getUser: () => ({type: 'GET_CURRENT_USER'} as GetCurrentUser),
    setUser: (user: CurrentUserState) => ({type: 'SET_CURRENT_USER', currentUser: user} as SetCurrentUser),
    removeUser: () => ({type: 'REMOVE_CURRENT_USER'} as RemoveCurrentUser),
    updateNotificationCount: (count?: number) => ({type: 'UPDATE_NOTIFICATION_COUNT', count:count} as UpdateNotificationCount),
};

const unloadedState: CurrentUserState = {
    userProfile: {
        id: '',
        firstName: '',
        lastName: '',
        username: '',
        roles: [],
        visitedSocialConnection: false,
        isBanned: false,        
        imageLink: '',        
        completedTraining: '',
        assignedSectionId: '',                        
        email: '',        
    }, 
    isAdmin: false, 
    isSuperAdmin:false,
    isLoggedIn: false,
    notificationCount: 0
}

export const reducer: Reducer<CurrentUserState> = (state: CurrentUserState | undefined, incomingAction: Action): CurrentUserState => {
    if(state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;

    switch(action.type) {
        case 'GET_CURRENT_USER':
            return {
                userProfile: state.userProfile,
                isAdmin: state.isAdmin,
                isSuperAdmin: state.isSuperAdmin,
                isLoggedIn: state.isLoggedIn,
                notificationCount: state.notificationCount
            }
        case 'SET_CURRENT_USER':
            return {
                userProfile: action.currentUser.userProfile,
                isAdmin: action.currentUser.isAdmin,
                isSuperAdmin: action.currentUser.isSuperAdmin,
                isLoggedIn: action.currentUser.isLoggedIn,
                notificationCount: action.currentUser.notificationCount
            }
        case 'UPDATE_NOTIFICATION_COUNT':
            return {
                ...state,
                userProfile: state.userProfile,
                isAdmin: state.isAdmin,
                isSuperAdmin: state.isSuperAdmin,
                isLoggedIn: state.isLoggedIn,
                notificationCount:  action.count

            }
        
        case 'REMOVE_CURRENT_USER':            
            return unloadedState;        

        default:
            return state;
    }
};