import { AxiosError } from 'axios';
import { IModule, PartialModuleType } from "../models/module/Module";
import { AdminTreeModule, IAdminTreeModule } from '../models/partialModels/AdminTreeModule';
import { PartialModuleTreeModel, TreeViewType } from "../models/partialModels/PartialModuleTreeModel";
import { ErrorHandler } from "../models/utility/ErrorHandler";
import { LessonService } from "../services/LessonService";
import { ModuleService } from "../services/ModuleService";
import moment from "moment/moment";
import { StringHelper } from '../models/utility/StringHelper';
import { PublishedModuleService } from '../services/PublishedModuleService';
import { PublishedLessonService } from '../services/PublishedLessonService';

// Class for interacting with the EduTrainer API Module Endpoints
export class PublishedModuleController{

    constructor(){
        this.ModService = new PublishedModuleService()
        this.LessonService = new PublishedLessonService();
    }

    private ModService:PublishedModuleService;
    private LessonService:PublishedLessonService;
    
    // Sends a POST request to create a module
    public async CreateModule(moduleData : IModule) 
    {
       try{
           moduleData.createdAt =moment(new Date()).toISOString();
           moduleData.updatedAt =moment(new Date()).toISOString();
           return this.ModService.Post(moduleData);
       }catch(error){
           return ErrorHandler.catchApiError((error as AxiosError))
       }
    }

    // sends a GET request to get an array of modules
    public async GetModule(id: string)
    {
        try{
            return await this.ModService.Get({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModuleWithChildren(id:string){
        try{
            let module:IModule = await this.ModService.Get({id});
            if(module != null){
                
                let childModules:PartialModuleType[] = await this.ModService.GetModulesWithParentId({id});
                let lessonModules:PartialModuleType[] = await this.LessonService.GetLessonsWithParentId({id});
                module.combinedList = childModules;
                if(module.combinedList)
                    module.combinedList = module.combinedList.concat(lessonModules);
                
            }
            return module;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModuleDepthById(id:string){
        try{
            let value = await this.ModService.GetModuleDepthById({id});
            return value;
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    public async GetModules()
    {
        try{
            return this.ModService.GetAll();
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    /**
     * Gets a list of modules that are courses (Top parent module) 
     */
    public async GetCourses() {
        try {
            return await this.ModService.GetCourses();
        } catch (error) {
            throw error;
        }
    }

    public async GetAvailableModulesByParentModule(id:string){
        try{
            return this.ModService.GetAvailableModulesByParentModule({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    };

    public async GetDeletedModules()
    {
        try {
            return this.ModService.GetDeletedModules();
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }
    
    public async GetPartialModuleByParentId(id:string): Promise<PartialModuleType[]>{
        try{
            const modules: IModule[] = await this.ModService.GetModulesWithParentId({id});
            const partialModules = modules.map((module)=>
                ({
                    id: module.id,
                    name: module.name,
                    description: module.description,
                    isLesson:false,
                    order: module.order
                }))

            return partialModules;
            
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }
    
    /**
     * Get a parent module's children and convert them to an array of AdminTreeModules
     * @param id 
     * @returns 
     */
    // public async GetAdminTreeModuleByParentId(id:string): Promise<IAdminTreeModule[]> {
    //     try {
    //         const modules:IModule[] = await this.ModService.GetModulesWithParentId({id});

    //         return modules.map((mod) => new AdminTreeModule({
    //             ...mod,
    //             type: TreeViewType.Branch,
    //             parentId: mod.parentModule ?? "",
    //             children: [],                
    //             duration: "",                
    //             isViewable:mod.isViewable ? mod.isViewable : false,
    //         }))
            
    //     } catch (error) {
    //         return ErrorHandler.catchApiError((error as AxiosError))
    //     }
    // }
    
    public async GetModulesWithParentId(id:string) {
        try{
            return this.ModService.GetModulesWithParentId({id});
        }catch(error){
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    /**
     * Get the topmost modules
     */
    public async GetParentModules() {
        try {
            const response:IModule[] = await this.ModService.GetAll();            
            const parentModules = response.filter((module) => module.parentModule == null);
            return parentModules;            
        } catch (error) {
            return ErrorHandler.catchApiError((error as AxiosError))
        }
    }

    public async UnlockUserLessons(id:string)
    {
        const endpoint = 'Module/UnlockNextLesson/'
        try
        {
            return this.ModService.Get({id},endpoint);
        }catch(error)
        {
            return ErrorHandler.catchApiError((error as AxiosError));
        }
    }

    // sends a GET request to get an individual module when given an ID
    // TODO: Combine this with GetModules
    // static async GetModuleWithID(moduleID : string) : Promise<IModule | never> {
    //     const endPoint = `/Module/Get/${moduleID}`;

    //     try {
            
    //         const response : AxiosResponse<IModule> = await axios.get(`${this.baseUrl}${endPoint}`);
            
    //         return response.data;

    //     } catch (error) {
    //         return this.handleError((error as AxiosError));
    //     }
    // }

    /**
     * Publish the entire course by updating published modules,
     * lessons, and lesson content with the regular versions
     */
    public async PublishCourse(sectionId:string) {
        try {
            return await this.ModService.PublishEntireCourse(sectionId);
        } catch (error) {
            throw error;
            
        }
    }

    /**
     * Updates a single instance of a module
     * @param obj 
     * @returns 
     */
    public async UpdateModule(obj : IModule) 
    {
        try{
            let id = obj.id;
            const params = {id}
            obj.updatedAt = moment(new Date()).toISOString();

            return await this.ModService.Put(params,obj);
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Updates the last modified field for a module
     * @param id 
     * @returns 
     */
    public async UpdateLastModified(id:string) {
        try {
            if(id == null || StringHelper.IsNullOrWhiteSpace(id)) {
                return;
            }

            return await this.ModService.UpdateLastModified({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Fully deletes a module from the database
     * @param id 
     * @returns 
     */
    public async DeleteModule(id : string) 
    {
        try{
            return await this.ModService.Delete({id});
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Permanently delete a course and all of its contents. This will delete user modules/etc also
     * @param courseId 
     * @returns 
     */
    public async DeleteCourse(courseId:string) {
        try {
            return await this.ModService.DeleteCourse(courseId);
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    /**
     * Softly deletes a module by updating the deleted at timestamp
     * @param id 
     * @returns 
     */
    public async DeleteModuleSoftly(id:string)
    {
        try {
            return await this.ModService.DeleteModuleSoftly({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Restores a softly deleted module by removing the deleted at timestamp
     * @param id 
     * @returns 
     */
    public async RestoreDeletedModule(id:string)
    {
        try {
            return await this.ModService.RestoreDeletedModule({id});
        } catch (error) {
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
    
    // Get an array of modules and extract only partial amount of information from modules
    public async GetPartialModules() : Promise<PartialModuleType[] | never> 
    {
        // Get an array of modules
        try{
            const modules: IModule[] = await this.ModService.GetAll();
            const partialModules = modules.map((module)=>
                ({
                    id: module.id,
                    name: module.name,
                    description: module.description,
                    isLesson:false,
                    order: module.order,
                }))

            return partialModules;
        }catch(error){
            return ErrorHandler.catchApiError(error as AxiosError);
        }
    }
}


