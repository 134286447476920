import {BaseReactService} from "./interfaces/BaseReactService";
import {ErrorHandler} from "../models/utility/ErrorHandler";
import { AxiosError } from "axios";
import { ILesson } from "../models/lesson/Lesson";

export class LessonService extends BaseReactService {

    constructor(){
        super("Lesson/");
    }  

    public async GetLessonsWithParentId(id: { id:string }){
        const endpoint = 'Lesson/WithBranchId/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async UpdateLessonLastModified(id:string) {
        const endpoint = 'Lesson/LastModifiedUpdate';
        try {            
            return await this.CustomPatch({id}, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    public async CompleteUserLesson(id: {id:string}){
        const endpoint = 'Lesson/CompleteUserLesson/'
        try{
            return await this.Get(id,endpoint);
        }catch(error){
            ErrorHandler.catchApiError(error as AxiosError)
        }
    }

    public async GetTotalQuizQuestions(lessonId:string) {
        const endpoint = 'lesson/GetTotalQuizQuestions/';
        try {
            return await this.CustomGet({lessonId}, endpoint);
        } catch (error) {
            ErrorHandler.catchApiError(error as AxiosError);
        }
    }

    /**
     * Perma deletes a lesson and its lesson contents
     * @param lessonId 
     * @returns 
     */
    public async DeleteLesson(lessonId:string) {
        const endpoint = 'lesson/DeleteLesson/';
        try {
            return await this.CustomDelete({lessonId}, endpoint);
        } catch (error) {
            throw error;
        }
    }
}