import { ModuleController } from "../../../controllers/ModuleController";
import CustomModal from "../../Utilities/CustomModal";
import * as Yup from "yup";
import * as Dompurify from "dompurify";
import FormHelper from "../../../models/utility/FormHelper";
import { ErrorMessage, Formik } from "formik";
import { domPurifyOpts } from "../../Utilities/DomPurifyOptions";
import { IModule, Module } from "../../../models/module/Module";
import toast from "react-hot-toast";
import { useState } from "react";
import "./CreateCourseModal.css";

type propsType = {
    updateCourses:(course:IModule) => void
    selectedCourse?:IModule;
}

const modController = new ModuleController();

type formValues = {
    name:string
}

/**
 * Modal popup for creating courses
 * @param props 
 * @returns 
 */
export default function CreateCourseModal(props:propsType) {
    const {
        updateCourses,
        selectedCourse
    } = props;

    const [isLoading, setIsLoading] = useState(false);
    const [isFormOpen, setFormOpen] = useState(false);

    const initialValues:{name:string}= {
        name: selectedCourse ? selectedCourse.name : ""
    }

    const yupValidationObj = {
        name: Yup.string()
                .required(FormHelper.requiredErrorMsg("name"))
                .min(2, FormHelper.minErrorMsg("Name", 2))
                .max(100, FormHelper.maxErrorMsg("Name", 32)),
    }

    const validationSchema = Yup.object(yupValidationObj);

    /**
     * Creates a new course module
     * @param name 
     * @returns 
     */
    const createNewCourse = async (name:string) => {
        const module = new Module();
        module.name = name;
        module.isCourse = true;
        const newMod = await modController.CreateNewCourse(module);
        updateCourses(newMod);
    }

    /**
     * updates the course name
     * @param name 
     * @returns 
     */
    const updateCourse = async (newName:string) => {
        if(!selectedCourse) {
            return;
        }

        selectedCourse.name = newName
        const updatedMod = await modController.UpdateCourse(selectedCourse)
        updateCourses(updatedMod);
    }


    /**
     * validates input and creates a module on form submission
     * @param formData 
     * @param formik 
     */
    const onFormSubmit = async (formData:formValues, formik:any) => {
        const {name} = formData;

        const cleanedName = Dompurify.sanitize(name, domPurifyOpts);

        const module = new Module();

        module.name = cleanedName;
        module.isCourse = true;

        const loadId = "createCourseLoad";

        try {
            toast.loading("Creating course", {id: loadId});
            setIsLoading(true);
            
            selectedCourse ? updateCourse(cleanedName) : createNewCourse(cleanedName);
                                              
            formik.resetForm();  
            setFormOpen(false);
        } catch (error) {
            console.error(error);
            toast.error("Failed to create course");
        }

        toast.dismiss(loadId);
        setIsLoading(false);
     }
     
     /**
      * Decides between displaying edit text or the default text
      * @param defaultText 
      * @param editText 
      * @returns 
      */
     const displayEditText = (defaultText:string, editText:string) => {
        return selectedCourse ? editText : defaultText;                     
     }

    return (
        <>
            {
                selectedCourse ? 
                (
                    <button 
                        onClick={() => setFormOpen(true)}
                        className="btn-cbit-link">
                        Rename
                    </button>
                ) 
                : 
                (
                    <button 
                    onClick={() => setFormOpen(true)}
                    className="btn-cbit-primary">
                        Create Course
                    </button>
                )
            }

            <CustomModal 
                isOpen={isFormOpen}                
                toggle={() => setFormOpen(!isFormOpen)}
            >
                <div className="cbit-dialog">

                <div className="cbit-header">Give your course a name</div>
                <Formik
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                    onSubmit={onFormSubmit}
                    >
                {({errors, handleChange, handleSubmit, handleBlur, values}) => (
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <div className="form-label-input-container">
                                <label htmlFor="name" className="cbit-label">Name</label>
                                <input 
                                    id="name"
                                    name="name"
                                    className="form-input"
                                    type="text" 
                                    value={values.name}
                                    placeholder="Name"
                                    onChange={handleChange} 
                                    onBlur={handleBlur}
                                    />
                            </div>
                            <div className="error-message-container">
                                <ErrorMessage name={"name"} />
                            </div>
                        </div>
                        <div className="cbit-dialog-btn-container">
                            <button 
                                disabled={isLoading}
                                type="submit"                            
                                className="btn-cbit-primary">
                                    {displayEditText("Submit", "Update")}
                            </button>
                        </div>
                    </form>
                )}
                </Formik>                        
                </div>
            </CustomModal>
        </>
    )
}