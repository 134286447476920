import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import PrivateRoutes from "../../../Routes/PrivateRoutes";
import PublicRoutes, { validPages } from "../../../Routes/PublicRoutes";
import { ApplicationState } from "../../../store";
import { CurrentUserState } from "../../../store/CurrentUser";

const UrlWhitelist = () => {
    const userStore = useSelector<ApplicationState, CurrentUserState | undefined>(state => state.currentUser);
    
    const [isPublicRoute, setIsPublicRoute] = useState(true);

    const history = useHistory();

    useEffect(() => {
        let {pathname} = window.location;

        if (validPages.includes(pathname)) {
            setIsPublicRoute(true)
        } else {
            setIsPublicRoute(false);
        }

    });

    return (
        <>
            {isPublicRoute ? <PublicRoutes/> : <PrivateRoutes/>}
        </>
    )
}

export default UrlWhitelist;