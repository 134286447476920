import { LessonContentType } from '../../../../../models/contentTypes/LessonContentType';
import { ISelectableChoice } from '../../../../../models/contentTypes/SelectableChoice';
import { ILessonContent } from '../../../../../models/lesson/LessonContent';
import './AdminContentItemView.css';
import QuizViewItem from './AdminViewItems/QuizViewItem';
import TextViewItem from './AdminViewItems/TextViewItem';
import VideoViewItem from './AdminViewItems/VideoViewItem';

type AdminContentViewerProps = {
    lessonContent:ILessonContent
    imageFile?:File
}

/**
 * Component for viewing the content in a content item.
 * AKA show how videos, text, and quiz will look
 */
const AdminContentItemViewer = (props:AdminContentViewerProps) => {
    
    const {lessonContent, imageFile} = props;    

    /**
     * Renders a view based on the content type 
     */
    const renderViewItem = () => {
        
        const contentType = lessonContent.type;
        
        switch(contentType) {
            case LessonContentType.video:
                return (
                    < VideoViewItem  
                        videoLink={lessonContent.videoProperties?.videoLink as string} 
                        videoName={lessonContent.videoProperties?.videoName as string} 
                        videoDescription={lessonContent.videoProperties?.videoDescription as string} 
                    /> 
                );

            case LessonContentType.text:
                return (
                    <TextViewItem 
                        imageId={lessonContent.textProperties?.imageId as string} 
                        bodyText={lessonContent.textProperties?.text as string} 
                        imageFile={imageFile}
                    />
                );

            case LessonContentType.multipleChoice:
                return (<QuizViewItem answerChoices={lessonContent.multipleChoiceProperties?.choices as ISelectableChoice[]}/>);
            
            case LessonContentType.trueOrFalse:
                return (<QuizViewItem answerChoices={lessonContent.multipleChoiceProperties?.choices as ISelectableChoice[]}/>)
            
            default:
                return <></>
        }
    }
    
    return (
        renderViewItem()
    )
}

export default AdminContentItemViewer;
