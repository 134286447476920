import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { UserController } from '../../../controllers/UserController';
import { UserWithCourse } from '../../../models/User/UserWithCourse';
import { DeepCopy } from '../../../models/utility/DeepCopy';
import CustomAlert from '../../Utilities/CustomAlert';
import './UserManagement.css';
import UserManagementSearch from './UserManagementSearch';
import UserManagementTable from './UserManagementTable';

type UserManagementState = {
    users:UserWithCourse[]
    originalUsers:UserWithCourse[]
    isLoading:boolean    
    userToDelete:UserWithCourse | null
}

const userController = new UserController();

/**
 * Component for creating, updating, and deleting users
 */
const UserManagement = () => {
    const defaultState:UserManagementState = {
        users: [],
        originalUsers:[],
        isLoading:false,        
        userToDelete:null
    }

    const [state, setState] = useState(defaultState);

    const {users, isLoading, originalUsers} = state;

    const [isDeleteAlertOpen, setDeleteAlert] = useState(false);

    useEffect(() => {
        async function onComponentMount() {
            await getUsers();
        }
        onComponentMount();
    }, []);

    /**
     * Get a list of user bios
     */
    const getUsers = async () => {
        setState(prevState => ({...prevState, isLoading:true}));

        try {            
            const users:UserWithCourse[] = await userController.GetUsersForManagement()

            setState(prevState => ({
                ...prevState,
                users,
                originalUsers:users,
                isLoading:false
            }));
            
        } catch (error) {
            console.error(error);
            toast.error("Failed to retrieve users");
            setState(prevState => ({...prevState, isLoading:false}));
        }
    }

    /**
     * Permanently deletes a user
     * TODO in the future you may want to disable a user instead
     */
    const deleteUser = async (userId:string) => {
        if(userId.length < 1) return;

        toast.loading("Deleting User...", {id:"deleteLoadToast"});
        
        setState(prevState => ({...prevState, isLoading:true}));
        try {
            await userController.DeleteUser(userId);    
        
            let updatedUsers:UserWithCourse[] = DeepCopy.copy(state.users); 

            updatedUsers = updatedUsers.filter(user => user.id !== userId);

            toggleDeleteAlert(); 

            setState(prevState => ({
                ...prevState,
                users:updatedUsers,
                isLoading:false,
            }));

            toast.dismiss("deleteLoadToast");
            toast.success(`Successfully deleted user`);

        } catch (error) {
            toast.dismiss("deleteLoadToast");
            console.error(error);
            toast.error("Failed to delete user");
        }
    }

    /**
     * Open or closes the delete alert modal
     * @returns 
     */
    const toggleDeleteAlert = () => (setDeleteAlert(!isDeleteAlertOpen));

    /**
     * Set a user to be deleted in state and toggle the delete alert
     * @param userToDelete 
     */
    const toggleDeleteAlertAndSetUserToDelete = (userToDelete:UserWithCourse) => {              
        setState(prevState => ({...prevState, userToDelete}));
        toggleDeleteAlert(); 
    }

    /**
     * For children components to update user state
     */
    const updateUser = (users:UserWithCourse[]) => {
        setState(prevState => ({
            ...prevState,
            users
        }));
    }

    /**
     * For children components to be able to update state
     * @param loading 
     */
    const updateIsLoading = (loading:boolean) => {
        setState(prevState => ({
            ...prevState,
            isLoading:loading
        }));
    }    

    return (
        <>
            <div className='user-management-container cbit-container'>
                <div className='user-management-header-container'>
                    <h2 className='cbit-header'>
                        Users
                    </h2>
                    <Link to="/users/new" className="btn-cbit-primary">
                        Add User
                    </Link>
                </div>
                <div>
                    <UserManagementSearch 
                        users={originalUsers}
                        filterUsers={updateUser}
                    />
                </div>
                <div>                    
                    {
                        <>
                            <UserManagementTable
                                isLoading={isLoading}
                                updateIsLoading={updateIsLoading}
                                users={users}
                                onDelete={toggleDeleteAlertAndSetUserToDelete} 
                                getUsers={getUsers}
                            />
                        </>                        
                    }
                </div>
            </div>                
                <CustomAlert 
                    header={`ARE YOU SURE YOU WANT TO DELETE ${state.userToDelete?.firstName} ${state.userToDelete?.lastName}?`} 
                    text={`Once you click "Delete", the user will be deleted immediately.`} 
                    primaryBtnText={'Delete'} 
                    secondaryBtnText={'Cancel'} 
                    isOpen={isDeleteAlertOpen} 
                    primaryBtnSubmit={() => deleteUser(state.userToDelete ? state.userToDelete.id : "")} 
                    secondaryBtnSubmit={toggleDeleteAlert} 
                    toggleAlert={toggleDeleteAlert}
                />            
        </>
    )
}

export default UserManagement;