import { Link } from "react-router-dom";
import { IModule } from "../../../models/module/Module"
import trashIcon from "../../../resources/icons/icon-trash.png";
import CreateCourseModal from "./CreateCourseModal";
import DeleteCourse from "./DeleteCourse";

type propsType = {
    course:IModule;
    updateCourseInList: (course:IModule) => void;
}

export default function CourseItem(props:propsType) {
    const {
        course,
        updateCourseInList
    } = props;

    return (
        <div className="course-item cbit-container">                    
            <div className="cbit-header">
                <Link
                    to={`/content-builder/${course.id}`} 
                >
                    {course.name}                                
                </Link>
            </div>                    
            <div className="course-item-actions">
                <CreateCourseModal 
                    updateCourses={updateCourseInList} 
                    selectedCourse={course}
                />
                <DeleteCourse 
                    course={course}
                />
            </div>
        </div>
    )
}