import { useContext, useState } from "react";
import { PublishedModuleController } from "../../../controllers/PublishedModuleController";
import { IModule } from "../../../models/module/Module";
import iconDeleteSrc from "../../../resources/icons/icon-trash.png";
import CustomAlert from "../../Utilities/CustomAlert";
import toast from "react-hot-toast";
import { DeepCopy } from "../../../models/utility/DeepCopy";
import CustomModal from "../../Utilities/CustomModal";
import { CourseSelectContext } from "./Context/CourseSelectContext";

type propsType = {
    course:IModule;
}

const publishedModController = new PublishedModuleController();

export default function DeleteCourse(props:propsType) {
    const {
        course,
    } = props;

    const courseContext = useContext(CourseSelectContext);

    const [isAlertOpen, setAlert] = useState(false);
    const [isLoading, setLoading] = useState(false);

    const toggleDeleteAlert = () => (setAlert(!isAlertOpen));

    /**
     * Deletes the course item
     */
    const performDelete = async () => {
        const toastLoadId = "DeleteLoadId";
        setLoading(true);
        toast.loading("Deleting Course", {id:toastLoadId});

        try {
            const courses = courseContext.courses;
            const updateCourse = courseContext.updateCourses;
            const coursesCopy:IModule[] = DeepCopy.copy(courses);
            const filterCourses = coursesCopy.filter(c => c.id !==course.id);
            updateCourse(filterCourses);
            await publishedModController.DeleteCourse(course.id);

            
        } catch (error) {
            console.error(error);
            toast.error("Failed to delete course");
        }

        toast.dismiss(toastLoadId);
        setLoading(false);
    }

    return (
        <>
            <button 
                onClick={() => toggleDeleteAlert()}
                className='table-action-delete btn-cbit-link'                
            >
                <img src={iconDeleteSrc} alt="Delete icon" />
            </button>
            <CustomAlert
                header={`ARE YOU SURE YOU WANT TO DELETE: ${course.name}`} 
                text={`Once you click "Delete", the course will be deleted immediately`} 
                primaryBtnText={"Delete"} 
                secondaryBtnText={"Cancel"} 
                isOpen={isAlertOpen} 
                isLoading={isLoading}
                primaryBtnSubmit={performDelete} 
                secondaryBtnSubmit={toggleDeleteAlert} 
                toggleAlert={toggleDeleteAlert}
            />
        </>
    )
}